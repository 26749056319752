import React from 'react';
import { useEffect, useRef } from "react";
import { Note, Fraction, NoteTypeHandler } from 'opensheetmusicdisplay';
import { NoteType} from 'opensheetmusicdisplay';
import { UndefinedNoteTypeError, UndefinedNoteDivisionsError } from './Errors';
// import * as Scheduler from 'WebWorkers/Scheduler';
import { omitBy, isNil, find, range, some } from 'lodash';
import TimeSignature from 'Models/TimeSignature';
import { LevelData, UserLevelData } from 'Types';
/**
 * Helper function which safely adds two timestamps which may be in decimal form.
 * The result is always an integer form.
 */
export const safeAddTimestamps = (timestamp1: number, timestamp2: number) => {
  let safeTimestamp1 = Number.isSafeInteger(timestamp1) ? timestamp1 * .001 : timestamp1
  let safeTimeStamp2 = Number.isSafeInteger(timestamp2) ? timestamp2 * .001 : timestamp2 
  return safeTimestamp1 + safeTimeStamp2;
}

/**
 * Helper function which safely compares two timestamps which may be in decimal form.
 * @param timestamp1 
 * @param timestamp2 
 * @returns timestamp1 - timestamp2 (in integer form)
 */
export const safeTimeStampCompare = (timestamp1: number, timestamp2: number) => {
  let safeTimestamp1 = Number.isSafeInteger(timestamp1) ? timestamp1 : timestamp1 * 1000;
  let safeTimeStamp2 = Number.isSafeInteger(timestamp2) ? timestamp2 : timestamp2 * 1000;
  return safeTimestamp1 - safeTimeStamp2;
}

export const isEighth = (note: Note) => {
  return  note.Length.Equals(new Fraction(1,8))
}

export const calculateDurationByType = (notetype: NoteType | 'eighth'): number => {
  const fractionDuration = notetype === 'eighth' ? 
    NoteTypeHandler.getNoteDurationFromType('eighth') :
    NoteTypeHandler.getNoteDurationFromType(NoteTypeHandler.NoteTypeToString(notetype))
  return fractionDuration.RealValue
}

export const noteTypeToDivisionsPerMeasure = (noteType: NoteType, timeSignature: TimeSignature) => {
  // TODO: handle time signatures with denominators other than 4
  if (timeSignature.denominator != 4) {
    throw `Time signature with denominator ${timeSignature.denominator} not implemented yet!`
  }

  // First get the number of tick divisions in 4/4.
  let divisionsIn4Over4 = noteTypeToDivisionsIn4Over4(noteType)

  // In 2/4, we have half as many ticks as in 4/4.
  // In 3/4, 3/4 as many ticks as in 4/4.
  return divisionsIn4Over4 * (timeSignature?.numerator / 4)
}

export const fractionalMeasureToTicks = (fractionalMeasure: number, ticksPerMeasure: number ) => {
  let ticks = Math.floor(fractionalMeasure * ticksPerMeasure)
  return ticks;
}

export const divisionsPerMeasureToNoteTypeIn4Over4 = (divisions: number): NoteType => {
  switch(divisions) {
    case 1024:
      return NoteType._1024th
    case 512:
      return NoteType._512th
    case 256:
      return NoteType._256th
    case 128:
      return NoteType._128th
    case 64:
      return NoteType._64th
    case 32:
      return NoteType._32nd
    case 16:
      return NoteType._16th
    case 8:
      return NoteType.EIGTH
    case 4:
      return NoteType.QUARTER
    case 2:
      return NoteType.HALF
    case 1:
      return NoteType.WHOLE 
    case .5:
      return NoteType.BREVE
    default:
      throw new UndefinedNoteTypeError(divisions)
  }
}


export const noteTypeToDivisionsIn4Over4 = (noteType: NoteType) => {
  switch(noteType) {
    case NoteType._1024th:
      return 1024
    case NoteType._512th:
      return 512
    case NoteType._256th:
      return 256
    case NoteType._128th:
      return 128
    case NoteType._64th:
      return 64
    case NoteType._32nd:
      return 32
    case NoteType._16th:
      return 16
    case NoteType.EIGTH:
      return 8
    case NoteType.QUARTER:
      return 4
    case NoteType.HALF:
      return 2
    case NoteType.WHOLE:
      return 1
    case NoteType.BREVE:
      return .5
    case NoteType.UNDEFINED:
    case NoteType.MAXIMA:
    case NoteType.LONG:
      throw new UndefinedNoteTypeError(noteType)
  }
}

export const filterFalsy = (obj: object) => omitBy(obj, val => !val);

export const getLevelDataByLevelNumber = (levelData: LevelData[], levelNumber: number) => {
  return find(levelData, (level: LevelData) => level.level_number === levelNumber)
}

export const findAndReplace = (value: any, replacement: any, list: any[]) => {
  if(list.indexOf(value) > -1) {
    list[list.lastIndexOf(value)] = replacement
  }
  return list
}

export const midiPitchToFrequency = (pitch: number) : number => {
  // A4 == frequency 440 == midi pitch 69,
  // this is the magic function to convert from midi pitch to a frequency value
  return 220 * Math.pow(2, (pitch - (69-12)) / 12)
}

export const sumToN = (sumTarget: number) => {
  return range(0, sumTarget + 1).reduce((total, current) => {
    return total + current;
  }, 0)
}


export async function useComponentWillUnmount(cleanupCallback = () => {}) {
  const callbackRef = React.useRef(cleanupCallback)
  callbackRef.current = cleanupCallback // always up to date
  React.useEffect(() => {
    return () => {callbackRef.current()}
  }, [])
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const getNamesConcatted = (nameList: {name: string}[]) => {
  let fullNames = nameList
    .map(person => person.name);
    if(fullNames.length === 1) {
      return fullNames[0]
    }

    if(nameList.length > 1) {
      fullNames = fullNames.map(personName => personName + ",")
    }
    
    if(nameList.length > 2) {
      fullNames.splice(nameList.length - 1, 0, '&')
    }

  const almostFinalNames = fullNames.join(" ")
  // take comma off end
  return almostFinalNames.slice(0, almostFinalNames.length - 1)
}


export function calculateCorrectNoteOffMin(noteDuration: number) {
  if (noteDuration <= 1.0/4.0) {
      return noteDuration * 0.5
  }
  // If duration is between a quarter note and half note, use this block.
  else if (noteDuration > 1.0/4.0 && noteDuration < 2.0/4.0) {
      const nRange = (2.0/4.0) - (1.0/4.0)
      // Scale from 0.5 to 0.625 of the note, on a nearly linear curve
      let nScale = 1.0 - ((2.0/4.0) - noteDuration) / nRange
      // nScale is normalized from 0..1; putting that on a curve nScale^0.6
      // lets a noteDuration=1.5 have minimum offset of just under 1 beat.
      // Adjust the following line to adjust how "aggressive" scaling between 1 and 2 is.
      // Use graphtoy.com to visualize the curve you're adjusting.
      nScale = Math.pow(nScale, 0.6)
      return (1.0/8.0) + (0.1875) * nScale
  } else {
      return noteDuration - (1.0/4.0) + (1.0/16.0)
  }
}

export const getUserLevelDataByLevel = (userLevelData:UserLevelData[], levelNumber: number) => {
  return find(userLevelData, datum => datum?.level.level_number === levelNumber)
}


import { styled } from '@mui/material/styles';
import { 
    Box, 
    CircularProgress, 
    TextField, 
    Switch, 
    FormGroup, 
    FormControl, 
    FormControlLabel, 
    Button,
    CheckboxProps
} from '@mui/material';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { MUSEFLOW_ORANGE } from './Colors';
import { FormControlProps } from '@mui/material';
import { FormGroupProps } from '@mui/material';
import { FormControlLabelProps } from '@mui/material';

export const CustomCircularProgress = styled(CircularProgress)(() => ({
    color: MUSEFLOW_ORANGE
}))

export const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: "#CA7C62"
    },
    "& .MuiSwitch-switchBase": {
        color: "white"
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
        backgroundColor: '#CA7C62'
    },
    "& .MuiSwitch-switchBase+.MuiSwitch-track": {
        backgroundColor: 'white'
    },
    '& .MuiInputBase-root-MuiOutlinedInput-root': {
        backgroundColor: '#CA7C62'
    },
    '& .MuiOutlinedInput-input': {
        backgroundColor: '#CA7C62'
    }
}))

export const CustomButton = styled(Button)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#050B13', 
  padding: '12px 28px',
  background: '#CA7C62', 
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  letterSpacing: '2.5px',
  fontWeight: '700',
  fontSize: '16px',
  '&:hover': {
    background: "rgb(202,124,98,1.0)",
    cursor: 'pointer',
    transition: '0.3s',
    },
  "&.Mui-disabled": {
    backgroundColor: '#767C8C',
  },
}))


export const CustomTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(255,255,255,.5)",
    borderRadius: 80,
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderColor: '#469F6A'//'rgba(212,134,108,1.0)'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: '#469F6A'//"rgba(212,134,108,1.0)"//"purple"
    },
    "& .MuiOutlinedInput-input": {
    color: "rgba(255,255,255,.5)",
    fontFamily: 'Lato',
    },
    "&:hover .MuiOutlinedInput-input": {
    color: '#469F6A'//'rgba(212,134,108,1.0)'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    color: '#469F6A'//"rgba(212,134,108,1.0)"//purple"
    },
    "& .MuiInputLabel-outlined": {
    color: "rgba(255,255,255,.5)"
    },
    "&:hover .MuiInputLabel-outlined": {
    color: '#469F6A'//'rgba(212,134,108,1.0)'
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
    color: '#469F6A'//"rgba(212,134,108,1.0)"//purple"
    }

}))

interface RadioComponentProps {
  labels: Array<string>;
  select: number;
}

export const CustomRadioComponent = (props: RadioComponentProps) => {
    const [selectIdx, setSelectIdx] = useState(props.select);

    const Element = (props: {text: string; index: number;}) => {
        let text = props.text
        let index = props.index
        let isSelected = selectIdx == index

        const bgColor = isSelected ? {backgroundColor: '#CA7C62'} : {}
        const textColor = isSelected ? '#100D09' : 'white'
        return (
            <Box 
              sx={{
                marginTop: '10px', width: '100%', height: '38px', 
                borderRadius: '20px', display: 'flex', 
                alignItems: 'center', border: '1px solid white',
                '&:hover': {
                    background: "rgb(202,124,98,1.0)",
                    //borderRadius: '5px',
                    cursor: 'pointer',
                    transition: '0.3s',
                    //boxShadow: '0px 0px 5px 5px rgb(202,124,98,.25)',
                    },
                //backgroundColor: '#CA7C62', 
               ...bgColor}}
               onClick={() => setSelectIdx(index)}
               >
            <Box sx={{marginLeft: '10px', display: 'flex', alignItems: 'center'}}>
                { isSelected ? 
                    <Box sx={{height: '20px', width: '20px', borderRadius: '10px', backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Box sx={{height: '10px', width: '10px', backgroundColor: '#CA7C62', borderRadius: '50%'}}></Box>
                    </Box>
                :
                    <Box sx={{height: '16px', width: '16px', borderRadius: '9px', border: '1px solid white' }}></Box>
                }
                <Box sx={{
                    marginLeft: '10px', 
                    color: textColor,
                    fontStyle: 'normal',
                    fontWeight: isSelected ? '700':'400',
                    fontSize: '16px',
                    lineHeight: '24px',
                    letterSpacing: '0.01em',
                    }}>
                {text}
                </Box>
            </Box>
        </Box>
            )
    }
    const labels = props.labels
    const select = props.select
    return (
        <Box sx={{display: 'flex', flexDirection: 'column'}}>
            {labels.map((item:any,index:any)=>{
                return (
                  <Box key={index}>
                    <Element text={item} index={index}/>
                  </Box>
                )
            })}
        </Box>
    )

}

type CustomCheckboxProps = {
    formControlProps?: FormControlProps;
    formGroupProps?: FormGroupProps;
    formControlLabelProps?: FormControlLabelProps;
    checkboxProps?: CheckboxProps;
    label: string;
    id: string;
    name: string;
    value?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & CheckboxProps

export const CustomCheckbox = (props:CustomCheckboxProps) => {
    return (
        <FormControl component="fieldset" {...props.formControlProps}>
            <FormGroup aria-label="position" row {...props.formGroupProps}>
                <FormControlLabel
                value="end"
                control={<Checkbox 
                    defaultChecked={props.defaultChecked == undefined ? true : props.defaultChecked}
                    sx={Object.assign({
                    'color': MUSEFLOW_ORANGE,
                    '&.Mui-checked': {
                        color: MUSEFLOW_ORANGE
                    },
                    'boxSizing': 'border-box'
                    }, props.sx || {})}
                    id={props.id}
                    name={props.name}
                    value={props.value}
                    onChange={props.onChange}
                    {...props.checkboxProps}
                />}
                label={props.label}
                labelPlacement="end"
                sx={Object.assign({
                    color:'white'
                },props.sx || {})}
                />
            </FormGroup>
        </FormControl>
    )
}
import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import {  ChangePasswordData } from "Types";
import { CustomTextField, CustomButton } from "Components/StyledComponents"
import { FormikErrors, FormikProps } from "formik";
import * as yup from "yup";

export type  ChangePasswordFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  successMessage?: String
} & FormikProps< ChangePasswordData>;

export const  changePasswordFormSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
  newPassword: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
  repeatNewPassword: yup
    .string()
    .required("Repeat Password is a required field")
    .min(8, "Password must be at least 8 characters")
});


const  ChangePasswordForm: React.FC< ChangePasswordFormProps> = (
  props:  ChangePasswordFormProps
) => {
  //const { handleChange, placeholders, values, errors, successMessage } = props;
  const { handleChange, values, errors, successMessage } = props;

  return (
    <Box
      sx ={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}
    >
      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="currentPassword"
        name="currentPassword"
        type="password"
        label="Current Password"
        value={values.currentPassword || ""}
        error={!!errors.currentPassword}
        helperText={errors.currentPassword}
        fullWidth
      />

<CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="newPassword"
        name="newPassword"
        type="password"
        label="New Password"
        value={values.newPassword || ""}
        error={!!errors.newPassword}
        helperText={errors.newPassword}
        fullWidth
      />

      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="repeatNewPassword"
        name="repeatNewPassword"
        type="password"
        label="Confirm New Password"
        value={values.repeatNewPassword || ""}
        error={!!errors.repeatNewPassword}
        helperText={errors.repeatNewPassword}
        fullWidth
      />

      <Box>
        
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
        <Typography color="green">
          {successMessage}
        </Typography> 
      </Box>

      <CustomButton
        variant="contained"
        type="submit"
        sx={{
          marginBottom: '20px',
        }}
        disabled={!(values.newPassword || values.repeatNewPassword)}
      >
        SUBMIT
      </CustomButton> 

    </Box>
  );
};

export default  ChangePasswordForm;
import { Link } from '@mui/material';

const WelcomeLink = (props: any) => {
  return (
    <Link {...props} sx={linkStyles}>
      {props.children}
    </Link>
  )
}
export default WelcomeLink

const linkStyles = {
  color: '#CA7C62',
  textAlign: 'center',
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '24px',
  letterSpacing: '0.16px',
  textDecoration: 'none',
  "&:hover": {
    cursor: 'pointer',
  }
}

import { useEffect, useState, useRef, useMemo } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import Staff from 'Components/Staff/Repertoire';
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents'
import { divisionsPerMeasureToNoteTypeIn4Over4 } from 'Utils';
import 'react-piano/dist/styles.css';
import { useSelector, useDispatch } from 'react-redux'
import {  MainAppReducer } from 'Types';
import { useAWSRumContext } from 'Contexts/AWSRum';
import { useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { AuthReducer } from 'Types/AuthTypes';
import { useRepertoirePlayContext } from 'Contexts/RepertoirePlayContext';

async function useComponentWillUnmount(cleanupCallback = () => {}) {
  const callbackRef = useRef(cleanupCallback)
  callbackRef.current = cleanupCallback // always up to date
  useEffect(() => {
    return () => {callbackRef.current()}
  }, [])
}

interface MainAppProps {
  showKeyboard: boolean;
  setAccuracy: (x: number) => void
  setShowAccuracy: (x:boolean) => void
  setAllowChevrons: (x:boolean) => void
  cursorIsVisible: boolean
  scrollPosition?: any;
  setScrollPosition?: any;
}
const canWakeLock = () => 'wakeLock' in navigator;

const RepertoirePlayContainer = (props: MainAppProps) => {

  const { awsRum } = useAWSRumContext();
  const navigate = useNavigate(); 
  let location = useLocation();
  useEffect(() => {
    awsRum?.recordPageView(location.pathname);
    ReactGA?.send({ hitType: "pageview", page: location.pathname });
  }, []);
  const dispatch = useDispatch();
  const wakeLock = useRef<any>(null);
  const [ isWakeLocked, setIsWakeLocked ] = useState(false);
  const isVisible = usePageVisibility()

  const {
    scheduler2, 
    midiStream, 
    topPhraseStartTimestamp, 
    bottomPhraseStartTimestamp,
    musicLoaded,
    phraseParts,
    updateKey,
    setMusicLoaded,
    allPhrasesLoaded,
  } = useRepertoirePlayContext();

  const {
    cursorIsVisible,
    scrollPosition,
    setScrollPosition,
  } = props

  const musicXmlSvgWidth = useRef(960);
  const min960 = useMediaQuery('(min-width:960px)');
  const min1100 = useMediaQuery('(min-width:1100px)');
  const min1300 = useMediaQuery('(min-width:1300px)');
  const min1650 = useMediaQuery('(min-width:1650px)');
  const min1850 = useMediaQuery('(min-width:1850px)');

  useEffect(()=>{
    if(min1850) {
      musicXmlSvgWidth.current = 1600
    } else if(min1650) {
      musicXmlSvgWidth.current = 1440
    } else if(min1300) {
      musicXmlSvgWidth.current = 960
    } else if(min1100) {
      musicXmlSvgWidth.current = 800
    } else if(min960) {
      // this might not work - need to add additional choices in the parsing lambda. I don't think we support this anyway
      musicXmlSvgWidth.current = 800
    }
  },[min960, min1100, min1300, min1650, min1850])

  

  async function lockWakeState() {
    if(!canWakeLock()) return;
    try {
      wakeLock.current = await (navigator as any).wakeLock.request();
      wakeLock.current.addEventListener('release', () => {
        setIsWakeLocked(false);
      });
      setIsWakeLocked(true);
    } catch(error) {
      // console.error('Failed to lock wake state');
      // console.error(error);
    }
  }
  
  useEffect(()=>{
    if(!isWakeLocked && isVisible) {
      lockWakeState()
    }
  },[isWakeLocked, isVisible])

  const phrases = useMemo(() => {
    // Only recompute the phrases if phraseParts or allPhrasesLoaded has changed
    if (allPhrasesLoaded) {
      return phraseParts.toArray();
    }
    return [];
  }, [phraseParts, allPhrasesLoaded]);

  useEffect(()=>{setMusicLoaded(true)},[allPhrasesLoaded])
  return (
    <div 
    // className="App"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100vw',
        // overflow: 'scroll',
        // You can uncomment the following line to set a background color
        // backgroundColor: 'rgba(111, 135, 241, .15)',
      }}
    >
      {(musicLoaded && allPhrasesLoaded) ? (
        <>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'center',
            marginTop: '20px',
            marginBottom: '0px',
          }} />
          <Staff
            scheduler={scheduler2}
            timeKeeper={scheduler2?.timeKeeper}
            topPhraseStartTimestamp={topPhraseStartTimestamp}
            bottomPhraseStartTimestamp={bottomPhraseStartTimestamp}
            midiStream={midiStream}
            phrases={phrases}
            updateKey={updateKey}
            cursorIsVisible={cursorIsVisible}
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
          />
        </>
      ) : (
        <CustomCircularProgress />
      )}
    </div>
  );

}

export default RepertoirePlayContainer

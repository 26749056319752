import { Box } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import * as appActions from 'Actions/app'

const PageNav = (props: any) => {
  const { selected } = props
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{zIndex: 15, backgroundColor: '#040B13', height: '60px', display: 'flex'}}>
      <Box sx={{marginLeft: '16px', display:'flex', gap: '16px',}}>
          <Box 
            sx={ (selected == "Home") ? {...selectStyles, ...selectSelectedStyles} : selectStyles} 
            // onClick={() => navigate("/home")}
            onClick={() => dispatch(appActions.setRepPageSelected('Home'))}
          >
            <Box sx={textStyles}>Home</Box>
          </Box>
        <Box 
          sx={ (selected == "Browse") ? {...selectStyles, ...selectSelectedStyles} : selectStyles} 
          // onClick={() => navigate("/browse")}
          onClick={() => dispatch(appActions.setRepPageSelected('Browse'))}
        >
          <Box sx={textStyles}>Browse</Box>
        </Box>
      </Box>
    </Box> 
  )
}
export default PageNav

const selectStyles = {
  
  color: 'white',
  margin: '14px 5px 5px 5px',
  padding: '0px 32px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    background: "rgb(202,124,98,.25)",
    borderRadius: '5px',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(202,124,98,.25)',
    },
}

const selectSelectedStyles = {
  borderBottom: '2px solid', 
  borderColor: '#6F87F1',
  color: '#6F87F1',
}

const textStyles = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
  letterSpacing: '1px',
}
import { colors } from "../config";
import Note from "./Note";
import RoundedRect from "./RoundedRect";

/**
 * @enum ProgressState
 * @description The current state of the note's progress.
 */
export enum ProgressState {
  Waiting,
  Missing,
  TimelyStart,
  LateStart,
  MistimedRelease,
  Ended,
  Missed,
}

/**
 * @enum Result
 * @description The end result of the note.
 */
export enum Result {
  // The note was played on time and held throught the duration of the note.
  Success,
  // The note was played but it was either started late or it was released early.
  OffTime,
  // The user missed to play the note.
  Missed,
}

export default class Progress extends RoundedRect {
  progressState: ProgressState;
  tweenHandle: Phaser.Tweens.Tween | null = null;
  result: Result | null = null;
  progress: number;
  targetWidth?: number;
  widthCap?: number;
  note: Note;
  onResult: (result: Result) => void;

  constructor(
    note: Note,
    scene: Phaser.Scene,
    x: number,
    y: number,
    height: number,
    radius: number,
    strokeWidth: number,
    depth: number,
    onResult: (result: Result) => void
  ) {
    super(
      scene,
      x,
      y,
      0,
      height,
      radius,
      colors.gray,
      colors.progressAlphaFill,
      {
        color: colors.gray,
        width: strokeWidth,
        //alpha: colors.progressAlphaStroke,
        alpha: 1,
      }
    );
    this.onResult = onResult;
    this.note = note;
    this.progressState = ProgressState.Waiting;
    this.graphics.setDepth(depth);
    this.progress = 0;
  }

  start(
    targetWidth: number,
    widthCap: number,
    duration: number,
    onComplete: (result: Result) => void
  ) {
    this.progressState = ProgressState.Waiting;
    this.targetWidth = targetWidth;
    this.widthCap = widthCap;
    /*if (this.scene.tweens) {
      this.tweenHandle = this.scene.tweens.add({
        targets: this,
        progress: 1,
        ease: "Linear",
        duration,
        callbackScope: this,
        onUpdate: () => {
          this.onProgress();
        },
        onComplete: () => {
          onComplete(this.result!);
        },
      });
    }*/
  }

  /*onProgress() {
    if (this.progress > 0.8) {
      // If the tween is nearly complete but the actual note progress still hasn't started yet,
      // then we consider the user to have missed the note.
      if (this.progressState == ProgressState.Missing) {
        this.setProgressState(ProgressState.Missed);
      } else if (this.progressState == ProgressState.TimelyStart) {
        // If the user was playing the note up till this point, we mark it as complete and the user
        // can now release the note.
        this.setProgressState(ProgressState.Ended);
      }
    } else if (this.progress > 0.2) {
      // If the tween is 10% complete or more and the user still hasn't started playing the note,
      // then we set the late flag to true.
      if (this.progressState == ProgressState.Waiting)
        this.setProgressState(ProgressState.Missing);
    }
  }*/

  /*startProgress() {
    if (this.result != undefined) {
      if (this.result === Result.OffTime) {
        this.progressState = ProgressState.LateStart;
        this.setColor(
          colors.progressFillOffTime,
          undefined,
          colors.progressBorderOffTime
        );
        this.note.setColor(colors.noteFillOffTime);
      }
      return false;
    }
    if (this.progressState == ProgressState.Missing)
      this.setProgressState(ProgressState.LateStart);
    else if (this.progressState == ProgressState.Waiting)
      this.setProgressState(ProgressState.TimelyStart);
    else return false;
    return true;
  }*/

  stopProgress() {
    if (this.result != undefined) return;
    if (this.progressState == ProgressState.Missed) return;
    if (
      this.progressState == ProgressState.TimelyStart ||
      this.progressState == ProgressState.LateStart
    )
      this.setProgressState(ProgressState.MistimedRelease);
  }

  setProgressState(newState: ProgressState) {
    if (this.result != undefined && newState != ProgressState.Missed) return;
    this.progressState = newState;
    switch (this.progressState) {
      case ProgressState.Waiting:
        this.setColor(colors.gray, undefined, colors.gray);
        break;
      case ProgressState.TimelyStart:
        this.note.setColor(colors.noteFillOnTime);
        this.setColor(
          colors.progressBorderOnTime,
          undefined,
          colors.progressBorderOnTime
        );
        break;
      case ProgressState.Ended:
        this.result = Result.Success;
        break;
      case ProgressState.Missing:
        //this.setColor(colors.progressFillOffTime, undefined, colors.progressBorderOffTime);
        this.result = Result.OffTime;
        break;
      case ProgressState.MistimedRelease:
        this.result = Result.OffTime;
        this.setColor(
          colors.progressFillOffTime,
          undefined,
          colors.progressBorderOffTime
        );
        this.note.setColor(colors.noteFillOffTime);
        break;
      case ProgressState.LateStart:
        this.result = Result.OffTime;
        this.setColor(
          colors.progressFillOffTime,
          undefined,
          colors.progressBorderOffTime
        );
        this.note.setColor(colors.noteFillOffTime);
        break;
      case ProgressState.Missed:
        this.setColor(
          colors.progressFillMiss,
          undefined,
          colors.progressBorderMiss
        );
        this.note.setColor(colors.noteFillMiss);
        this.result = Result.Missed;
        break;
    }
    if (this.result) {
      this.onResult(this.result);
    }
  }

  onPlay() {
    // If the note is waiting to be played and the user has just started playing it,
    // then we mark it as in progress.
    if (this.progressState == ProgressState.Waiting)
      this.setProgressState(ProgressState.TimelyStart);
    else if (this.progressState == ProgressState.Missing)
      this.setProgressState(ProgressState.LateStart);
  }

  /*onRelease() {
    // If the note is still in progress and the user has just released it,
    // then we mark it as early released
    if (this.progressState == ProgressState.TimelyStart) {
      this.setProgressState(ProgressState.MistimedRelease);
    }
  }*/

  update(cursorX: number) {
    const width = (cursorX - this.x) / this.scale;
    this.width = Math.min(this.widthCap!, width);
    this.draw();
  }

}

import React, { useEffect } from 'react'
import ITimeKeeper from 'Models/ITimeKeeper';
import { useSelector } from 'react-redux';
import { MainAppReducer } from 'Types';
import Phrase from 'Models/Phrase';
import { useWindowDimension } from 'Hooks/useWindowDimension';
import useMediaQuery from '@mui/material/useMediaQuery';
import { TEMPO_SVG_HEIGHT } from 'Utils/Constants';

type CursorV2Props = {
    timeKeeper: ITimeKeeper | undefined
    startTimestamp: number,
    phrase: Phrase,
    isPlaying: boolean,
    countInTimestamp: number,
    cursorIsVisible: boolean,
    timeSig: boolean,
    className: string | undefined,
    phraseIndex?: number,
    scrollPosition?: any,
    setScrollPosition?: any,
    isRepertoireStaff: boolean | undefined,
}

// Internal POD struct 
type CursorOffset = {
    timestamp: number,
    offset: number
}


export const MuseFlowCursorV2: React.FC<CursorV2Props> = ({
    timeKeeper,
    startTimestamp,
    phrase,
    isPlaying,
    countInTimestamp,
    cursorIsVisible,
    timeSig,
    className,
    phraseIndex,
    setScrollPosition,
    isRepertoireStaff,
}) => {
    const cursorDiv = React.useRef<HTMLDivElement>(null)
    const matches = useMediaQuery('(max-width:1450px)');


    const previousOffset = React.useRef<CursorOffset | null>(null)
    const nextOffset = React.useRef<CursorOffset | null>(null)
    const animationCallbackRef = React.useRef<((ts: DOMHighResTimeStamp) => void) | null>(null)

    const [height, setHeight] = React.useState(133)
    const [initialOffsetLeft, setInitialOffsetLeft] = React.useState(0); 
    const [initialOffsetTop, setInitialOffsetTop] = React.useState(0); 
    const [cursorInitialLeft, setCursorInitialLeft] = React.useState(0);

    const [offsetTop, setOffsetTop] = React.useState(0)
    const [offsetLeft, setOffsetLeft] = React.useState(0)
    const [opacity, setOpacity] = React.useState("0%")
    // const [offsetPct, setOffsetPct] = React.useState(0)

    const [phraseWidth, setPhraseWidth] = React.useState<number | undefined | null>(null)

    const measureStartOffset = React.useRef(0)
    const currMeasureIndex = React.useRef(0)
    const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const iterator = timeSig ? phrase.timesigIterator : phrase.iterator;
    const dimensions = useWindowDimension()



    const initPhraseCache = React.useCallback(() => {
        if(phrase) {
            while(!phrase.iterator?.EndReached) {
                phrase.iterator?.left()
                phrase.iterator?.next()
            }
            phrase.iterator.resetIterator();
        }
    }, [phrase])

    React.useEffect(()=>{
        initPhraseCache()
    },[phrase])

    const resetCursorLeft = React.useCallback(()=>{
        let finalCursorInitialOffset = cursorInitialLeft;
        if(phrase.uuid) {
            // setOffsetLeft(0);
            const initialLeft = document.getElementById(phrase.uuid)?.getBoundingClientRect().left
            setPhraseWidth(document.getElementById(phrase.uuid)?.getBoundingClientRect().width)
            if(initialLeft) {
                finalCursorInitialOffset = initialLeft;
                setCursorInitialLeft(initialLeft);
            }
        }
        return finalCursorInitialOffset
        //   - 20
    },[cursorInitialLeft, phrase.uuid])
    

    const resetCursorHeight = React.useCallback(()=>{
        if(phrase.uuid) {
            const svg = document.getElementById(phrase.uuid)
            let svgHeight = svg?.getBoundingClientRect().height
            if (isRepertoireStaff && svgHeight && phrase.startTimestamp == 0) {
              svgHeight -= TEMPO_SVG_HEIGHT // For first line/phrae subtract height of tempo and then offset.
              setOffsetTop(TEMPO_SVG_HEIGHT)
            }
            if(svgHeight) setHeight(svgHeight)
        }
    },[phrase.uuid])

    React.useEffect(()=> {
        resetCursorHeight()
        resetCursorLeft()
        phrase.iterator?.clearCache()
        initPhraseCache()
    },[dimensions, phrase.iterator, resetCursorLeft])
    
    const getOffset = React.useCallback(() : CursorOffset | null => {
        let finalCursorInitialOffset = cursorInitialLeft;
        if(cursorInitialLeft === 0) {
            finalCursorInitialOffset = resetCursorLeft()
        }
        const left = iterator?.left() 
        const offset = left ? left - finalCursorInitialOffset : 0;
        return iterator && left ? {
            timestamp: iterator?.currentTimeStamp,
            offset: offset - 16 // account for cursor width (8px) + a bit more because it just looks better at faster tempos
        } : null
    }, [phrase, cursorInitialLeft, iterator])

    const setAnimationParams = React.useCallback((timestamp: number) => {
        if (nextOffset.current) {
            if (previousOffset.current) {
                let nDistBetween = (timestamp - previousOffset.current.timestamp)
                    / (nextOffset.current.timestamp - previousOffset.current.timestamp) 
                    
                let offset = previousOffset.current.offset
                    + (nextOffset.current.offset - previousOffset.current.offset) * nDistBetween
                
                setOffsetLeft(offset)
            } else {
                // console.log("setting offset", nextOffset.current.offset)
                // If only the next offset is set, we haven't passed the first animation tick yet.
                // Animate immediately.
                setOffsetLeft(nextOffset.current.offset)
            }
        }
    }, []) 



    // Finds the OSMD cursor position offsets that the MuseFlow cursor should be between given the current time.
    // If we haven't passed the start time yet, only nextOffset will be set when this function returns.
    const findOffsets = React.useCallback((osmdTime: number): boolean => {
        let offsetsChanged = false
        if (cursorDiv.current) {
            iterator?.resetIterator()
            if (!nextOffset.current) {
                
                const initialOffset = getOffset()
                currMeasureIndex.current = 0
                measureStartOffset.current = initialOffset?.offset ?? 0
                nextOffset.current = initialOffset
                offsetsChanged = true

                if (initialOffset && timeKeeper) {
                    // Count in timestamp should be the timestamp that the phrase is visible given the cursor count in
                    // but we're really just looking for when it's zero and subtracting 150 from the cursor offset. Theoretically
                    // it should be -1 (or -.25) at that point anyway, but it isn't
                    previousOffset.current = { 
                        timestamp: initialOffset.timestamp - timeKeeper?.measureDurationAt(0), 
                        offset: initialOffset.offset - (countInTimestamp <= 0 ? 200 : 0)
                    }
                } else {
                    // console.log("initial offset not found")
                }
            }

            // Advance the timeline until the next offset is ahead of the current time.
            while (nextOffset.current 
                && nextOffset.current.timestamp <= osmdTime
                && !iterator?.EndReached) {
                // We've passed the "next" offset. Advance the cursor.
                // The next offset becomes the previous, and we ask OSMD for the next cursor offset now.
                // console.log("advancing iterator", phrase.uuid)
                // console.log("iterator", iterator, phrase.uuid)
                iterator?.next()
                offsetsChanged = true

                if (!iterator?.EndReached) {
                    previousOffset.current = nextOffset.current
                    nextOffset.current = getOffset()
                    const left = iterator?.left()
                    if (currMeasureIndex.current !== iterator?.CurrentMeasureIndex && left && iterator?.CurrentMeasureIndex !== undefined) {
                        currMeasureIndex.current = iterator?.CurrentMeasureIndex
                        measureStartOffset.current = left
                    }
                }
            }

            if (iterator?.EndReached 
                && nextOffset.current 
                && previousOffset.current) {

                if (nextOffset.current.timestamp > osmdTime) {
                    return offsetsChanged
                }
                if (nextOffset.current.timestamp === previousOffset.current.timestamp) {
                    setOpacity("0%")
                    animationCallbackRef.current = null
                    resetCursorLeft();
                    return offsetsChanged
                }

                // // Use our parent element's bounding rect to figure out where the end of the phrase is,
                // // since OSMD won't tell us nicely.
                // const parentRect = cursorDiv.current.parentElement?.getBoundingClientRect()
                // console.assert(parentRect != null)
                // if (parentRect == null) {
                //     return false
                // }

                const finalLength = getOffset()?.offset

                previousOffset.current = nextOffset.current
               
                const measuresPerPhrase = phrase.musicXML.measures.length
                nextOffset.current = {
                timestamp: phrase.endTimestamp - phrase.startTimestamp,
                offset: finalLength || previousOffset.current.offset + 50
                }

                offsetsChanged = true
            }
        }

        return offsetsChanged;
    }, [countInTimestamp, getOffset, isPlaying, phrase.iterator, phrase.musicXML, timeKeeper, setCursorInitialLeft])

    const animate = React.useCallback(() => {
        if(height === 0) {
            resetCursorHeight()
        }
        if(timeKeeper) {
            const nowMeasureTimestamp = timeKeeper.audioTimeToMeasureTimestamp()

            let osmdTime = nowMeasureTimestamp - phrase.startTimestamp
            // console.log("osmdTime", osmdTime)

            // phrase.startTimestamp is measured in "measures", as was the format of OSMD, 
            // the library which renders these phrases in the backend and previously rendered
            // these phrases in the front end. I.e. the first phrase starts at 0, and the first quarter
            // note in a 4/4 phrase starts at 0.25.

            // The difference between timeKeeper.audioTimeToMeasureTimestamp() and the start time should
            // be zero at the time the phrase is supposed to start, and therefore the time the cursor should
            // be visible.
            //const beatsPerMeasure = 5//phrase.musicXML.timeSignatures[0].numerator
            let beatsPerMeasure = phrase.musicXML.measures.length
            let timeSignatureNumerator = phrase.musicXML.timeSignatures[0].numerator
            let timeSignatureDenominator = phrase.musicXML.timeSignatures[0].denominator
            beatsPerMeasure = beatsPerMeasure*timeSignatureNumerator/timeSignatureDenominator
            const endOfMeasureTimeCutoff = (phrase.endTimestamp - .05)
    
            if (cursorDiv.current) {
                if (osmdTime >= -.1 && osmdTime + phrase.startTimestamp < endOfMeasureTimeCutoff - .05) {
                    setOpacity("70%")
                
                // This handles when the cursor goes over the edge and needs to be set to invisible
                // however, (osmdTime + phrase.startTimestamp) < endOfMeasureTimeCutoff + .15 is necessary because
                // there is a race condition between this and the pause when a pause causes the cursor to jump back a measure,
                // where the pause will make the cursor visible again, but this will run right after and make it invisible
                // so, only turn the the cursor off at the end of the phrase line by capping the time that this can be called
                // Checking for if "opacity === 70%" would work, but for some reason the state doesn't propagate. 
                } else if(isPlaying && (osmdTime + phrase.startTimestamp > endOfMeasureTimeCutoff - .05) && (osmdTime + phrase.startTimestamp) < endOfMeasureTimeCutoff ) {
                    setOpacity("0%")
                }
                const offsetsChanged = findOffsets(osmdTime)
                if (offsetsChanged && nextOffset.current && previousOffset.current
                    && nextOffset.current.timestamp == previousOffset.current.timestamp) {
    
                    animationCallbackRef.current = null
                    if (isPlaying) {
                        setOpacity("0%")
                    }
                }
                else if (animationCallbackRef.current) {
                    setAnimationParams(osmdTime)
                }
            } 
                   
        }

        
    }, [isPlaying, timeKeeper, startTimestamp, opacity, findOffsets, setAnimationParams, countInTimestamp])

    React.useEffect(() => {
        nextOffset.current = null
        previousOffset.current = null
    }, [startTimestamp, cursorDiv])


    React.useEffect(()=>{
        if(timeKeeper) {
            previousOffset.current = null
            nextOffset.current = null
            const timestamp = countInTimestamp - phrase.startTimestamp
            const nowMeasureTimestamp = timeKeeper.audioTimeToMeasureTimestamp()
            let osmdTime = nowMeasureTimestamp - phrase.startTimestamp
            const beatsPerMeasure = phrase.musicXML.timeSignatures[0].numerator
            const endOfMeasureTimeCutoff = (beatsPerMeasure - .1)
            if (isPlaying) {
                if(osmdTime > -.1 && osmdTime < endOfMeasureTimeCutoff) {
                    setOpacity("70%")
                } 
                animationCallbackRef.current = (timestamp: DOMHighResTimeStamp) => {
                    animate()
    
                    if (animationCallbackRef.current) {
                        window.requestAnimationFrame(animationCallbackRef.current)
                    }
                }
    
                window.requestAnimationFrame(animationCallbackRef.current)
    
            } else {
                animationCallbackRef.current = null
    
                findOffsets(timestamp)
                setAnimationParams(timestamp)
                if (timestamp < 0 && phrase.startTimestamp != 0) {
                    // Delay a frame so that the previously scheduled animation callback runs BEFORE we reset opacity.
                    window.requestAnimationFrame(() => {
                        setOpacity("0%")
                    })
                } else {
                    const beatsPerMeasure = phrase.musicXML.timeSignatures[0].numerator
                    const endOfMeasureTimeCutoff = (beatsPerMeasure - .1)
                    if(isPlaying && osmdTime < endOfMeasureTimeCutoff && (osmdTime >= 0 - countInTimestamp - .01 - phrase.pickUpMeasureLength)) {
                        setOpacity("70%")
                    }else if(!isPlaying && timestamp < endOfMeasureTimeCutoff && timestamp > 1 && (osmdTime >= 0 - countInTimestamp - .01) && osmdTime - 1 < endOfMeasureTimeCutoff ) {
                        setOpacity("70%")
                    }
                }
            }
        }
        
    },[isPlaying, phrase])

    React.useEffect(()=>{
        if(timeKeeper) {
            const timestamp = countInTimestamp - phrase.startTimestamp
            const nowMeasureTimestamp = timeKeeper.audioTimeToMeasureTimestamp()
            let osmdTime = nowMeasureTimestamp - phrase.startTimestamp
            if(height === 0 ) {
                resetCursorHeight()
            }
            const beatsPerMeasure = phrase.musicXML.timeSignatures[0].numerator
            const endOfMeasureTimeCutoff = (beatsPerMeasure - .1)
            if(!nextOffset.current && isPlaying && timestamp >= countInTimestamp - .1 && nowMeasureTimestamp <= endOfMeasureTimeCutoff) {
                setOpacity("70%")
            } else if(!nextOffset.current && isPlaying && timestamp >= countInTimestamp - .01 && osmdTime >= .01 && phrase.startTimestamp >= osmdTime - .01) {
                setOpacity("70%")
            } 
        }
    },[isPlaying, phraseIndex])

    React.useEffect(()=>{
        setOpacity("0%")
    }, [])

    React.useEffect(()=>{
        setOpacity("0%")
    }, [data?.currentUserLevelData?.current_tier])

    const scrollOffsetCount = 65;
    useEffect(() => {
      if (opacity != "0%" && phraseIndex != null && phraseWidth != null && offsetLeft >= phraseWidth-scrollOffsetCount) {
        const scrollFactor = matches ? 200 : 250 // Must match height defined in line 163 of Staff/repertoire/index.tsx
        // setScrollPosition((scrollFactor+TEMPO_SVG_HEIGHT) + scrollFactor*(phraseIndex)) // adjust for additional height from tempo at the top.
        setScrollPosition(scrollFactor*(phraseIndex+1))
      }
    }, [opacity, phraseIndex, offsetLeft, phraseWidth])

    
    return (
        <div id={`museflow-cursor-${phrase.uuid}`} className={className} ref={cursorDiv} style={{
            position: "absolute",
            width: "16px",
            height: height + "px",
            background: "rgba(79, 150, 189, 0.26)",
            borderRadius: "100px",
            opacity: opacity,
            //top: scrollOffset,//offsetTop === 0 ? "None" : -10 + "px",
            top: offsetTop === 0 ? "None" : offsetTop + "px",
            paddingTop: '10px',
            left:  "None",
            marginLeft: offsetLeft + "px",
            transition: "opacity .25s ease-out",
            // visibility: "visible" 
            visibility: cursorIsVisible ? "visible" : "hidden", 
        }}></div>
    )
}
export default MuseFlowCursorV2
export const colors = {
  fg: 0x0,
  bg: 0xe2e2e2,
  cursor: 0x6f87f1,
  cursorAlpha: 0.26,
  yellow: 0xb0bb41,
  green: 0x469f6a,
  blue: 0x6f87f1,
  red: 0xd8465c,
  gray: 0x939393,

  beatNumberFillQuestion: "#6f87f1",
  beatNumberFillCorrect: "#469f6a",
  beatNumberFillMiss: "#D8465C",
  beatNumberFillOffTime: "#b0bb41",
  textFg: "#000000",

  promptBgQuestion: 0x9faff6,
  promptBgCorrect: 0x00a165,
  promptFgCorrect: 0xe2e2e2,
  promptFgQuestion: 0x0,
  promptBgWrong: 0xC3374D,
  promptFgWrong: 0xe2e2e2,

  progressFillMiss: 0xF53900,
  progressBorderMiss: 0xBCA59E,
  progressFillOnTime: 0x00A165,
  progressBorderOnTime: 0x9CAAA2,
  progressFillOffTime: 0xD3B506,
  progressBorderOffTime: 0xA7A896,
  progressAlphaFill: 0.19,
  progressAlphaStroke: 1,

  noteFillOnTime: 0x3d945e,
  noteFillOffTime: 0xb0bb41,
  noteFillMiss: 0xd8465c,

  whiteKey: 0xffffff,
  blackKey: 0x000000,
  keyTintActionRequired: 0x9faff6,
  keyTintPressedWrong: 0xC3374D,
  keyTintPressed: 0x35bc5b,
};

export function hex(num: number) {
  return "#" + num.toString(16);
}

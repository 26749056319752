import Phaser from "phaser";
import GraphicObject from "./GraphicObject";
import Gradient from "Phaser/Util/Gradient";

/**
 * @type StrokeStyle
 * @description The stroke style for a shape object.
 */
export interface StrokeStyle {
  /**
   * @member width
   * @description The width of the stroke line.
   */
  width: number;
  /**
   * @member color
   * @description The color of the stroke.
   */
  color: number;
  /**
   * @member alpha
   * @description The alpha of the stroke line.
   */
  alpha: number;
}

export default class RoundedRect extends GraphicObject {
  /**
   * @member graphics
   * @description The graphics object that is used to draw the rounded rectangle.
   */
  graphics: Phaser.GameObjects.Graphics;
  /**
   * @member radius
   * @description The border radius of the rounded rectangle.
   */
  radius: number;
  /**
   * @member width
   * @description The width of the rounded rectangle.
   */
  width: number;
  /**
   * @member height
   * @description The height of the rounded rectangle.
   */
  height: number;
  /**
   * @member color
   * @description The fill color of the rounded rectangle.
   */
  color: number;
  /**
   * @member alpha
   * @description The fill alpha of the rounded rectangle.
   */
  alpha: number;
  /**
   * @member stroke
   * @description The stroke style of the rounded rectangle.
   */
  stroke?: StrokeStyle;

  fillGradient?: Gradient;

  scale: number = 1;

  /**
   * @constructor RoundedRect
   * @param scene The scene to which the rounded rectangle belongs.
   * @param x x position of the rounded rectangle.
   * @param y y position of the rounded rectangle.
   * @param width Width of the rounded rectangle.
   * @param height Height of the rounded rectangle.
   * @param radius Border radius of the rounded rectangle.
   * @param color Fill color of the rounded rectangle.
   * @param alpha Fill alpha of the rounded rectangle.
   * @param stroke Stroke style of the rounded rectangle.
   */
  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    color: number,
    alpha: number,
    stroke?: StrokeStyle,
    fillGradient?: Gradient
  ) {
    super(scene, x, y, "graphics");
    this.radius = radius;
    this.width = width;
    this.height = height;
    this.color = color;
    this.stroke = stroke;
    this.alpha = alpha;
    this.x = x;
    this.y = y;
    this.graphics = scene.add.graphics();
    this.fillGradient = fillGradient;
    this.draw();
  }

  setPipeline(pipeline: string): void {
    this.graphics.setPipeline(pipeline);
  }

  /**
   * @method draw
   * @description Draws the rounded rectangle. The rectangle must be drawn every time one of its properties is changed.
   */
  draw() {
    // Clear the previously drawn rectangle, otherwise more than one rectangle will be drawn.
    this.graphics.clear();
    if (this.fillGradient) {
      this.graphics.fillGradientStyle(
        this.fillGradient.topLeft,
        this.fillGradient.topRight,
        this.fillGradient.bottomLeft,
        this.fillGradient.bottomRight,
        this.fillGradient.topLeftAlpha,
        this.fillGradient.topRightAlpha,
        this.fillGradient.bottomLeftAlpha,
        this.fillGradient.bottomRightAlpha
      );
    } else {
      this.graphics.fillStyle(this.color, this.alpha);
    }
    //const x = this.x - this.width / 2;
    //const y = this.y - this.height / 2;
    if (this.radius === 0) {
      this.graphics.fillRect(this.x, this.y, this.width, this.height);
    } else {
      // Gradient fill is broken in rounded rect!
      this.graphics.fillRoundedRect(
        this.x,
        this.y,
        this.width * this.scale,
        this.height * this.scale,
        this.radius * this.scale
      );
    }
    if (this.stroke) {
      const strokeWidth = this.stroke.width;
      this.graphics.lineStyle(
        strokeWidth,
        this.stroke.color,
        this.stroke.alpha
      );
      this.graphics.strokeRoundedRect(
        this.x - (strokeWidth / 2) * this.scale,
        this.y - (strokeWidth / 2) * this.scale,
        this.width * this.scale,
        this.height * this.scale,
        this.radius * this.scale
      );
    }
  }

  setScale(scale: number): void {
    this.scale = scale;
  }

  setColor(
    color: number,
    alpha?: number,
    strokeColor?: number,
    strokeAlpha?: number
  ) {
    this.color = color;
    if (alpha !== undefined) this.alpha = alpha;
    if (this.stroke) {
      this.stroke.color = strokeColor || this.stroke.color;
      this.stroke.alpha = strokeAlpha || this.stroke.alpha;
    }
    this.draw();
  }

  setAlpha(alpha: number) {
    this.alpha = alpha;
    this.draw();
  }

  setDepth(depth: number) {
    this.graphics.setDepth(depth);
  }

  setSize(width: number, height: number) {
    this.width = width;
    this.height = height;
    this.draw();
  }

  setInteractive(): this {
    this.graphics.setInteractive(
      new Phaser.Geom.Rectangle(this.x, this.y, this.width, this.height),
      Phaser.Geom.Rectangle.Contains
    );
    return this;
  }

  addClickListener(cb: () => void, context?: Object) {
    this.graphics.on("pointerdown", cb, context);
  }

  addListener(event: string | symbol, fn: Function, context?: any): this {
    this.graphics.on(event, fn, context);
    return this;
  }

  removeClickListener() {
    this.graphics.off("pointerdown");
  }

  /**
   * @method destroy
   * @description Destroys the graphics handle, clearing all graphics data and removing it from the scene.
   */
  destroy() {
    this.graphics.destroy();
  }
}

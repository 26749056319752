import immutable from 'immutable'
import { StateData } from '../../src/Types'
import { defaultState } from 'Reducers/mainAppReducer';

export const loadState = (userId: string | null) => {
  try {
    if (userId) {
      let envPrepend = (process.env.REACT_APP_NODE_ENV == 'dev' || process.env.REACT_APP_NODE_ENV === 'local') ? process.env.REACT_APP_NODE_ENV+'-' : '' 
      const serializedState = localStorage.getItem(`${envPrepend}museflow-data-${userId}`)
      if (serializedState === null) {
        return undefined;
      }
      return immutable.merge(JSON.parse(serializedState))
    } else {
      return immutable.merge(defaultState)
    }
  } catch (err) {
    return undefined
  }
}

export const saveState = (userId: string, state: StateData) => {
  try {
    const serializedState = JSON.stringify(state)
    let envPrepend = (process.env.REACT_APP_NODE_ENV == 'dev' || process.env.REACT_APP_NODE_ENV === 'local') ? process.env.REACT_APP_NODE_ENV+'-' : ''
    localStorage.setItem(`${envPrepend}museflow-data-${userId}`, serializedState)
  } catch (err) {
    // ignore write errors
  }
}

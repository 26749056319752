import { Box } from "@mui/system"
import Link from '@mui/material/Link';

const Logo = () => {
  return (
    <>
    <Box sx={{zIndex: 1, position:'absolute', top:'0px', borderRadius: '12px', background: 'linear-gradient(0deg, rgba(5,11,19,0) 0%, rgba(5,11,19,1.0) 100%)', width: '100%', height: '50px'}}/> 
    <Link sx={{zIndex: 2, position:'absolute', top:'-40px', height: '70px'}} href="https://www.museflow.ai">
      <svg xmlns="http://www.w3.org/2000/svg" width="67" height="67" viewBox="0 0 67 67" fill="none">
        <g clipPath="url(#clip0_1916_2966)">
          <path d="M67.2595 31.8009C65.1901 36.8751 59.9052 50.7572 51.0227 46.3532C50.2409 45.9656 49.5731 45.4452 49.0021 44.8179C43.086 38.3182 49.6098 19.6437 43.4774 19.6421C37.345 19.6405 38.0333 38.2154 33.4488 38.4069C28.8643 38.5984 31.7981 25.4814 27.8137 23.5721C23.8294 21.6628 22.7304 29.3742 17.5255 40.5956C12.3205 51.8169 4.27115 49.3131 -0.262695 40.5956" stroke="#CA7C62" strokeWidth="3.33333" strokeLinecap="round"/>
        </g>
        <rect x="2.25" y="2.62158" width="62.4999" height="62.5" rx="31.2499" stroke="#CA7C62" strokeWidth="3.33333"/>
        <defs>
          <clipPath id="clip0_1916_2966">
            <rect x="2.25" y="2.62158" width="62.4999" height="62.5" rx="31.2499" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </Link>
    </>
  )
}
export default Logo

import { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import StartGame from "./main";
import { EventBus, NotifyPhaser } from "./EventBus";
import { useMidiContext } from "Contexts/MidiContext";
import React from "react";

export interface IRefPhaserGame {
  game: Phaser.Game | null;
  scene: Phaser.Scene | null;
}

interface IProps {
  currentActiveScene?: (scene_instance: Phaser.Scene) => void;
  width: number;
  height: number;
}

export const PhaserGame = forwardRef<IRefPhaserGame, IProps>(
  function PhaserGame({ currentActiveScene, width, height }, ref) {
    const game = useRef<Phaser.Game | null>(null!);

    const midi = useMidiContext();

    const noteOn = React.useCallback((ev: any) => {
      NotifyPhaser("midi-on", ev.note);
    }, []);

    const noteOff = React.useCallback((ev: any) => {
      NotifyPhaser("midi-off", ev.note);
    }, []);

    useEffect(() => {
      const notifyOn = (ev: any) => {
        if (!ev.repeat && ev.key == 'm') NotifyPhaser("midi-on", ev.key);
      }
      const notifyOff = (ev: any) => {
        if (ev.key == "m") NotifyPhaser("midi-off", ev.key);
      }
      window.addEventListener('keydown', notifyOn);
      window.addEventListener('keyup', notifyOff);
      return () => {
        window.removeEventListener("keydown", notifyOn);
        window.removeEventListener("keyup", notifyOff);
      }
    }, []);

    useEffect(() => {
      midi.addListener({
        name: "phaser",
        noteOn,
        noteOff,
      });
      return () =>
        midi.removeListener({
          name: "phaser",
          noteOn,
          noteOff,
        });
    }, [midi]);

    useLayoutEffect(() => {
      if (game.current === null) {
        game.current = StartGame("game-container", width, height);
        console.log(width, height);
        if (typeof ref === "function") {
          ref({ game: game.current, scene: null });
        } else if (ref) {
          ref.current = { game: game.current, scene: null };
        }
      }

      return () => {
        if (game.current) {
          game.current.destroy(true);
          if (game.current !== null) {
            game.current = null;
          }
        }
      };
    }, [ref, height, width]);

    useEffect(() => {
      EventBus.on("current-scene-ready", (scene_instance: Phaser.Scene) => {
        if (currentActiveScene && typeof currentActiveScene === "function") {
          currentActiveScene(scene_instance);
        }

        if (typeof ref === "function") {
          ref({ game: game.current, scene: scene_instance });
        } else if (ref) {
          ref.current = { game: game.current, scene: scene_instance };
        }
      });
      return () => {
        EventBus.removeListener("current-scene-ready");
      };
    }, [currentActiveScene, ref]);

    return <div id="game-container" style={{ pointerEvents: "none" }}></div>;
  }
);

import React from 'react';
import { Formik, Form } from "formik";
import { Box, Link, Typography } from '@mui/material';
import { ResetCodeData } from 'Types';
import ResetCodeForm, {resetCodeFormSchema} from 'Components/Forms/ResetCodeForm';
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import { isPhoneNumber } from 'Components/Forms/SignUpForm';
import GoBackButton from 'Components/GoBackButton';
import { MUSEFLOW_OFF_WHITE_1 } from 'Components/Colors';
import { resendSignUp } from 'Utils/Amplify';
import { useDispatch, useSelector } from 'react-redux';
import { AuthReducer, SIGN_IN_COMPONENT } from 'Types/AuthTypes';
import * as authActions from 'Actions/auth';
import { CircularProgress } from '@mui/material';

type VerificationCodeProps = {
  onSubmit?: (code: string) => void;
  backFn?:()=>void
}

const VerificationCode: React.FC<VerificationCodeProps> = (props) => {

  const { onSubmit, backFn } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const {
    pendingEmailOrPhone,
    resetEmailOrPhone,
    resetCountryAcronym,
    pendingCountryAcronym,
    errors,
    processPending
  } = auth;


  return(
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '300px'
      }}
    >
      {
        backFn && 
        <GoBackButton
        sx={{
          position: 'absolute',
          top: 30,
          left: 30,
          color: MUSEFLOW_OFF_WHITE_1
        }}
        onClick={()=>backFn()}
      >
        Back
      </GoBackButton>
      }
      {
        pendingEmailOrPhone &&
        <>
        <h3 
          style={{
            marginBottom: "20px",
            color: "rgb(255,255,255,1.0)"
          }}
        >
          {isPhoneNumber(pendingEmailOrPhone) ?
            "Verify Your Phone Number" :
            "Verify Your Email"
          }
        </h3> 
        <Box 
            sx={{
              margin: "20px",
            }}
          >
            {
          isPhoneNumber(pendingEmailOrPhone) ?
            "Check your sms mesages for a code." :
            "Check your email for a code."
          }
          </Box> 
        <Link 
          sx={{
            textAlign: 'center' as 'center',
            cursor: 'pointer'
          }}
          onClick={()=> {resendSignUp(pendingEmailOrPhone || "", pendingCountryAcronym || "")}}
        >
          Didn't get a Code? Click here to resend.
        </Link>
        </>
      }
      {!processPending && <>
        <Box 
          sx={{
            maxWidth: '400px',
            margin: '20px'
          }}>
        </Box>
        <Formik
          initialValues={{
            "code": "",
            "unknown": ""
          }}
          validate={(values: ResetCodeData) => {
            return ValidateForm(resetCodeFormSchema,values)}
          }
          onSubmit={async (values) => {
            dispatch(authActions.SetConfirmationCode(values.code))
            if(pendingEmailOrPhone && isPhoneNumber(pendingEmailOrPhone)) {
              dispatch(authActions.confirmSignUp({phoneNumber: pendingEmailOrPhone, confirmationCode: values.code, countryAcronym: pendingCountryAcronym || "US"}))
            } else if(resetEmailOrPhone){
              dispatch(authActions.setSignInComponent(SIGN_IN_COMPONENT.RESET_PASSWORD))
            }
            if(onSubmit) {
              onSubmit(values.code)
            }
          }}
          validateOnChange={false}
        >
          {formikProps => (
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                dispatch(authActions.resetErrors());
                formikProps.handleSubmit(e);
              }}
            >
              <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
                <ResetCodeForm 
                  {...formikProps}
                  errors={{...errors,...formikProps.errors}}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </> }
      {processPending && <CircularProgress/>}
    </Box>
  )
}

export default VerificationCode;
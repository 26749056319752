import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Transition } from 'react-transition-group'
import StaffLine from './StaffLine';
import { Box } from '@mui/material';
import Phrase from 'Models/Phrase';
import { range } from 'lodash';
import EventStream from 'Models/EventStream';
import ITimeKeeper from 'Models/ITimeKeeper';


type StaffProps = {
  midiStream: EventStream,
  timeKeeper: ITimeKeeper,
  phrasePart0: Phrase | undefined,
  phrasePart1: Phrase | undefined,
  phrasePart2: Phrase | undefined,
  phrasePart3: Phrase | undefined,
  topPhraseStartTimestamp: number,
  bottomPhraseStartTimestamp: number,
  scheduler: any,
  updateKey: number,
  cursorIsVisible: boolean,
  showMusic?: boolean
}

const Staff: React.FC<StaffProps> = ({
  midiStream,
  timeKeeper,
  // It would be nice to make this an array, but React will either not re-render (because same array reference)
  // Or it will re-render the entire object
  phrasePart0,
  phrasePart1,
  phrasePart2,
  phrasePart3,
  topPhraseStartTimestamp,
  bottomPhraseStartTimestamp,
  scheduler,
  updateKey,
  cursorIsVisible,
  showMusic = true
}) => {
  const matches = useMediaQuery('(max-width:1450px)');

  const [currentVisibleTop, setCurrentVisibleTop] = React.useState(0) 
  const [currentVisibleBottom, setCurrentVisibleBottom] = React.useState(1)
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [topPhraseCountInTimestamp, setTopPhraseCountInTimestamp] = React.useState(-1)
  const [bottomPhraseCountInTimestamp, setBottomPhraseCountInTimestamp] = React.useState(0)

  // This is necessary due to two unfortunate quirks of React SVGs & transitions.
  // 1. SVGs don't re-render when a new URL is provided. So, an old SVG will be presented on the second fade-in of the component.
  //    This means we need to completely remove it from the DOM. However,
  // 2. Transitions don't seem to work correctly when elements are removed from the dom. Instead, they're removed abruptly with
  //    no animation. There's probably a way to fix this with TransitionCSSGroup. I have't figured it out. Alternatively, we can
  //    add a callback to remove the component when the animation is complete.
  const [transitionedOut0, setTransitionedingOut0] = React.useState(false);
  const [transitionedOut1, setTransitionedingOut1] = React.useState(false);
  const [transitionedOut2, setTransitionedingOut2] = React.useState(false);
  const [transitionedOut3, setTransitionedingOut3] = React.useState(false);
  const duration = 1000;


  let perPhraseDivrefs = React.useRef(range(4).map(() => React.createRef<HTMLDivElement>()))

  // The original intent here was to make this a list where individual list indexes would change the corresponding
  // rendered component. However, react state wasn't smart enough to figure out individual indexes were changing.
  // See this thread: https://stackoverflow.com/a/36182940
  // We won't want to re-render the entire list when an individual index changes. Hence the very complicated indexing
  // in the MainApp component.

  React.useEffect(() => {
    if(!perPhraseDivrefs.current[0].current) {
      perPhraseDivrefs.current[0] = React.createRef<HTMLDivElement>();
    }
    if(phrasePart0) {
      setCurrentVisibleTop(0)
      setTransitionedingOut0(false)
      setTimeout(()=> setTransitionedingOut2(true), duration)
      if(phrasePart0.timesigIterator){
        midiStream.addEvents2(phrasePart0.timesigIterator, topPhraseStartTimestamp)
      }
    }
  }, [phrasePart0])

  React.useEffect(() => {
    if(!perPhraseDivrefs.current[1].current) {
      perPhraseDivrefs.current[1] = React.createRef<HTMLDivElement>();
    }
    if(phrasePart1) {
      setCurrentVisibleBottom(1)
      setTransitionedingOut1(false)
      setTimeout(()=> setTransitionedingOut3(true), duration)
      if(phrasePart1.iterator){
        midiStream.addEvents2(phrasePart1.iterator, bottomPhraseStartTimestamp)
      }
    }
  }, [phrasePart1])

  React.useEffect(() => {
    if(!perPhraseDivrefs.current[2].current) {
      perPhraseDivrefs.current[2] = React.createRef<HTMLDivElement>();
    }
    if(phrasePart2) {
      setCurrentVisibleTop(2)
      setTransitionedingOut2(false)
      setTimeout(()=> setTransitionedingOut0(true), duration)
      if(phrasePart2.timesigIterator){
        midiStream.addEvents2(phrasePart2.timesigIterator, topPhraseStartTimestamp)
      }
    }
  }, [phrasePart2])

  React.useEffect(() => {
    if(!perPhraseDivrefs.current[3].current) {
      perPhraseDivrefs.current[3] = React.createRef<HTMLDivElement>();
    }

    if(phrasePart3) {
      setCurrentVisibleBottom(3)
      setTransitionedingOut3(false)
      setTimeout(()=> setTransitionedingOut1(true), duration)
      if(phrasePart3.iterator){
        midiStream.addEvents2(phrasePart3.iterator, bottomPhraseStartTimestamp)
      }
    }
  }, [phrasePart3])


  React.useEffect(() => {
    // TODO unmount logic? Need to un-register from the scheduler later
    if (scheduler) {

      scheduler.addPauseCallback((countInMeasure: number) => {
        setTopPhraseCountInTimestamp(countInMeasure)
        setBottomPhraseCountInTimestamp(countInMeasure)
        setIsPlaying(false)
      })

      scheduler.addUnpauseCallback((countInTicks: number, startMeasure: number, ticksPerMeasure: number) => {
        setTopPhraseCountInTimestamp(-1)
        setBottomPhraseCountInTimestamp(-1)
        setIsPlaying(true)
      })
    }
  }, [scheduler, updateKey])


const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
  unmounted:  { opacity: 0 },
};

  return (

    <Box sx={{
      margin: 0,
      width:'90vw',
      height: matches ? '400px' : '500px',
      visibility: showMusic ? 'unset' : 'hidden'
    }}>

        <Box
          sx={{
            height: matches ? '200px' : '250px'
          }}
        >
          <Transition
            in = {currentVisibleTop === 0}
            enter
            exit
            appear={true}
            nodeRef={perPhraseDivrefs.current[0]}
            timeout={2000}
          >
             {(state) => (
              <>
              {phrasePart0 && !transitionedOut0 &&
               <StaffLine
               style={{
                ...defaultStyle,
                ...transitionStyles[state]
              }}
                timeSig={true}
                divRef={perPhraseDivrefs.current[0]}
                phrase={phrasePart0}
                className={`fade fade-${state}`}
                timeKeeper={timeKeeper}
                startTimestamp={topPhraseStartTimestamp}
                isPlaying={isPlaying}
                countInTimestamp={topPhraseCountInTimestamp}
                cursorIsVisible={cursorIsVisible}
                phraseSvgUrl={phrasePart0?.svgTimesigURL || ""}
              />
            }
              </>
             )}
          </Transition>
          
            <Transition
              in = {currentVisibleTop === 2}
              enter
              exit
              appear={true}
              nodeRef={perPhraseDivrefs.current[2]}
              timeout={2000}
            >

              {(state) => (
              <>
              {phrasePart2 && !transitionedOut2 &&
                <StaffLine
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state]
                }}
                timeSig={true}
                divRef={perPhraseDivrefs.current[2]}
                phrase={phrasePart2}
                className={`fade fade-${state}`}
                timeKeeper={timeKeeper}
                startTimestamp={topPhraseStartTimestamp}
                isPlaying={isPlaying}
                countInTimestamp={topPhraseCountInTimestamp}
                cursorIsVisible={cursorIsVisible}
                phraseSvgUrl={phrasePart2?.svgTimesigURL || ""}
              />
              }
              </>
              )}

            </Transition>
        </Box>
        <Box
          sx={{
            height: matches ? '200px' : '250px'
          }}
        >
          <Transition
            in = {currentVisibleBottom === 1}
            enter
            exit
            appear={true}
            nodeRef={perPhraseDivrefs.current[1]}
            timeout={2000}
          >
             {(state) => (
               <>
               {
                phrasePart1 && !transitionedOut1 &&
                <StaffLine
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state]
                }}
                  timeSig={false}
                  divRef={perPhraseDivrefs.current[1]}
                  phrase={phrasePart1}
                  className={`fade fade-${state}`}
                  timeKeeper={timeKeeper}
                  startTimestamp={bottomPhraseStartTimestamp}
                  isPlaying={isPlaying}
                  countInTimestamp={bottomPhraseCountInTimestamp}
                  cursorIsVisible={cursorIsVisible}
                  phraseSvgUrl={phrasePart1?.svgURL || ""}
                />
              }
              </>
             )}
          </Transition>
  
            <Transition
              in = {currentVisibleBottom === 3}
              enter
              exit
              appear={true}
              nodeRef={perPhraseDivrefs.current[3]}
              timeout={2000}
            >
            {(state) => (
            <>
            {
              phrasePart3 && !transitionedOut3 &&
              <StaffLine
                style={{
                  ...defaultStyle,
                  ...transitionStyles[state]
                }}
                timeSig={false}
                divRef={perPhraseDivrefs.current[3]}
                phrase={phrasePart3}
                className={`fade fade-${state}`}
                timeKeeper={timeKeeper}
                startTimestamp={bottomPhraseStartTimestamp}
                isPlaying={isPlaying}
                countInTimestamp={bottomPhraseCountInTimestamp}
                cursorIsVisible={cursorIsVisible}
                phraseSvgUrl={phrasePart3?.svgURL || ""}
            />
            }
            </>
            )}
          </Transition>
        </Box>
      
    </Box>
  )
}

export default Staff;
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Formik, Form } from "formik";
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import { filterFalsy } from 'Utils';
import { UpdateAuthDataValues, ChangePasswordData } from 'Types'; 
import UpdateProfileForm, { updateProfileFormSchema} from 'Components/Forms/UpdateProfileForm';
import ChangePasswordForm, { changePasswordFormSchema } from 'Components/Forms/ChangePasswordForm';
import VerificationCode from 'Containers/SignIn/VerificationCode';
import {pick, has} from 'lodash'
import { useEffect } from 'react';
import { isEmail, isPhoneNumber } from 'Components/Forms/SignUpForm';
import { useDispatch, useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';
import * as authActions from 'Actions/auth';
import * as amplify from 'Utils/Amplify';
import { CustomCircularProgress } from 'Components/StyledComponents';

const Account = () => {
  // const [ accountSuccessMessage, setAccountSuccessMessage ] = React.useState<String | undefined>()
  // const [ changePassSuccessMessage, setChangePassSuccessMessage ] = React.useState<String | undefined>()
  const dispatch = useDispatch();
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const {
    user,
    pendingEmailOrPhone,
    errors,
    successMessage,
    processPending
  } = auth

  const [ showUnverifiedMessage, setShowUnverifiedMessage] = React.useState<Boolean>(false);

  const [isGoogleUser, setIsGoogleUser] = React.useState(true); // set to true until we know

  React.useEffect(()=>{
    if(auth.user) {
      setIsGoogleUser(auth.user?.username.startsWith("google"))
    }
  },[auth.user])
  useEffect(() =>{
    amplify.verifiedContact()
    .then(({unverified, verified}) => {
      if(!isGoogleUser && (unverified as any)?.email) {
        setShowUnverifiedMessage(true);
      }
      if(!isGoogleUser && (unverified as any)?.phone_number) {
        setShowUnverifiedMessage(true);
      }
    })
    .catch(console.error)
  },[user])
  return (
      <Box sx={{height: 'calc(100vh - 200px)'}}>
      <Box sx={{height: 'calc(100vh - 156px)', 
      width: '100%', overflow: 'scroll', paddingTop: '20px'}}>
      {/* </Box> */}
      <Box sx={{
        marginLeft: '50px',
        width: '600px',
        paddingTop: '20px',
        paddingBottom: '20px',
        backgroundColor: 'black',
        borderRadius: '5px',
        }}>
      <Box sx={{
        color: 'white',
        textAlign: 'left',
        marginLeft: '24px',
        marginRight: '24px',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Lato',
      }}>
          {processPending && <CustomCircularProgress />}
          {
            !pendingEmailOrPhone && !processPending &&
            <>
              <Box sx={{
                marginBottom: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '26px',
                letterSpacing: '0.01em',
                color: '#FFFFFF',
                }}>
              Update Profile
              </Box>
            
              <Formik
                initialValues={{
                  "name": "",
                  "emailOrPhone": "",
                  "countryAcronym": "US",
                  "unknown": ""
                }}
                validate={(values: UpdateAuthDataValues) => {
                  return ValidateForm(updateProfileFormSchema,values)}
                }
                onSubmit={async (values: UpdateAuthDataValues) => {
                  dispatch(authActions.updateAuthData(values))
                }}
                validateOnChange={false}
              >
                {formikProps => (
                <Form
                  onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  dispatch(authActions.resetErrors())
                  formikProps.handleSubmit(e);
                  }}
                >
                  <Box sx={{width: '350px'}}>
                  <UpdateProfileForm 
                    successMessage={successMessage}
                    placeholders={{
                      name: user?.attributes?.name || "",
                      emailOrPhone: user?.attributes?.email || user?.attributes?.phone_number || "",
                      countryAcronym: "",
                      unknown: ""
                    }}
                    disabled={{emailOrPhone: isGoogleUser, name: isGoogleUser}}
                    {...formikProps}
                    errors={{...errors,...formikProps.errors}}
                  />
                  </Box>
                </Form>
                )}
              </Formik>
            </>
          }
          {
            showUnverifiedMessage && !pendingEmailOrPhone &&  !processPending &&
            <Button 
              variant="text" 
              onClick={async () => {
                dispatch(authActions.verifyAttributes())
              }} 
              sx={{
                textAlign: 'center',
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'underline'
                },
                paddingBottom: "20px"
              }}
            >
              {isPhoneNumber(pendingEmailOrPhone) && 
              "Your Phone number is unverified. Please click here to recieve a verification code"}
              {isEmail(pendingEmailOrPhone) && 
              "Your email is unverified. Please click here to receive a confirmation link"}
            </Button>
          }
          {
            pendingEmailOrPhone &&  !processPending &&
            <VerificationCode 
              onSubmit={async confirmationCode => {
                  dispatch(authActions.verifyAttributesSubmit(confirmationCode))
                }
              }
            />
          }
      </Box>
      </Box>
      {
        !isGoogleUser &&
        <Box sx={{
          marginLeft: '50px',
          width: '600px',
          marginTop: '20px', 
          backgroundColor: 'black',
          borderRadius: '5px',
          }}>
          <Box sx={{
            color: 'white',
            textAlign: 'left',
            marginLeft: '24px',
            marginRight: '24px',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '40px',
          }}>
              
              <Box sx={{
                paddingTop: '20px',
                marginBottom: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '26px',
                letterSpacing: '0.01em',
                color: '#FFFFFF',
                fontFamily: 'Lato',
                }}>
              Change Password
              </Box>
          
              <Formik
                initialValues={{
                  "currentPassword": "",
                  "newPassword": "",
                  "repeatNewPassword": "",
                  "unknown": ""
                }}
                validate={(values: ChangePasswordData) => {
                  if(values.newPassword !== values.repeatNewPassword) {
                    return {
                      newPassword: "Passwords must match",
                      repeatNewPassword: "Passwords must match",
                    }
                    }
                  return ValidateForm(changePasswordFormSchema,values)}
                }
                onSubmit={async (values) => {
                  dispatch(authActions.changePassword(values))
                }}
                validateOnChange={false}
                >
                  {formikProps => ( 
                  <Form
                    onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                      e.preventDefault();
                      dispatch(authActions.resetErrors())
                      formikProps.handleSubmit(e);
                    }}
                  >
                    <Box sx={{width: '350px'}}>
                    <ChangePasswordForm  
                      successMessage={successMessage}           
                      {...formikProps}
                      errors={{...errors,...formikProps.errors}}
                    />
                    </Box>
                  </Form>
                  )}
                </Formik>
            </Box>
        </Box>
      }
      </Box>
      </Box>
  )
}

export default Account

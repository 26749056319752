import { Box } from '@mui/material';
import { Menu } from 'Components/RoadmapComponents/Menu';
import { HelpPageContent } from './faqContent'
import DropDownIcon from '../../assets/images/DropDownIcon.png';
import { useEffect, useState, useRef } from 'react';
import { useAWSRumContext } from 'Contexts/AWSRum';
import { useLocation } from 'react-router-dom';
import { SURVEY_LINK } from '../../Utils/Constants'
import './Help.css'


const HelpPage = () => {
  useEffect(() => {
    // If user manually goes to /faq route (instead of clicking on roadmap), will just redirect to faq page on museflow.ai
    window.location.href = 'https://museflow.ai/faq'
  }, []);
  return null;

  const { awsRum } = useAWSRumContext();

  let location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
    awsRum?.recordPageView(location.pathname);
  }, []);

  const [ showThing, setRenderRepertoirePlay ] = useState<{[key: number]: boolean}>({})

  return (
    <Box sx={containerStyles}>
      <Menu selected={"FAQ"}/>
      <Box sx={{width: '100%', height: '100%', minHeight: '100vh'}}>
        <Box sx={{height: '100%',maxHeight: '100vh', overflow: 'scroll', display: 'flex', flexDirection: 'column'}}>
          <Box sx={titleContainerStyles}>
            <Box sx={titleStyles}>
              FAQs
            </Box>
          </Box>
          <Box sx={{
            marginLeft: '50px',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.01em',
            color: '#FFFFFF',
            textAlign: 'left',
            fontFamily: "'Lato', sans-serif"
            }}>

              <p style={{
                marginRight: '150px',
                fontWeight: 'normal',
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.01em',
                color: '#FFFFFF',
                textAlign: 'left',
                fontFamily: "'Lato', sans-serif",
              }}>
            Can't find your answer? Email us at <a style={{color:"#fff"}} href="mailto:support@museflow.ai">support@museflow.ai</a>. 
            We're also always looking for feedback! If you have any comments or suggestions, <a target="_blank" style={{color:"#469F6A"}} href={SURVEY_LINK}>please visit our survey</a>
            </p>
          
          <Box sx={{marginBottom: '20px'}}>
          {HelpPageContent.map((obj, idx) => 
            <FAQItem key={idx} obj={obj} idx={idx} showThing={showThing} setRenderRepertoirePlay={setRenderRepertoirePlay} />
          )}
          </Box>

          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default HelpPage


const FAQItem = ({obj, idx, showThing, setRenderRepertoirePlay}: any) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    let isOpen = false
    if (showThing[idx]) isOpen = true
    let obj = {...showThing}
    if (!isOpen) { obj = {} }
    obj[idx] = !isOpen
    setRenderRepertoirePlay(obj)
    setTimeout(() => {
    if (contentRef.current) {
      contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
    }}, 50)
  };

  return (
    <Box key={`${idx}`} style={{
      marginRight: '100px',
      backgroundColor: '#050B13',
      paddingLeft: '20px',
      paddingRight: '20px',
      borderTopLeftRadius: (idx==0 ? '10px' : '0px'),
      borderTopRightRadius: (idx==0 ? '10px' : '0px'),
      borderBottomLeftRadius: (idx==obj.length-1 ? '10px' : '0px'),
      borderBottomRightRadius: (idx==obj.length-1 ? '10px' : '0px'),
    }}>
      <Box sx={{
        display: 'flex', alignItems: 'center',
        fontSize: '18px',
        justifyContent: 'space-between',
        height: '70px',
        '&:hover': {
          cursor: 'pointer',
        }
      }} 
      onClick={handleClick}>
        {obj.title}
        <img style={{
          width: '30px',
          transform: showThing[idx] ? 'rotate(0deg)' : 'rotate(-90deg)',
          transition: 'transform 0.5s'
        }} src={DropDownIcon} />
      </Box>
      {showThing[idx] &&
      <Box ref={contentRef} style={{height: '10px', marginTop: '-20px', paddingBottom: '10px', marginLeft: '10px', overflow: 'hidden', transition: 'height 0.3s'}}>
        <p style={{opacity: 0.7, whiteSpace: 'pre-line', fontSize: '14px', marginBottom: '0px', display: 'inline-block'}}>
        {obj.text}
        </p>
      </Box>
      }
    </Box>
  )
};

const containerStyles = {
    display: 'flex', 
    height: '100%', 
    width: '100%',
    background: 'linear-gradient(0deg, black, #1D2F44)'
}

const titleContainerStyles = {
    marginTop: '20px',
    width: '100%', 
    height: '100px', display: 'flex', 
    flexDirection: 'row', 
    alignItems: 'center', 
    justifyContent: 'space-between',
    paddingBottom: '20px'
}
const titleStyles = {
    marginLeft: '50px',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '48px',
    lineHeight: '58px',
    letterSpacing: '0.01em',
    color: '#FFFFFF',
    fontFamily: "'Lato', sans-serif"
}


import React, { useEffect } from 'react';
import { Box, Link } from '@mui/material';
import { Formik } from "formik";
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import SignUpForm, {isEmail, isPhoneNumber, signUpFormSchema} from 'Components/Forms/SignUpForm';
import { SignUpData } from 'Types';
import VerificationPending from './VerificationPending';
import VerificationCode from './VerificationCode';
import { MUSEFLOW_OFF_WHITE_1, MUSEFLOW_OFF_WHITE_3 } from 'Components/Colors';
import { useDispatch, useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';
import * as authActions from 'Actions/auth';
import { useNavigate } from 'react-router-dom';

type SignUpProps = {
  goBackFn: () => void
}

const SignUp: React.FC<SignUpProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const { 
    pendingEmailOrPhone,
    processPending,
    errors
   } = auth;

   useEffect(()=>{
    if(pendingEmailOrPhone && isEmail(pendingEmailOrPhone)){
      navigate('/signin')
    }
   },[pendingEmailOrPhone])


  const { goBackFn } = props;


  return (
    <Box 
      sx={{
        display:'flex', 
        flexDirection:'column', 
        alignItems:'center', 
        marginTop: '0px', 
        marginBottom: '20px',
        minHeight: '450px'
      }}
    >
      {/* {
        pendingEmailOrPhone && isEmail(pendingEmailOrPhone) &&
        <VerificationPending
        resendFn={() => {dispatch(authActions.resendSignUp(pendingEmailOrPhone || ""))}}
        goBackFn={() => {
          dispatch(authActions.setPendingEmailOrPhone(undefined))
          goBackFn()
        }}
        />
      } */}
       {
        pendingEmailOrPhone && isPhoneNumber(pendingEmailOrPhone) &&
        <VerificationCode
        // resendFn={() => {dispatch(authActions.resendSignUp(pendingEmailOrPhone || ""))}}
        // goBackFn={() => {
        //   dispatch(authActions.setPendingEmailOrPhone(undefined))
        //   goBackFn()
        // }}
        />
      }
      {
        !pendingEmailOrPhone && !processPending &&
      <Formik
        initialValues={{
          "name": "",
          "emailOrPhone": "",
          "password": "",
          "repeatPassword": "",
          "unknown": "",
          "countryAcronym": "US"
        }}
        validate={async (values: SignUpData) => {
          return ValidateForm(signUpFormSchema,values)
        }}
        onSubmit={async (values) => {
          dispatch(authActions.signUp(values))
        }}
        validateOnChange={false}
      >
        {formikProps => (
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              dispatch(authActions.resetErrors());
              formikProps.handleSubmit(e);
            }}
          >
            <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
              <SignUpForm 
                {...formikProps}
                errors={{...errors,...formikProps.errors}}
              />
            </Box>
          </form>
        )}
      </Formik>
      }
      <Box
        sx={{
          margin: '0px 32px 0px 32px',
          marginTop: 'auto',
          marginBottom: '40px'
        }}
      >
        <p style={{color: '#767C8C', lineHeight: '133%'}}>
        By signing up, you are agreeing to MuseFlow’s <Link href={"https://www.museflow.ai/terms"} target={"_blank"} sx={{color: '#6F87F1'}}> Terms of Service</Link>.
        </p>
      </Box>
      </Box>
  )
}

export default SignUp;
import React from 'react';
import { NoteType } from 'opensheetmusicdisplay';

class LengthError extends Error {
  constructor(expected: number, actual: number) {
    super(`Event streams are not the same length. Expected: ${expected}, Actual: ${actual}`);
  }
}
class BPMMisMatchError extends Error {
  constructor(BPM: number, otherBPM: number) {
    super(`Cannot append two event streams with mismatched BPMs: ${BPM} & ${otherBPM}`)
  }
}
class BeatsPerBarMisMatchError extends Error {
  constructor(beatsPerBar: number, otherBeatsPerBar: number) {
    super(`Cannot append two event streams with mismatched beats per bar: ${beatsPerBar} & ${otherBeatsPerBar}`)
  }
}
class beatNoteTypeMisMatchError extends Error {
  constructor(beatNoteType: NoteType, otherbeatNoteType: NoteType) {
    super(`Cannot append two event streams with mismatched beat note typw: ${beatNoteType} & ${otherbeatNoteType}`)
  }
}
// class EventStreamEqualityError extends Error {}

class ReducerUnrecognizedActionError extends Error {
  constructor(action: string) {
    super(`Reducer used with unreginized action: ${action}`)
  }
}

class DispatchPreUse extends Error {
  constructor() {
    super('dispatch used before reducer was set');
  }
}
class UseStateError extends Error {
  constructor(useStateFunc: Function, providerFunc: React.FC<any>) {
    super(`${useStateFunc.name} must be used within ${providerFunc.name}`)
  }
}

class MissingSignUpFormDataError extends Error {
  constructor(field: string) {
    super(`${field} is missing from sign up data`)
  }
}

class UndefinedNoteTypeError extends Error {
  constructor(field: NoteType) {
    super(`Undefined note type ${field} not accepted`)
  }
}

class UndefinedNoteDivisionsError extends Error {
  constructor(field: number) {
    super(`Undefined note division ${field} not accepted`)
  }
}

export {
  LengthError,
  BPMMisMatchError,
  BeatsPerBarMisMatchError,
  beatNoteTypeMisMatchError,
  ReducerUnrecognizedActionError,
  DispatchPreUse,
  UseStateError,
  MissingSignUpFormDataError,
  UndefinedNoteTypeError,
  UndefinedNoteDivisionsError
}

import { Events } from "phaser";

export type ExerciseToReactEvent =
  | "exercise-complete"
  | "exercise-paused"
  | "exercise-resumed"
  | "current-scene-ready"
  | "exercise-loaded"
  | "exercise-skipped"
  | "exercise-unloaded"
  | "skip-button-request";
export type ReactToExerciseEvent =
  | "unload"
  | "update-objective"
  | "pause-exercise"
  | "resume-exercise"
  | "toggle-play-pause"
  | "start-exercise"
  | "midi-on"
  | "midi-off";

// Used to emit events between React components and Phaser scenes
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Events.EventEmitter
export const EventBus = new Events.EventEmitter();

/// Send event from phaser to react
export const NotifyReact = (event: ExerciseToReactEvent, ...args: any[]) =>
  EventBus.emit(event, ...args);
/// Send event from react to phaser
export const NotifyPhaser = (event: ReactToExerciseEvent, ...args: any[]) => {
  console.debug("sending event: ", event);
  EventBus.emit(event, ...args);
};

/// Listen for events emitted by react
export const ListenForReactEvent = (
  event: ReactToExerciseEvent,
  cb: (args: any) => void,
  ctx?: object,
  once?: boolean
) => (once ? EventBus.once(event, cb, ctx) : EventBus.on(event, cb, ctx));

/// Listen for events emitted by phaser
export const ListenForPhaserEvent = (
  event: ExerciseToReactEvent,
  cb: (ev: ExerciseToReactEvent, args: any) => void,
  ctx?: object
) => EventBus.on(event, cb, ctx);

export const RemoveListener = (
  event: ReactToExerciseEvent | ExerciseToReactEvent,
  fn?: () => void
) => EventBus.off(event, fn);

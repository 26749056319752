import RoundedRect from "Phaser/GameObjects/RoundedRect";
import ExerciseBase from "./ExerciseBase";
import FailMenu from "Phaser/GameObjects/FailMenu";
import Instruction from "Phaser/GameObjects/Instruction";
import { Wipe } from "Phaser/Rendering/Transitions";
import { EventBus } from "Phaser/EventBus";
import { colors } from "Phaser/config";
import Cursor from "Phaser/GameObjects/Cursor";

export class TestExercise extends ExerciseBase {
  progressBar?: Phaser.GameObjects.Rectangle;
  t: number = 0.0;
  cursor: Cursor | null = null;

  constructor() {
    super("TestExercise");
  }

  stop() {
    this.scene.stop();
  }

  create(): void {
    EventBus.emit("current-scene-ready", this);
  }

  init(config: {}) {}
}

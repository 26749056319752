import React from "react";
import { Box } from "@mui/material";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { IRefPhaserGame, PhaserGame } from "Phaser/PhaserGame";
import {
  EventBus,
  ListenForPhaserEvent,
  NotifyPhaser,
  ReactToExerciseEvent,
  RemoveListener,
} from "Phaser/EventBus";
import { NoteData } from "Phaser/GameObjects/Note";
import { Note } from "Phaser/GameObjects/Piano";
import ChapterList, { ChapterSection, getTime, Timestamp } from "../Chapters";
import "./style.css";
import Button from "@mui/material/Button";
import FastForwardRounded from "@mui/icons-material/FastForwardRounded";
import Fullscreen from "Components/Fullscreen";
import { markChapterAsCompleted } from "Actions/chapter";
import { useDispatch } from "react-redux";
import { setLevelSelect } from "Actions/app";
import { useNavigate } from "react-router-dom";
import Play from "../../../assets/images/Play.png";

interface TempoPracticeData {
  instructionText: string;
  instructionWaitTime?: number;
  transition?: string;
  tempo: number;
  skipTo: Timestamp;
  numBeats: number;
}

interface NotePracticeData {
  instructionText: string;
  instructionWaitTime?: number;
  transition?: string;
  skipTo: Timestamp;
  tempo: number;
  notes: NoteData[];
  scale?: number;
  centreWithTails?: boolean;
  paddingHorizontal?: number;
}

export type ExerciseData =
  | ["NoteSequenceExercise", NotePracticeData]
  | ["ExerciseTempoPractice", TempoPracticeData]
  | ["PianoScene", { start: Note; end: Note }]
  | ["TestExercise", {}];

type Padding = {
  paddingHorizontal: number;
  paddingVertical: number;
};

type PlaybackInterruptEvent =
  | ["SceneLoad", ExerciseData, Padding?]
  | ["SceneEvent", { event: ReactToExerciseEvent; args?: any }];

type InteractiveConfig = {
  playedSeconds: Timestamp;
  videoEnd?: boolean;
  end?: Timestamp;
  pause?: boolean;
  event?: PlaybackInterruptEvent;
};

type Visibility = "hidden" | "visible";

const SkipButton = ({
  skip,
  visibility,
}: {
  skip: () => void;
  visibility?: Visibility;
}) => {
  return (
    <Button
      id="skip-button"
      className={visibility}
      sx={{
        backgroundColor: "#4A5AA1",
        borderRadius: "35px",
        width: "5%",
        minWidth: "unset",
        height: "calc(5% * 1.777778)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "absolute",
        bottom: "10%",
        opacity: 0,
        right: "10%",
        padding: "none",
        pointerEvents: "all",
      }}
      variant="contained"
      onClick={() => {
        skip();
      }}
      onKeyUp={(e) => {
        if (e.key === " ") e.preventDefault();
      }}
    >
      <FastForwardRounded sx={{ height: "100%", width: "unset" }} />
    </Button>
  );
};

const ProceedButton = ({
  proceed,
  visibility,
}: {
  proceed: () => void;
  visibility: Visibility;
}) => {
  return (
    <Box onClick={proceed} id="proceed-button">
      <Box sx={playButtonStyles} className={visibility}>
        <img
          alt="play_button"
          style={{ maxWidth: "100%", maxHeight: "100%", margin: "0px" }}
          src={Play}
        />
      </Box>
    </Box>
  );
};

const playButtonStyles = {
  position: "absolute",
  width: "70px",
  height: "70px",
  top: "calc(58%)",
  left: "calc(50% - 35px)",
  padding: "0px",
  margin: "0px",
  borderRadius: "50%",
  pointerEvents: "all",
  "&:hover": {
    background: "rgba(212,134,108,1.0)",
    cursor: "pointer",
    transition: "0.3s",
    boxShadow: "0px 0px 5px 5px rgb(212,134,108,.5)",
  },
};

// These are the layouts that will be rendered!!!
const InteractiveTemplate1 = () => {
  const [lastPauseEventTime, setLastPauseEventTime] = React.useState<number>(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [narrationShouldPause, setNarrationShouldPause] =
    React.useState<boolean>(false);

  const [activeExercise, setActiveExercise] = React.useState(0);
  const [, setCompletionStatus] = React.useState<number[]>([]);
  const [exerciseEnd, setExerciseEnd] = React.useState<number | null>();
  const [currentChapter, setCurrentChapter] = React.useState<number | null>(
    null
  );
  const [completedObjectiveCount, setCompletedObjectiveCount] =
    React.useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sectionData] = React.useState<ChapterSection[]>([
    {
      chapters: [
        {
          start: "0:0",
          title: "The Piano",
          type: "lecture",
        },
        {
          start: "0:12",
          title: "Highest and Lowest Keys",
          type: "exercise",
          numObjectives: 2,
        },
      ],
    },
    {
      chapters: [
        {
          start: "0:26",
          title: "Counting Beats",
          type: "lecture",
        },
        {
          start: "0:56",
          title: "Tempo",
          type: "lecture",
        },
        {
          start: "1:26",
          title: "Playing Beats in Time",
          type: "exercise",
        },
      ],
    },
    {
      chapters: [
        {
          start: "1:44",
          title: "Music Notes",
          type: "lecture",
        },
        {
          start: "2:26",
          title: "Quarter Notes",
          type: "exercise",
        },
        {
          start: "2:37",
          title: "Half Notes",
          type: "lecture",
        },
        {
          start: "2:50",
          title: "Half Notes",
          type: "exercise",
        },
        {
          start: "3:03",
          title: "Whole Notes",
          type: "lecture",
        },
        {
          start: "3:17",
          title: "Whole Notes",
          type: "exercise",
        },
      ],
    },
    {
      chapters: [
        {
          start: "3:26",
          title: "The Metronome",
          type: "lecture",
        },
        {
          start: "3:52",
          title: "Combining Rhythms",
          numObjectives: 3,
          type: "exercise",
        },
      ],
    },
    {
      chapters: [
        {
          start: "4:10",
          title: "The Grand Staff",
          type: "lecture",
        },
        {
          start: "5:13",
          title: "Reading Notes on the Staff",
          type: "lecture",
        },
        {
          start: "5:39",
          title: "Counting Bars",
          type: "lecture",
        },
        {
          start: "5:59",
          title: "Time Signatures",
          type: "lecture",
        },
      ],
    },
  ]);
  const [sections, setSections] = React.useState<ChapterSection[]>([]);

  // Attach an ID to every chapter
  React.useEffect(() => {
    setCompletionStatus([]);
    let idx = 0;
    setSections(
      sectionData.map((section) => {
        return {
          chapters: section.chapters.map((chapter) => {
            return { ...chapter, id: idx++ };
          }),
        };
      })
    );
  }, [sectionData]);

  const [interactiveConfig] = React.useState<InteractiveConfig[]>([
    {
      playedSeconds: "0:14",
      event: [
        "SceneLoad",
        [
          "PianoScene",
          {
            start: "A0",
            end: "C8",
          },
        ],
      ],
    },
    {
      playedSeconds: "0:15",
      event: [
        "SceneEvent",
        {
          event: "update-objective",
          args: ["Play the key furthest to the left on your piano.", false, 1],
        },
      ],
    },
    {
      playedSeconds: "0:17",
      end: "0:18",
      pause: true,
      event: [
        "SceneEvent",
        {
          event: "start-exercise",
        },
      ],
    },
    {
      playedSeconds: "0:20.5",
      end: "0:24",
      event: [
        "SceneEvent",
        {
          event: "update-objective",
          args: ["Play the key furthest to the right on your piano.", 2],
        },
      ],
    },
    {
      playedSeconds: "0:22",
      pause: true,
    },
    /*{
      playedSeconds: "0:25",
      event: [
        "SceneEvent",
        {
          event: "unload",
        },
      ],
    },*/
    {
      playedSeconds: "1:27.5",
      end: "1:34",
      event: [
        "SceneLoad",
        [
          "ExerciseTempoPractice",
          {
            skipTo: "1:34.8",
            instructionText:
              "Use any key to tap out 8 beats in sync with the tempo.",
            instructionWaitTime: 5,
            tempo: 72,
            numBeats: 8,
          },
        ],
      ],
    },
    /*{
        playedSeconds: 184,
        event: ["SceneEvent", { event: "visualise-gap" }],
      },*/
    {
      playedSeconds: "1:33",
      pause: true,
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    {
      playedSeconds: "1:35.5",
      end: "1:38",
      event: [
        "SceneLoad",
        [
          "ExerciseTempoPractice",
          {
            skipTo: "1:39",
            instructionText:
              "Use any key to tap out 8 beats in sync with the tempo.",
            instructionWaitTime: 1.5,
            tempo: 120,
            numBeats: 8,
          },
        ],
      ],
    },
    {
      playedSeconds: "1:36.5",
      pause: true,
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    {
      playedSeconds: "1:40",
      end: "1:43",
      event: [
        "SceneLoad",
        [
          "ExerciseTempoPractice",
          {
            skipTo: "1:44",
            instructionText:
              "Use any key to tap out 8 beats in sync with the tempo.",
            instructionWaitTime: 1,
            tempo: 40,
            numBeats: 8,
          },
        ],
      ],
    },
    {
      playedSeconds: "1:41.5",
      pause: true,
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    {
      playedSeconds: "2:28",
      end: "2:34.5",
      event: [
        "SceneLoad",
        [
          "NoteSequenceExercise",
          {
            scale: 1.4,
            instructionText: "Play four quarter notes in a row, using any key.",
            instructionWaitTime: 4,
            skipTo: "2:36",
            transition: "Dissolve",
            tempo: 60,
            notes: [
              { length: "quarter" },
              { length: "quarter" },
              { length: "quarter" },
              { length: "quarter" },
            ],
          },
        ],
      ],
    },
    {
      playedSeconds: "2:33.2",
      pause: true,
      event: [
        "SceneEvent",
        {
          event: "start-exercise",
        },
      ],
    },
    {
      playedSeconds: "2:51.5",
      end: "3:01",
      event: [
        "SceneLoad",
        [
          "NoteSequenceExercise",
          {
            scale: 1.4,
            instructionText: "Play three half notes in a row, using any key.",
            instructionWaitTime: 5,
            transition: "Dissolve",
            tempo: 60,
            skipTo: "3:02",
            notes: [{ length: "half" }, { length: "half" }, { length: "half" }],
          },
        ],
      ],
    },
    {
      playedSeconds: "3:00",
      pause: true,
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    {
      playedSeconds: "3:18.5",
      end: "3:25",
      event: [
        "SceneLoad",
        [
          "NoteSequenceExercise",
          {
            scale: 1.4,
            instructionText: "Play two whole notes in a row, using any key.",
            transition: "Dissolve",
            instructionWaitTime: 3,
            tempo: 60,
            skipTo: "3:26",
            notes: [{ length: "whole" }, { length: "whole" }],
            paddingHorizontal: 500,
            centreWithTails: true,
          },
        ],
      ],
    },
    {
      playedSeconds: "3:24",
      pause: true,
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    {
      playedSeconds: "3:53.5",
      end: "4:00",
      event: [
        "SceneLoad",
        [
          "NoteSequenceExercise",
          {
            skipTo: "4:01",
            scale: 0.8,
            instructionText: "",
            tempo: 72,
            notes: [
              { length: "half" },
              { length: "half" },
              { length: "quarter" },
              { length: "quarter" },
              { length: "half" },
            ],
          },
        ],
      ],
    },
    {
      playedSeconds: "3:56",
      event: [
        "SceneEvent",
        {
          event: "update-objective",
          args: ["Use any key to play these notes in time with the metronome."],
        },
      ],
    },
    {
      playedSeconds: "3:59",
      pause: true,
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    /*{
      playedSeconds: "4:00.5",
      event: ["SceneEvent", { event: "unload" }],
    },*/
    {
      playedSeconds: "4:01.5",
      end: "4:04",
      event: [
        "SceneLoad",
        [
          "NoteSequenceExercise",
          {
            skipTo: "4:05",
            scale: 0.8,
            instructionText:
              "Use any key to play these notes in time with the metronome.",
            instructionWaitTime: 1,
            tempo: 72,
            notes: [
              { length: "quarter" },
              { length: "quarter" },
              { length: "quarter" },
              { length: "quarter" },
              { length: "half" },
              { length: "half" },
            ],
          },
        ],
      ],
    },
    {
      pause: true,
      playedSeconds: "4:03",
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    /*{
      playedSeconds: "4:04",
      event: ["SceneEvent", { event: "unload" }],
    },*/
    {
      playedSeconds: "4:05.5",
      end: "4:08",
      event: [
        "SceneLoad",
        [
          "NoteSequenceExercise",
          {
            skipTo: "4:10",
            scale: 0.8,
            instructionText:
              "Use any key to play these notes in time with the metronome.",
            instructionWaitTime: 1,
            tempo: 72,
            notes: [
              { length: "quarter" },
              { length: "half" },
              { length: "quarter" },
              { length: "whole" },
            ],
            centreWithTails: true,
            paddingHorizontal: 300,
          },
        ],
      ],
    },
    {
      pause: true,
      playedSeconds: "4:07",
      event: ["SceneEvent", { event: "start-exercise" }],
    },
    {
      playedSeconds: "6:36",
      videoEnd: true,
    },
    /*{
      playedSeconds: "4:08",
      event: ["SceneEvent", { event: "unload" }],
    },*/
  ]);

  const phaserRef = React.useRef<IRefPhaserGame | null>(null);
  const videoRef = React.useRef<ReactPlayer>(null);

  const [exercisePaused, setExercisePaused] = React.useState(true);
  const [showExercise, setShowExercise] = React.useState(false);
  const [skipDestination, setSkipDestination] = React.useState(0);
  const [skipButtonVisibility, setSkipButtonVisibility] = React.useState<
    Visibility | undefined
  >(undefined);
  const [proceedButtonVisibility, setProceedButtonVisibility] =
    React.useState<Visibility>("hidden");

  /*React.useEffect(() => {
    console.log(interactiveConfig.toJS());
  }, [interactiveConfig]);*/

  const loadScene = React.useCallback(
    (name: string, props: any) => {
      if (phaserRef.current?.scene) {
        phaserRef.current.scene.scene.start(name, {
          ...props,
        });
      } else {
        console.error("phaser scene manager not initialised!");
      }
    },
    [phaserRef]
  );

  const onSeek = React.useCallback(() => setLastPauseEventTime(0), []);

  // Mark a video chapter as complete automatically after two seconds watching
  React.useEffect(() => {
    setCompletedObjectiveCount(0);
    const chap =
      currentChapter !== null
        ? sections.flatMap((s) => s.chapters)[currentChapter]
        : null;
    if (chap && chap.type === "lecture") {
      const t = setTimeout(
        () => setCompletionStatus((comp) => [...comp, currentChapter || 0]),
        2000
      );
      dispatch(
        markChapterAsCompleted({ lesson: 0, chapter: currentChapter || 0 })
      );
      return () => clearTimeout(t);
    }
  }, [currentChapter, sections, dispatch]);

  const onProgress = React.useCallback(
    (progress: OnProgressProps) => {
      const { playedSeconds } = progress;
      // was there an event this frame?
      let eventThisFrame = false;
      const max = sections
        .flatMap((x) => x.chapters)
        .filter((x) => getTime(x.start) <= playedSeconds)
        .reduce(
          (max, chapter, idx, arr) =>
            chapter.start > arr[max].start ? idx : max,
          0
        );
      setActiveExercise(max);
      if (currentChapter !== max) {
        setCurrentChapter(max);
      }
      for (let indx = 0; indx < interactiveConfig.length; indx++) {
        const config = interactiveConfig[indx];
        const secs = getTime(config.playedSeconds);
        if (
          config &&
          playedSeconds > secs &&
          playedSeconds < secs + 1 &&
          // This is still not rock solid as jumping back immediately to the tutorial you just completed
          // wont pause the video due to the lastPauseEventTime 'guard' thing so we need to unset that
          // state variable whenever the video is seeked (sought?! english is confusing). The same applies
          // for moving between 'chapter's in the video. Basically, when the user manipulates the position
          // of the playback head manually, you reset the lastPauseEventTime guard.
          secs !== lastPauseEventTime
        ) {
          eventThisFrame = true;
          if (config.videoEnd) setProceedButtonVisibility("visible");
          if (config.pause) {
            setNarrationShouldPause(true);
            setIsPlaying(false);
          }
          setLastPauseEventTime(secs);
          //setInteractiveComponentVisibleAtIndex(indx, true);
          //setExerciseActive(true);
          if (config.event) {
            if (config.end) setExerciseEnd(getTime(config.end));
            switch (config.event[0]) {
              case "SceneEvent":
                const { event, args } = config.event[1];
                console.debug("emitting event, ", event, args);
                if (args) EventBus.emit(event, ...args);
                else EventBus.emit(event);
                break;
              case "SceneLoad":
                setExercisePaused(false);
                const scene = config.event[1][0];
                if (
                  scene === "NoteSequenceExercise" ||
                  scene === "ExerciseTempoPractice"
                ) {
                  setSkipDestination(getTime(config.event[1][1].skipTo));
                }
                loadScene(config.event[1][0], {
                  ...config.event[1][1],
                });
                break;
              default:
                throw new Error("Unexpected event!");
            }
          }
        }
      }
      if (!eventThisFrame && playedSeconds > 1.5 + lastPauseEventTime) {
        setLastPauseEventTime(-1);
      }
    },
    [interactiveConfig, loadScene, sections, lastPauseEventTime, currentChapter]
  );

  const playVideo = React.useCallback(() => {
    setIsPlaying(true);
    setNarrationShouldPause(false);
    //setInteractiveComponentHidden(true);
    //setExerciseActive();
  }, [setIsPlaying]);

  const resume = React.useCallback(() => {
    setIsPlaying(!narrationShouldPause);
    NotifyPhaser("resume-exercise");
  }, [narrationShouldPause]);

  const pause = React.useCallback(() => {
    setIsPlaying(false);
    NotifyPhaser("pause-exercise");
  }, []);

  React.useEffect(() => {
    // NOTE: This is entirely dependant on the video ALWAYS resuming whenever an exercise gets unloaded
    // due to whatever reason may be (the exercise being completed succesfully / skipped or jumping
    // to a completely different chapter). Point is: if the video playback state doesn't change, this
    // breaks.
    if (isPlaying) {
      setNarrationShouldPause(false);
      setSkipButtonVisibility((prev) => {
        if (prev !== undefined) return "hidden";
        else return undefined;
      });
    }
  }, [isPlaying]);

  const onVideoClick = React.useCallback(
    (e: any) => {
      if (e.target.tagName === "VIDEO") {
        pause();
      }
    },
    [pause]
  );

  const jumpTo = React.useCallback((seconds: number) => {
    setProceedButtonVisibility("hidden");
    if (videoRef && videoRef.current) videoRef.current.seekTo(seconds);
    setCompletedObjectiveCount(0);
    setIsPlaying(true);
    setShowExercise(false);
    //if (type === "exercise") {
    // Instead of calling this particular function manually, use another function (or effects)
    // to handle all cases of interactive sections becoming inactive / disabled.
    setExercisePaused(true);
    phaserRef.current?.scene?.scene.stop();
    NotifyPhaser("unload");
    //}
  }, []);

  // Spacebar event listener
  React.useEffect(() => {
    console.debug(
      "recomputing keydown",
      isPlaying,
      narrationShouldPause,
      exercisePaused
    );
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === " ") {
        if (exercisePaused) NotifyPhaser("resume-exercise");
        else NotifyPhaser("pause-exercise");
        if (isPlaying) setIsPlaying(false);
        else setIsPlaying(!narrationShouldPause);
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [isPlaying, narrationShouldPause, exercisePaused]);

  // Mark an exercise as complete when all of its objectives have been completed
  React.useEffect(() => {
    const ex = sections
      .flatMap((x) => x.chapters)
      .find((x) => x.id === activeExercise);
    console.debug("ex: ", ex);
    if (ex && ex.type === "exercise") {
      const req = ex.numObjectives ?? 1;
      if (completedObjectiveCount === req) {
        setCompletionStatus((comp) => [...comp, activeExercise]);
        dispatch(
          markChapterAsCompleted({ lesson: 0, chapter: activeExercise })
        );
      }
    }
  }, [completedObjectiveCount, sections, activeExercise, dispatch]);

  /*
  // Event listener for skipping exercises
  React.useEffect(() => {
    ListenForPhaserEvent("exercise-skipped", () => {
      jumpTo(skipDestination);
    });
    return () => {
      RemoveListener("exercise-skipped");
    };
  }, [jumpTo, skipDestination]);
  */

  const proceed = React.useCallback(() => {
    dispatch(setLevelSelect({ levelSelect: 1 }));
    navigate("/tutorial");
  }, [dispatch]);

  const skipExercise = React.useCallback(() => {
    jumpTo(skipDestination);
  }, [jumpTo, skipDestination]);

  // Listener for "exercise-complete" events
  React.useEffect(() => {
    ListenForPhaserEvent("exercise-complete", () => {
      setCompletedObjectiveCount((x) => x + 1);
      //setCompletionStatus((comp) => [...comp, activeExercise]);
      if (exerciseEnd) videoRef.current?.seekTo(exerciseEnd);
      playVideo();
    });
    return () => {
      RemoveListener("exercise-complete");
    };
  }, [playVideo, activeExercise, exerciseEnd]);

  // Other phaser event listeners
  React.useEffect(() => {
    ListenForPhaserEvent("exercise-loaded", () => setShowExercise(true));
    ListenForPhaserEvent("exercise-paused", () => setExercisePaused(true));
    ListenForPhaserEvent("exercise-unloaded", () => {
      setExercisePaused(true);
      setShowExercise(false);
    });
    ListenForPhaserEvent("exercise-resumed", () => setExercisePaused(false));
    ListenForPhaserEvent("skip-button-request", () =>
      setSkipButtonVisibility("visible")
    );
    return () => {
      RemoveListener("exercise-unloaded");
      RemoveListener("exercise-paused");
      RemoveListener("exercise-resumed");
      RemoveListener("exercise-loaded");
      RemoveListener("skip-button-request");
    };
  }, []);

  // Event listener for pausing playback when tab / window loses focus
  React.useEffect(() => {
    window.addEventListener("blur", () => {
      pause();
    });
  }, [pause]);

  const aspectRatio = 1.777778;
  // Keeps track of the <video> elements width and height which is used later for scaling the canvas
  // to fit the size of the video
  const [videoPlayerSize, setVideoPlayerSize] = React.useState({
    width: 0,
    height: 0,
  });
  // The original width and height of every frame in the loaded video
  const videoDimens = { width: 1920, height: 1080 };
  const [videoLoaded, setVideoLoaded] = React.useState(false);

  const onResize = React.useCallback(
    (ratio: number) => {
      const vid: HTMLVideoElement = document.getElementsByTagName("video")[0];
      if (!vid) return;
      const height = vid.clientHeight;
      const width = vid.clientWidth;
      const desiredWidth = height * ratio;
      const desiredHeight = width / ratio;
      if (desiredWidth > width) {
        setVideoPlayerSize({ width: width, height: desiredHeight });
        console.debug(width, height, desiredWidth, desiredHeight);
      } else {
        setVideoPlayerSize({ width: desiredWidth, height: height });
        console.debug(width, height, desiredWidth, desiredHeight);
      }
    },
    [setVideoPlayerSize]
  );

  const onVideoLoad = React.useCallback(() => {
    const vid: HTMLVideoElement = document.getElementsByTagName("video")[0];
    const ratio = vid.videoWidth / vid.videoHeight;
    //setAspectRatio(ratio);
    //setVideoDimens({ width: vid.videoWidth, height: vid.videoHeight });
    onResize(ratio);
    setVideoLoaded(true);
  }, [onResize]);

  // Window resize event listener
  React.useEffect(() => {
    window.addEventListener("resize", () => onResize(aspectRatio));
    return () => {
      window.removeEventListener("resize", () => onResize(aspectRatio));
    };
  }, [aspectRatio, onResize]);

  // Check if an element is within the viewport without any clipping
  const isVisible = (el: Element) => {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  };

  // Scroll to current chapter
  React.useEffect(() => {
    const el = document.querySelector(`#chapter-${activeExercise}`);
    if (el) console.debug(el, isVisible(el));
    if (el && !isVisible(el)) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeExercise]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        background: "linear-gradient(270deg, #1D2F44 28.99%, #071423 90.95%)",
        alignItems: "top",
        justifyContent: "center",
      }}
    >
      <ChapterList
        sections={sections}
        jumpTo={jumpTo}
        active={activeExercise}
        lesson={0}
      />
      <Box
        onClick={onVideoClick}
        sx={{
          display: "flex",
          margin: "20px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          cursor: "pointer",
        }}
      >
        <>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <ReactPlayer
              // in order to re-pause whenever the times above are reached, the state has to stay in sync
              // with the component
              ref={videoRef}
              onPlay={playVideo}
              //controls={true}
              onSeek={onSeek}
              playing={isPlaying}
              type="video/mov"
              url="https://assets.museflow.ai/videos/unit/1/level-0.mp4"
              onReady={onVideoLoad}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
              }}
              onProgress={onProgress}
              width="100%"
              height="100%"
              className="react-player"
            />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                pointerEvents: "none",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                <Box
                  sx={{
                    visibility: videoLoaded ? "visible" : "hidden",
                    width:
                      videoPlayerSize.width > 0 ? videoPlayerSize.width : 1280,
                    height:
                      videoPlayerSize.height > 0 ? videoPlayerSize.height : 720,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <ProceedButton
                    proceed={proceed}
                    visibility={proceedButtonVisibility}
                  />
                  <SkipButton
                    skip={skipExercise}
                    visibility={skipButtonVisibility}
                  />
                  <Box
                    sx={{
                      zIndex: 99,
                      pointerEvents: "all",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                    }}
                  >
                    <Fullscreen
                      show_label={false}
                      use_dark_icon={isPlaying || !exercisePaused}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      visibility: showExercise ? "visible" : "hidden",
                    }}
                  >
                    <PhaserGame ref={phaserRef} width={1920} height={1080} />
                  </Box>
                  {!isPlaying && exercisePaused && (
                    <Box
                      onClick={resume}
                      sx={{
                        background: "#000000aa",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: 0,
                        pointerEvents: "all",
                      }}
                    >
                      <p style={{ fontSize: 28 }}>
                        click here or press spacebar to play/pause
                      </p>
                    </Box>
                  )}
                </Box>
              }
            </Box>
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default InteractiveTemplate1;

import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { ResetPasswordData } from "Types";
import { CustomTextField, CustomButton } from "Components/StyledComponents"
import { FormikErrors, FormikProps } from "formik";
import * as yup from "yup";

export type ResetPasswordFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FormikProps<ResetPasswordData>;

export const resetPasswordFormSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
  repeatPassword: yup
    .string()
    .required("Repeat Password is a required field")
    .min(8, "Password must be at least 8 characters")
});


const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (
  props: ResetPasswordFormProps
) => {
  const { handleChange, values, errors } = props;

  return (
    <Box
    sx ={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}

    >

      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="password"
        name="password"
        type="password"
        label="Password"
        value={values.password || ""}
        error={!!errors.password}
        helperText={errors.password}
        fullWidth
      />

      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="repeatPassword"
        name="repeatPassword"
        type="password"
        label="Confirm Password"
        value={values.repeatPassword || ""}
        error={!!errors.repeatPassword}
        helperText={errors.repeatPassword}
        fullWidth
      />

      <Box>
        { 
        errors.unknown && 
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
        }
      </Box>

      <CustomButton
        variant="contained"
        type="submit"
        sx={{
          minWidth: "200px"
        }}
      >
        Submit
      </CustomButton> 

    </Box>
  );
};

export default ResetPasswordForm;
import React from "react";
import { Box, makeStyles, MenuItem, InputLabel, FormControl, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { find } from 'lodash';
import { SignUpData } from "Types";

const codeStyles = {
  minWidth: 40,
  borderRight: "1px solid #ccc",
  paddingRight: 8,
  marginRight: 8
}

interface CountryType {
  acronym: string;
  label: string;
  code: string;
}


export const codeFromCountryAcronym = (acronym: string) => find(countryCodes, country => country.acronym === acronym)?.code

export const countryCodes: CountryType[] = [
  {
    acronym: 'US',
    label: 'United States',
    code: '1',
  },
  {
    acronym: 'CA',
    label: 'Canada',
    code: '1',
  },
  { acronym: 'AD', label: 'Andorra', code: '376' },
  {
    acronym: 'AE',
    label: 'United Arab Emirates',
    code: '971',
  },
  { acronym: 'AF', label: 'Afghanistan', code: '93' },
  {
    acronym: 'AG',
    label: 'Antigua and Barbuda',
    code: '1-268',
  },
  { acronym: 'AI', label: 'Anguilla', code: '1-264' },
  { acronym: 'AL', label: 'Albania', code: '355' },
  { acronym: 'AM', label: 'Armenia', code: '374' },
  { acronym: 'AO', label: 'Angola', code: '244' },
  { acronym: 'AQ', label: 'Antarctica', code: '672' },
  { acronym: 'AR', label: 'Argentina', code: '54' },
  { acronym: 'AS', label: 'American Samoa', code: '1-684' },
  { acronym: 'AT', label: 'Austria', code: '43' },
  {
    acronym: 'AU',
    label: 'Australia',
    code: '61',
  },
  { acronym: 'AW', label: 'Aruba', code: '297' },
  { acronym: 'AX', label: 'Alland Islands', code: '358' },
  { acronym: 'AZ', label: 'Azerbaijan', code: '994' },
  {
    acronym: 'BA',
    label: 'Bosnia and Herzegovina',
    code: '387',
  },
  { acronym: 'BB', label: 'Barbados', code: '1-246' },
  { acronym: 'BD', label: 'Bangladesh', code: '880' },
  { acronym: 'BE', label: 'Belgium', code: '32' },
  { acronym: 'BF', label: 'Burkina Faso', code: '226' },
  { acronym: 'BG', label: 'Bulgaria', code: '359' },
  { acronym: 'BH', label: 'Bahrain', code: '973' },
  { acronym: 'BI', label: 'Burundi', code: '257' },
  { acronym: 'BJ', label: 'Benin', code: '229' },
  { acronym: 'BL', label: 'Saint Barthelemy', code: '590' },
  { acronym: 'BM', label: 'Bermuda', code: '1-441' },
  { acronym: 'BN', label: 'Brunei Darussalam', code: '673' },
  { acronym: 'BO', label: 'Bolivia', code: '591' },
  { acronym: 'BR', label: 'Brazil', code: '55' },
  { acronym: 'BS', label: 'Bahamas', code: '1-242' },
  { acronym: 'BT', label: 'Bhutan', code: '975' },
  { acronym: 'BV', label: 'Bouvet Island', code: '47' },
  { acronym: 'BW', label: 'Botswana', code: '267' },
  { acronym: 'BY', label: 'Belarus', code: '375' },
  { acronym: 'BZ', label: 'Belize', code: '501' },
  {
    acronym: 'CC',
    label: 'Cocos (Keeling) Islands',
    code: '61',
  },
  {
    acronym: 'CD',
    label: 'Congo, Democratic Republic of the',
    code: '243',
  },
  {
    acronym: 'CF',
    label: 'Central African Republic',
    code: '236',
  },
  {
    acronym: 'CG',
    label: 'Congo, Republic of the',
    code: '242',
  },
  { acronym: 'CH', label: 'Switzerland', code: '41' },
  { acronym: 'CI', label: "Cote d'Ivoire", code: '225' },
  { acronym: 'CK', label: 'Cook Islands', code: '682' },
  { acronym: 'CL', label: 'Chile', code: '56' },
  { acronym: 'CM', label: 'Cameroon', code: '237' },
  { acronym: 'CN', label: 'China', code: '86' },
  { acronym: 'CO', label: 'Colombia', code: '57' },
  { acronym: 'CR', label: 'Costa Rica', code: '506' },
  { acronym: 'CU', label: 'Cuba', code: '53' },
  { acronym: 'CV', label: 'Cape Verde', code: '238' },
  { acronym: 'CW', label: 'Curacao', code: '599' },
  { acronym: 'CX', label: 'Christmas Island', code: '61' },
  { acronym: 'CY', label: 'Cyprus', code: '357' },
  { acronym: 'CZ', label: 'Czech Republic', code: '420' },
  {
    acronym: 'DE',
    label: 'Germany',
    code: '49',
  },
  { acronym: 'DJ', label: 'Djibouti', code: '253' },
  { acronym: 'DK', label: 'Denmark', code: '45' },
  { acronym: 'DM', label: 'Dominica', code: '1-767' },
  {
    acronym: 'DO',
    label: 'Dominican Republic',
    code: '1-809',
  },
  { acronym: 'DZ', label: 'Algeria', code: '213' },
  { acronym: 'EC', label: 'Ecuador', code: '593' },
  { acronym: 'EE', label: 'Estonia', code: '372' },
  { acronym: 'EG', label: 'Egypt', code: '20' },
  { acronym: 'EH', label: 'Western Sahara', code: '212' },
  { acronym: 'ER', label: 'Eritrea', code: '291' },
  { acronym: 'ES', label: 'Spain', code: '34' },
  { acronym: 'ET', label: 'Ethiopia', code: '251' },
  { acronym: 'FI', label: 'Finland', code: '358' },
  { acronym: 'FJ', label: 'Fiji', code: '679' },
  {
    acronym: 'FK',
    label: 'Falkland Islands (Malvinas)',
    code: '500',
  },
  {
    acronym: 'FM',
    label: 'Micronesia, Federated States of',
    code: '691',
  },
  { acronym: 'FO', label: 'Faroe Islands', code: '298' },
  {
    acronym: 'FR',
    label: 'France',
    code: '33',
  },
  { acronym: 'GA', label: 'Gabon', code: '241' },
  { acronym: 'GB', label: 'United Kingdom', code: '44' },
  { acronym: 'GD', label: 'Grenada', code: '1-473' },
  { acronym: 'GE', label: 'Georgia', code: '995' },
  { acronym: 'GF', label: 'French Guiana', code: '594' },
  { acronym: 'GG', label: 'Guernsey', code: '44' },
  { acronym: 'GH', label: 'Ghana', code: '233' },
  { acronym: 'GI', label: 'Gibraltar', code: '350' },
  { acronym: 'GL', label: 'Greenland', code: '299' },
  { acronym: 'GM', label: 'Gambia', code: '220' },
  { acronym: 'GN', label: 'Guinea', code: '224' },
  { acronym: 'GP', label: 'Guadeloupe', code: '590' },
  { acronym: 'GQ', label: 'Equatorial Guinea', code: '240' },
  { acronym: 'GR', label: 'Greece', code: '30' },
  {
    acronym: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    code: '500',
  },
  { acronym: 'GT', label: 'Guatemala', code: '502' },
  { acronym: 'GU', label: 'Guam', code: '1-671' },
  { acronym: 'GW', label: 'Guinea-Bissau', code: '245' },
  { acronym: 'GY', label: 'Guyana', code: '592' },
  { acronym: 'HK', label: 'Hong Kong', code: '852' },
  {
    acronym: 'HM',
    label: 'Heard Island and McDonald Islands',
    code: '672',
  },
  { acronym: 'HN', label: 'Honduras', code: '504' },
  { acronym: 'HR', label: 'Croatia', code: '385' },
  { acronym: 'HT', label: 'Haiti', code: '509' },
  { acronym: 'HU', label: 'Hungary', code: '36' },
  { acronym: 'ID', label: 'Indonesia', code: '62' },
  { acronym: 'IE', label: 'Ireland', code: '353' },
  { acronym: 'IL', label: 'Israel', code: '972' },
  { acronym: 'IM', label: 'Isle of Man', code: '44' },
  { acronym: 'IN', label: 'India', code: '91' },
  {
    acronym: 'IO',
    label: 'British Indian Ocean Territory',
    code: '246',
  },
  { acronym: 'IQ', label: 'Iraq', code: '964' },
  {
    acronym: 'IR',
    label: 'Iran, Islamic Republic of',
    code: '98',
  },
  { acronym: 'IS', label: 'Iceland', code: '354' },
  { acronym: 'IT', label: 'Italy', code: '39' },
  { acronym: 'JE', label: 'Jersey', code: '44' },
  { acronym: 'JM', label: 'Jamaica', code: '1-876' },
  { acronym: 'JO', label: 'Jordan', code: '962' },
  {
    acronym: 'JP',
    label: 'Japan',
    code: '81',
  },
  { acronym: 'KE', label: 'Kenya', code: '254' },
  { acronym: 'KG', label: 'Kyrgyzstan', code: '996' },
  { acronym: 'KH', label: 'Cambodia', code: '855' },
  { acronym: 'KI', label: 'Kiribati', code: '686' },
  { acronym: 'KM', label: 'Comoros', code: '269' },
  {
    acronym: 'KN',
    label: 'Saint Kitts and Nevis',
    code: '1-869',
  },
  {
    acronym: 'KP',
    label: "Korea, Democratic People's Republic of",
    code: '850',
  },
  { acronym: 'KR', label: 'Korea, Republic of', code: '82' },
  { acronym: 'KW', label: 'Kuwait', code: '965' },
  { acronym: 'KY', label: 'Cayman Islands', code: '1-345' },
  { acronym: 'KZ', label: 'Kazakhstan', code: '7' },
  {
    acronym: 'LA',
    label: "Lao People's Democratic Republic",
    code: '856',
  },
  { acronym: 'LB', label: 'Lebanon', code: '961' },
  { acronym: 'LC', label: 'Saint Lucia', code: '1-758' },
  { acronym: 'LI', label: 'Liechtenstein', code: '423' },
  { acronym: 'LK', label: 'Sri Lanka', code: '94' },
  { acronym: 'LR', label: 'Liberia', code: '231' },
  { acronym: 'LS', label: 'Lesotho', code: '266' },
  { acronym: 'LT', label: 'Lithuania', code: '370' },
  { acronym: 'LU', label: 'Luxembourg', code: '352' },
  { acronym: 'LV', label: 'Latvia', code: '371' },
  { acronym: 'LY', label: 'Libya', code: '218' },
  { acronym: 'MA', label: 'Morocco', code: '212' },
  { acronym: 'MC', label: 'Monaco', code: '377' },
  {
    acronym: 'MD',
    label: 'Moldova, Republic of',
    code: '373',
  },
  { acronym: 'ME', label: 'Montenegro', code: '382' },
  {
    acronym: 'MF',
    label: 'Saint Martin (French part)',
    code: '590',
  },
  { acronym: 'MG', label: 'Madagascar', code: '261' },
  { acronym: 'MH', label: 'Marshall Islands', code: '692' },
  {
    acronym: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    code: '389',
  },
  { acronym: 'ML', label: 'Mali', code: '223' },
  { acronym: 'MM', label: 'Myanmar', code: '95' },
  { acronym: 'MN', label: 'Mongolia', code: '976' },
  { acronym: 'MO', label: 'Macao', code: '853' },
  {
    acronym: 'MP',
    label: 'Northern Mariana Islands',
    code: '1-670',
  },
  { acronym: 'MQ', label: 'Martinique', code: '596' },
  { acronym: 'MR', label: 'Mauritania', code: '222' },
  { acronym: 'MS', label: 'Montserrat', code: '1-664' },
  { acronym: 'MT', label: 'Malta', code: '356' },
  { acronym: 'MU', label: 'Mauritius', code: '230' },
  { acronym: 'MV', label: 'Maldives', code: '960' },
  { acronym: 'MW', label: 'Malawi', code: '265' },
  { acronym: 'MX', label: 'Mexico', code: '52' },
  { acronym: 'MY', label: 'Malaysia', code: '60' },
  { acronym: 'MZ', label: 'Mozambique', code: '258' },
  { acronym: 'NA', label: 'Namibia', code: '264' },
  { acronym: 'NC', label: 'New Caledonia', code: '687' },
  { acronym: 'NE', label: 'Niger', code: '227' },
  { acronym: 'NF', label: 'Norfolk Island', code: '672' },
  { acronym: 'NG', label: 'Nigeria', code: '234' },
  { acronym: 'NI', label: 'Nicaragua', code: '505' },
  { acronym: 'NL', label: 'Netherlands', code: '31' },
  { acronym: 'NO', label: 'Norway', code: '47' },
  { acronym: 'NP', label: 'Nepal', code: '977' },
  { acronym: 'NR', label: 'Nauru', code: '674' },
  { acronym: 'NU', label: 'Niue', code: '683' },
  { acronym: 'NZ', label: 'New Zealand', code: '64' },
  { acronym: 'OM', label: 'Oman', code: '968' },
  { acronym: 'PA', label: 'Panama', code: '507' },
  { acronym: 'PE', label: 'Peru', code: '51' },
  { acronym: 'PF', label: 'French Polynesia', code: '689' },
  { acronym: 'PG', label: 'Papua New Guinea', code: '675' },
  { acronym: 'PH', label: 'Philippines', code: '63' },
  { acronym: 'PK', label: 'Pakistan', code: '92' },
  { acronym: 'PL', label: 'Poland', code: '48' },
  {
    acronym: 'PM',
    label: 'Saint Pierre and Miquelon',
    code: '508',
  },
  { acronym: 'PN', label: 'Pitcairn', code: '870' },
  { acronym: 'PR', label: 'Puerto Rico', code: '1' },
  {
    acronym: 'PS',
    label: 'Palestine, State of',
    code: '970',
  },
  { acronym: 'PT', label: 'Portugal', code: '351' },
  { acronym: 'PW', label: 'Palau', code: '680' },
  { acronym: 'PY', label: 'Paraguay', code: '595' },
  { acronym: 'QA', label: 'Qatar', code: '974' },
  { acronym: 'RE', label: 'Reunion', code: '262' },
  { acronym: 'RO', label: 'Romania', code: '40' },
  { acronym: 'RS', label: 'Serbia', code: '381' },
  { acronym: 'RU', label: 'Russian Federation', code: '7' },
  { acronym: 'RW', label: 'Rwanda', code: '250' },
  { acronym: 'SA', label: 'Saudi Arabia', code: '966' },
  { acronym: 'SB', label: 'Solomon Islands', code: '677' },
  { acronym: 'SC', label: 'Seychelles', code: '248' },
  { acronym: 'SD', label: 'Sudan', code: '249' },
  { acronym: 'SE', label: 'Sweden', code: '46' },
  { acronym: 'SG', label: 'Singapore', code: '65' },
  { acronym: 'SH', label: 'Saint Helena', code: '290' },
  { acronym: 'SI', label: 'Slovenia', code: '386' },
  {
    acronym: 'SJ',
    label: 'Svalbard and Jan Mayen',
    code: '47',
  },
  { acronym: 'SK', label: 'Slovakia', code: '421' },
  { acronym: 'SL', label: 'Sierra Leone', code: '232' },
  { acronym: 'SM', label: 'San Marino', code: '378' },
  { acronym: 'SN', label: 'Senegal', code: '221' },
  { acronym: 'SO', label: 'Somalia', code: '252' },
  { acronym: 'SR', label: 'Suriname', code: '597' },
  { acronym: 'SS', label: 'South Sudan', code: '211' },
  {
    acronym: 'ST',
    label: 'Sao Tome and Principe',
    code: '239',
  },
  { acronym: 'SV', label: 'El Salvador', code: '503' },
  {
    acronym: 'SX',
    label: 'Sint Maarten (Dutch part)',
    code: '1-721',
  },
  {
    acronym: 'SY',
    label: 'Syrian Arab Republic',
    code: '963',
  },
  { acronym: 'SZ', label: 'Swaziland', code: '268' },
  {
    acronym: 'TC',
    label: 'Turks and Caicos Islands',
    code: '1-649',
  },
  { acronym: 'TD', label: 'Chad', code: '235' },
  {
    acronym: 'TF',
    label: 'French Southern Territories',
    code: '262',
  },
  { acronym: 'TG', label: 'Togo', code: '228' },
  { acronym: 'TH', label: 'Thailand', code: '66' },
  { acronym: 'TJ', label: 'Tajikistan', code: '992' },
  { acronym: 'TK', label: 'Tokelau', code: '690' },
  { acronym: 'TL', label: 'Timor-Leste', code: '670' },
  { acronym: 'TM', label: 'Turkmenistan', code: '993' },
  { acronym: 'TN', label: 'Tunisia', code: '216' },
  { acronym: 'TO', label: 'Tonga', code: '676' },
  { acronym: 'TR', label: 'Turkey', code: '90' },
  {
    acronym: 'TT',
    label: 'Trinidad and Tobago',
    code: '1-868',
  },
  { acronym: 'TV', label: 'Tuvalu', code: '688' },
  {
    acronym: 'TW',
    label: 'Taiwan',
    code: '886',
  },
  {
    acronym: 'TZ',
    label: 'United Republic of Tanzania',
    code: '255',
  },
  { acronym: 'UA', label: 'Ukraine', code: '380' },
  { acronym: 'UG', label: 'Uganda', code: '256' },
  { acronym: 'UY', label: 'Uruguay', code: '598' },
  { acronym: 'UZ', label: 'Uzbekistan', code: '998' },
  {
    acronym: 'VA',
    label: 'Holy See (Vatican City State)',
    code: '379',
  },
  {
    acronym: 'VC',
    label: 'Saint Vincent and the Grenadines',
    code: '1-784',
  },
  { acronym: 'VE', label: 'Venezuela', code: '58' },
  {
    acronym: 'VG',
    label: 'British Virgin Islands',
    code: '1-284',
  },
  {
    acronym: 'VI',
    label: 'US Virgin Islands',
    code: '1-340',
  },
  { acronym: 'VN', label: 'Vietnam', code: '84' },
  { acronym: 'VU', label: 'Vanuatu', code: '678' },
  { acronym: 'WF', label: 'Wallis and Futuna', code: '681' },
  { acronym: 'WS', label: 'Samoa', code: '685' },
  { acronym: 'XK', label: 'Kosovo', code: '383' },
  { acronym: 'YE', label: 'Yemen', code: '967' },
  { acronym: 'YT', label: 'Mayotte', code: '262' },
  { acronym: 'ZA', label: 'South Africa', code: '27' },
  { acronym: 'ZM', label: 'Zambia', code: '260' },
  { acronym: 'ZW', label: 'Zimbabwe', code: '263' },
];


export default function SimpleSelect(
  props: {
    handleChange: (event: SelectChangeEvent<HTMLInputElement>) => void,
    countryAcronym?: any  // fuck typescript
  }
) {
  const { handleChange, countryAcronym} = props;
  return (
    <Select 
      id="countryAcronym"
      name="countryAcronym"
      value={countryAcronym}
      label="Country Code" // code is derived from acronym, but as far as the user is concerned, it's code.
      onChange={handleChange}
      sx={{
        marginRight: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#050B13', 
        // padding: '12px 28px',
        background: '#CA7C62', 
        // boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
        borderRadius: '40px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        letterSpacing: '2.5px',
        fontWeight: '700',
        fontSize: '16px',
        '&:hover': {
          background: "rgb(202,124,98,1.0)",
          cursor: 'pointer',
          transition: '0.3s',
          },
        "&.Mui-disabled": {
          backgroundColor: '#767C8C',
        },
        "&.MuiInputBase-root": {
          minWidth: '120px',
          height: '60px'
        }
      }}
    >
      {
        countryCodes.map((option, indx) => {
          return (
          <MenuItem value={option.acronym} key={indx}>
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.acronym.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.acronym.toLowerCase()}.png`}
              alt=""
              style={{paddingRight: '5px'}}
            />
            +{option.code}
          </MenuItem>
          )
        })
      }
    </Select>
  );
}

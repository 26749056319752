import React, { RefObject } from "react";
import { Box } from '@mui/material';
import Phrase from 'Models/Phrase';
import { ReactSVG } from "react-svg";
import CursorV2 from 'Components/Cursorv2';
import ITimeKeeper from 'Models/ITimeKeeper';

type StaffProps = {
  divRef: RefObject<HTMLDivElement>,
  phrase: Phrase,
  timeSig: boolean,
  className?: string,
  style?: React.CSSProperties
  timeKeeper: ITimeKeeper
  startTimestamp: number,
  isPlaying: boolean,
  countInTimestamp: number,
  cursorIsVisible: boolean,
  phraseSvgUrl: string,
  // lineIsActive: boolean // as opposed to "isPlaying", this tells us if this specific cursor should be moving
  phraseIndex?: number,
  scrollPosition?: any,
  setScrollPosition?: any,
  isRepertoireStaff?: boolean,
}

const Staff: React.FC<StaffProps & React.ComponentProps<React.FC>> = ({
  divRef,
  phrase,
  style,
  timeSig,
  timeKeeper,
  startTimestamp,
  isPlaying,
  countInTimestamp,
  cursorIsVisible,
  phraseSvgUrl,
  className,
  phraseIndex,
  scrollPosition,
  setScrollPosition,
  isRepertoireStaff,
}) => {
  // const countInTimestamp = 0

  return (
      <Box
        sx={{
          ...{
            position: isRepertoireStaff ? 'relative' : 'absolute',
            width: '90%',
            margin: isRepertoireStaff ? 'auto' : 'unset',
            minWidth: '60rem',
            display: 'flex',
            justifyContent: 'center'
          },
          ...style
        }}
        ref={divRef}
      >
        
          <Box
          sx={{
            ...style
          }}
        >
          {/* The cursor selector will go with whichever phrase the cursor is associated to */}
          {/* { isRepertoireStaff &&
            <CursorSelector
              timeKeeper={timeKeeper}
              startTimestamp={startTimestamp}
              isPlaying={isPlaying}
              countInTimestamp={countInTimestamp}
              cursorIsVisible={cursorIsVisible}
              phrase={phrase}
              timeSig={timeSig}
              className={className}
            />
          }
       */}
          <CursorV2
            timeKeeper={timeKeeper}
            startTimestamp={startTimestamp}
            isPlaying={isPlaying}
            countInTimestamp={countInTimestamp}
            cursorIsVisible={cursorIsVisible}
            phrase={phrase}
            timeSig={timeSig}
            className={className}
            // Add isLastPhrase (will trigger to stop)
            phraseIndex={phraseIndex}
            scrollPosition={scrollPosition}
            setScrollPosition={setScrollPosition}
            isRepertoireStaff={isRepertoireStaff} // Only for repertoire!
          />
          
          <ReactSVG 
            src={phraseSvgUrl}
          />
        </Box>     
      </Box>
  )
}
export default Staff;
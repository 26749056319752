import { SVGProps } from 'react';
import {Box} from '@mui/material'

const QuarterNoteStemUp = (props: SVGProps<SVGSVGElement> & React.ComponentProps<any>) => {
  return(
    <Box
      zIndex={props.zIndex}
      sx={{
        position: 'relative'
      }}
    >
      <svg 
      width="33" 
      height="101" 
        viewBox="0 0 33 101" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path 
          d="M30.8989 83.726V0.397949"
          stroke={props.stroke}
          stroke-width="2.26"
          stroke-linejoin="bevel"
        />
        <path 
          d="M0.484985 91.1659C0.484985 96.4235 4.75059 100.292 11.4962 100.292C20.9202 100.292 32.0306 92.7531 32.0306 83.7259C32.0306 78.4683 27.765 74.6987 20.9202 74.6987C11.7938 74.6987 0.484985 81.7419 0.484985 91.1659Z"
          fill={props.fill}
        />
      </svg>
    </Box>
  )
}

export default QuarterNoteStemUp
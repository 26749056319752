import Phaser from "phaser";
import { EventBus, NotifyReact } from "../EventBus";
import { colors } from "Phaser/config";
import ExerciseBase from "./ExerciseBase";
import Cursor, { StaffNote, StartTiming } from "Phaser/GameObjects/Cursor";
import { Wipe } from "Phaser/Rendering/Transitions";
import TimeSignature from "Models/TimeSignature";
import IPlayable from "Phaser/GameObjects/Playable";
import { ProgressState } from "Phaser/GameObjects/Progress";

export interface Config {
  tempo: number;
  numBeats: number;
  paddingVertical?: number;
  paddingHorizontal?: number;
  instructionText: string;
  instructionWaitTime: number;
  exerciseIndex: number;
  forceLoad?: boolean;
}

type BeatState = "hit" | "miss" | "late" | "idle";

class Beat implements IPlayable {
  isFocused: boolean = false;
  graphic: Phaser.GameObjects.Text;
  state: BeatState = "idle";
  tweenHandle: Phaser.Tweens.Tween[] = [];
  scene: Phaser.Scene;
  resetColorTimer?: Phaser.Time.TimerEvent;

  constructor(graphic: Phaser.GameObjects.Text, scene: Phaser.Scene) {
    this.graphic = graphic;
    this.scene = scene;
  }

  update(_: number) {}

  setTimingState(state: ProgressState) {
    switch (state) {
      case ProgressState.TimelyStart: {
        this.state = "hit";
        this.graphic.setColor(colors.beatNumberFillCorrect);
        break;
      }
      case ProgressState.LateStart:
        this.state = "late";
        this.graphic.setColor(colors.beatNumberFillOffTime);
        break;

      case ProgressState.Missed:
        this.graphic.setColor(colors.beatNumberFillMiss);
        this.state = "miss";
        break;
      default:
        break;
    }
  }

  reset(forceColor: boolean = false) {
    this.isFocused = false;
    this.tweenHandle.forEach((tween) => tween.destroy());
    this.resetColorTimer?.remove();
    if (forceColor) this.graphic.setColor(colors.beatNumberFillQuestion);
    else {
      this.resetColorTimer = this.scene.time.delayedCall(
        2500,
        () => this.graphic.setColor(colors.beatNumberFillQuestion),
        [],
        this
      );
    }
  }

  setFocus(focus: boolean): void {
    this.resetColorTimer?.remove();
    this.isFocused = focus;
    if (!focus) return this.tweenHandle.forEach((tween) => tween.destroy());
    this.tweenHandle = [
      this.scene.tweens.add({
        targets: this.graphic,
        scale: 1.3,
        x: this.graphic.x - this.graphic.width * 0.15,
        y: this.graphic.y - this.graphic.height * 0.15,
        ease: Phaser.Math.Easing.Quadratic.InOut,
        yoyo: true,
        duration: 150,
      }),
    ];
  }

  destroy() {
    this.isFocused = false;
    this.tweenHandle.forEach((tween) => tween.destroy());
    this.resetColorTimer?.remove();
    this.graphic?.destroy();
  }
}

export class ExerciseTempoPractice extends ExerciseBase {
  SPACE?: Phaser.Input.Keyboard.Key;
  nextEarly: boolean = false;
  config?: Config;
  cursor?: Cursor;
  playAreaSize?: { width: number; height: number; x: number; y: number };
  beatGraphics: Phaser.GameObjects.Text[];
  currentBeat: number;
  beatState: BeatState[];
  beats: Beat[];
  beatTweenHandle: Phaser.Tweens.Tween[] = [];
  lateTimer?: Phaser.Time.TimerEvent;
  missTimer?: Phaser.Time.TimerEvent;
  completionTimer?: Phaser.Time.TimerEvent;
  hitBuffer: boolean = false;
  hitBufferResetTimer?: Phaser.Time.TimerEvent;
  // running is for keeping track of the cursor's movement
  running: boolean = false;
  // started is to determine whether the exercise has started and is ready for input or not
  started: boolean = false;
  history: BeatState[] = [];
  cursorTweenHandle?: Phaser.Tweens.Tween;
  metronomeHandle?: Phaser.Time.TimerEvent;
  startTimerHandle?: Phaser.Time.TimerEvent;
  shakeHandle?: Phaser.Tweens.Tween;
  instructionTimeout?: NodeJS.Timeout;

  constructor() {
    super("ExerciseTempoPractice");
    this.beatGraphics = [];
    this.beatState = [];
    this.beats = [];
    this.currentBeat = -1;
    this.running = false;
  }

  init(config: Config) {
    this.config = config;
    this.setupBackground();
    this.instantiateObjects();
    EventBus.on("update-objective", this.updateObjective, this);
    super.init(config);
    if (config.forceLoad) this.skipLoadTransition();
    else setTimeout(this.transition.bind(this), 500);
    const instructionWaitTime = config.forceLoad
      ? 0.5
      : config.instructionWaitTime;
    if (config.instructionText && instructionWaitTime) {
      this.instructionTimeout = setTimeout(
        () => this.showInstruction(this.config!.instructionText, "Top"),
        instructionWaitTime * 1000
      );
    }
  }

  skipLoadTransition() {
    (this.cameras.main.getPostPipeline("Wipe") as Wipe).progress = 1;
    setTimeout(() => NotifyReact("exercise-loaded"), 50);
  }

  transition(out?: boolean, cb?: () => void, ctx?: object) {
    this.wipe(out, cb, ctx);
    if (!out) setTimeout(() => NotifyReact("exercise-loaded"), 50);
  }

  wipe(reverse?: boolean, onComplete?: () => void, ctx?: object): void {
    const targets = this.cameras.main.getPostPipeline("Wipe") as Wipe;
    this.tweens.add({
      targets,
      progress: reverse ? 0.0 : 1.0,
      duration: reverse ? 500 : 1000,
      onComplete,
      callbackScope: ctx,
    });
  }

  updateObjective(instruction: string): void {
    this.showInstruction(instruction, "Top");
  }

  instantiateObjects() {
    // Pixel ratio for retina displays. Without this, the canvas will be blurry on retina displays.
    const dpr = window.devicePixelRatio;
    const paddingH = (this.config!.paddingHorizontal ?? 50) * dpr;
    const paddingV = (this.config!.paddingVertical ?? 50) * dpr;
    this.playAreaSize = {
      width: this.cameras.main.width - paddingH * 2,
      height: this.cameras.main.height - paddingV * 2,
      x: paddingH,
      y: paddingV,
    };
    const yMid = this.playAreaSize.height / 2;
    const beatWidth = this.playAreaSize.width / (this.config!.numBeats + 1);
    const offset = this.playAreaSize.x;
    for (let i = 0; i < this.config!.numBeats; i++) {
      const graphic = this.add.text(
        (i + 1) * beatWidth + offset + 3 * dpr,
        yMid - 30 * dpr,
        (i + 1).toString(),
        {
          fontFamily: "Lato",
          fontStyle: "bold",
          fontSize: 85 * dpr,
          color: colors.beatNumberFillQuestion,
        }
      );
      graphic.setX(graphic.x - graphic.displayWidth / 2);
      this.beatGraphics.push(graphic);
      this.beats.push(new Beat(graphic, this));
    }
    const beatDuration = 60000 / this.config!.tempo;
    const cursorData: StaffNote[] = this.beats.map((beat) => {
      return {
        position: { x: beat.graphic.x, y: beat.graphic.y },
        durationInMs: beatDuration,
        durationRelative: 0.25,
        playable: beat,
      };
    });
    const endPos = {
      x: this.playAreaSize.width + this.playAreaSize.x,
      y: yMid - 30 * dpr,
    };
    cursorData.push({ position: endPos, durationInMs: 0, durationRelative: 0 });
    console.debug(cursorData);
    this.cursor = new Cursor(
      this,
      offset + beatWidth - this.beatGraphics[0].displayWidth / 2,
      yMid - 150 * dpr,
      45 * dpr,
      300 * dpr,
      20 * dpr,
      new TimeSignature(4, 4),
      this.config!.tempo,
      cursorData
    );
  }

  midiOn(_?: any): void {
    if (this.started) {
      this.onPressKey();
    }
  }

  startExercise() {
    this.started = true;
    this.cursor?.reset();
    this.running = false;
    this.history = [];
    if (this.beats) {
      this.beats.forEach((beat) => beat.reset());
    } else {
      this.instantiateObjects();
    }
    this.currentBeat = -1;
    this.hitBuffer = false;
    console.log("starting");
    this.cursor!.waitForInput();
  }

  /*nextBeat() {
    console.log("current", this.currentBeat);
    this.currentBeat += 1;
    if (this.currentBeat >= this.config!.numBeats) {
      return;
    }

    const beatLength = getDurationFromBeats(1, this.config!.tempo);
    this.missTimer = this.time.delayedCall(
      beatLength * 0.8,
      () => {
        this.setBeatState("miss");
      },
      [],
      this
    );
    if (this.getHitBuffer()) this.setBeatState("hit");
    else this.setBeatState("idle");
  }*/

  /*setBeatState(state: BeatState) {
    if (this.currentBeat >= this.config!.numBeats) return;
    if (state === "hit" || state === "late" || state === "miss") {
      this.history.push(state);
      if (this.missTimer) this.missTimer.remove();
    }
    this.beatState.push(state);
    switch (state) {
      case "hit":
        this.beatGraphics[this.currentBeat].setColor(
          colors.beatNumberFillCorrect
        );
        break;
      case "miss":
        this.beatGraphics[this.currentBeat].setColor(colors.beatNumberFillMiss);
        break;
      case "late":
        this.beatGraphics[this.currentBeat].setColor(
          colors.beatNumberFillOffTime
        );
        break;
      case "idle":
        this.beatGraphics[this.currentBeat].setColor(
          "#" + fillColorIdle.toString(16)
        );
    }
  }*/

  setHitBuffer() {
    this.hitBuffer = true;
    this.hitBufferResetTimer = this.time.delayedCall(
      (this.config!.tempo / 60) * 1000 * 0.2,
      () => {
        this.hitBuffer = false;
      },
      [],
      this
    );
  }

  visualiseBeatGap() {
    console.log("visual");
    const horiz = [];
    const vert = [];
    for (let i = 0; i < this.config!.numBeats - 1; i++) {
      const [l, c, r] = this.createGapVisualObject(i);
      c.scaleX = 0;
      l.scaleY = 0;
      r.scaleY = 0;
      horiz.push(c);
      vert.push(l, r);
    }
    this.tweens.add({
      targets: horiz,
      scaleX: 1,
      duration: 1000,
      ease: "Power2",
      hold: 1000,
      yoyo: true,
    });
    this.tweens.add({
      targets: vert,
      scaleY: 1,
      duration: 500,
      ease: "Power2",
      hold: 1000,
      yoyo: true,
    });
  }

  createGapVisualObject(index: number) {
    const dpr = window.devicePixelRatio;
    const leftBeat = this.beatGraphics[index];
    const rightBeat = this.beatGraphics[index + 1];
    const padding = 10 * dpr;
    const x = leftBeat.x + leftBeat.displayWidth + padding;
    const y = leftBeat.y + leftBeat.getBounds().height / 2;
    const x2 = rightBeat.x - padding;
    const width = x2 - x;
    const height = 2 * dpr;
    const lines = [];
    lines.push(this.add.rectangle(x + height / 2, y, height, 20 * dpr, 0));
    lines.push(this.add.rectangle(x + width / 2, y, width, height, 0x00));
    lines.push(this.add.rectangle(x2 - height / 2, y, height, 20 * dpr, 0));
    return lines;
  }

  getHitBuffer() {
    const val = this.hitBuffer;
    this.hitBuffer = false;
    return val;
  }

  shake(): void {
    this.shakeHandle?.remove();
    const target = this.beatGraphics[0];
    const pos = target.x;
    const dpr = window.devicePixelRatio;
    const duration = Math.min((60 / this.config!.tempo) * 1000, 200);
    this.shakeHandle = this.tweens.add({
      targets: { value: 0 },
      value: Math.PI * 2,
      duration,
      ease: Phaser.Math.Easing.Sine.In,
      repeat: 1,
      onUpdate: (_tween, _target, _key, current) => {
        console.log(current);
        target.setX(pos + Math.sin(current) * 10 * dpr);
      },
    });
  }

  onPressKey() {
    let timing: StartTiming;
    if (this.running === false) {
      [timing] = this.cursor!.tryStart(this.onExerciseEnd, this);
      if (timing === "early") {
        this.showToast("Too early!");
        this.shake();
      } else if (timing === "late") {
        this.showToast("Too late!");
        this.shake();
      } else {
        this.beatGraphics.forEach((graphic) =>
          graphic.setColor(colors.beatNumberFillQuestion)
        );
        this.running = true;
        this.cursor?.onHoldNote();
      }
    } else {
      timing = this.cursor!.checkTiming();
      this.cursor?.onHoldNote();
    }
    /*if (this.currentBeat === -1) {
      this.nextBeat();
    }
    switch (timing) {
      case "early":
        this.setBeatState("late");
        break;
      case "late":
        this.setBeatState("late");
        break;
      case "perfect":
        this.setBeatState("hit");
        break;
    }*/
  }

  stop() {
    this.scene.stop();
  }

  update(time: number) {
    this.cursor?.update(time);
    /*
    if (this.nextEarly) {
      this.nextEarly = false;
    }
    if (
      this.running &&
      this.currentBeat >= -1 &&
      this.currentBeat < this.config!.numBeats - 1
    ) {
      if (
        this.cursor!.x + this.cursor!.width >
        this.beatGraphics[this.currentBeat + 1].x
      ) {
        this.nextBeat();
      }
    }*/
  }

  setupBackground() {}

  passExercise(): void {
    this.started = false;
    this.cursor?.stop();
    super.passExercise();
  }

  onExerciseEnd() {
    console.debug(this.history);
    this.beats.forEach((beat) => console.log("beat state: ", beat.state));
    if (
      this.beats.find(
        (beat) =>
          beat.state === "miss" ||
          beat.state === "late" ||
          beat.state === "idle"
      ) !== undefined
    ) {
      this.failExercise();
    } else {
      this.passExercise();
    }
    this.running = false;
    EventBus.emit("exercise-ended");
  }

  unload(): void {
    console.log("unloaded tempo");
    this.started = false;
    this.cursor?.destroy();
    this.cursor = undefined;
    this.beats?.clear();
    this.beatGraphics.clear();
    this.beatState.clear();
    this.history.clear();
    if (this.instructionTimeout) clearTimeout(this.instructionTimeout);
    super.unload();
  }

  changeScene() {
    //nextExercise(this.config!.exerciseIndex, this.scene);
  }

  pauseExercise() {
    this.cursor?.pause();
    super.pauseExercise();
  }

  resume() {
    this.cursor?.resume();
    super.resume();
  }

  restart(): void {
    this.history.clear();
    this.startExercise();
  }

  create() {
    // This event must be emitted before using any of the integrated phaser modules such as tweens, physics, etc.
    this.cameras.main.setPostPipeline("Wipe");
    EventBus.emit("current-scene-ready", this);
    EventBus.on("visualise-gap", this.visualiseBeatGap, this);
    EventBus.off("update-objective");
    EventBus.on("update-objective", this.updateObjective, this);
  }
}

import { Boot } from "./Scenes/Boot";
import { AUTO, Game } from "phaser";
import { Preloader } from "./Scenes/Preloader";
import NoteSequenceExercise from "./Scenes/NoteSequenceExercise";
import { ExerciseTempoPractice } from "./Scenes/ExerciseTempoPractice";
import { PianoScene } from "./Scenes/Piano";
import { TestExercise } from "./Scenes/TestExercise";
import PauseMenu from "./Scenes/PauseMenu";
import { Dissolve, Wipe } from "./Rendering/Transitions";
import Empty from "./Scenes/Empty";

const StartGame = (parent: string, width: number, height: number) => {
  //  Find out more information about the Game Config at:
  //  https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
  const dpr = window.devicePixelRatio;
  console.log("dpr: ", dpr);
  const config: Phaser.Types.Core.GameConfig = {
    type: AUTO,
    antialias: true,
    scale: {
      mode: Phaser.Scale.FIT,
      width: width * dpr,
      height: height * dpr,
    },
    transparent: true,
    width,
    height,
    parent,
    scene: [
      Boot,
      Preloader,
      NoteSequenceExercise,
      ExerciseTempoPractice,
      PianoScene,
      TestExercise,
      PauseMenu,
      Empty,
    ],
    pipeline: {
      Dissolve: Dissolve,
      Wipe: Wipe,
    } as any,
  };
  return new Game(config);
};

export default StartGame;

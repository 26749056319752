import Phaser from "phaser";

const vertShader = `
precision mediump float;

uniform mat4 uProjectionMatrix;
uniform vec2 uResolution;

attribute vec2 inPosition;
attribute vec2 inTexCoord;
attribute float inTintEffect;
attribute vec4 inTint;

varying vec2 outTexCoord;
varying float outTintEffect;
varying vec4 outTint;

varying vec2 fragCoord;

void main ()
{
    gl_Position = uProjectionMatrix * vec4(inPosition, 1.0, 1.0);

    outTexCoord = inTexCoord;
    outTint = inTint;
    outTintEffect = inTintEffect;

    fragCoord = vec2(inPosition.x, uResolution.y - inPosition.y);
}
`;

export default class Transition extends Phaser.Renderer.WebGL.Pipelines.PostFXPipeline {
  progress: number = 0.0;

  constructor(game: Phaser.Game, fragShader: string) {
    super({game, fragShader})
  }

  onPreRender(): void {
    this.set1f("progress", this.progress);
  }
}

const wipeShader = `
  #define SHADER_NAME WIPE_POST_FX
  #ifdef GL_ES
  precision mediump float;
  #endif
  uniform sampler2D uMainSampler;
  uniform float progress;
  float fadeSize = 0.1;
  varying vec2 outTexCoord;
  void main() {
    vec4 color = texture2D(uMainSampler, outTexCoord);
    float cutoff = mix(0.0, 1.0 + fadeSize, progress);
    float threshold = cutoff - fadeSize;
    float diff = outTexCoord.x - cutoff + fadeSize;
    float fade = smoothstep(fadeSize, 0.0, diff);
    if (outTexCoord.x > threshold) {
        color *= fade;
    }
    gl_FragColor = color;
    //gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
  }
  `;

/*export class Wipe extends Phaser.Renderer.WebGL.Pipelines
  .PostFXPipeline {
  /// The progress of the wipe effect (between 0 - 1)
  progress: number = 0.0;

  constructor(game: Phaser.Game) {
    super({
      game,
      fragShader,
    });
  }

  getProgress() {
    return this.progress;
  }

  setProgresss(value: number) {
    this.progress = value;
    //this.set1f('progress', value);
  }

  onPreRender(): void {
    this.set1f("progress", this.progress);
  }
}*/

export class Wipe extends Transition {
  constructor(game: Phaser.Game) {
    super(game, wipeShader);
  }
}

export class WipeShader extends Phaser.Renderer.WebGL.Pipelines.SinglePipeline {
  constructor(game: Phaser.Game) {
    super({ game, vertShader, fragShader: wipeShader });
  }
}

const dissolveShader = `
  #define SHADER_NAME WIPE_POST_FX
  #ifdef GL_ES
  precision mediump float;
  #endif
  uniform sampler2D uMainSampler;
  uniform float progress;
  varying vec2 outTexCoord;
  void main() {
    vec4 color = texture2D(uMainSampler, outTexCoord);
    color *= progress;
    gl_FragColor = color;
    //gl_FragColor = vec4(0.0, 0.0, 0.0, 1.0);
  }
  `;

export class Dissolve extends Transition {

  constructor(game: Phaser.Game) {
    super(game, dissolveShader);
  }

}

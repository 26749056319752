
import { EventState, EventActionTypes, EventActions, EventStateActions } from "Types/EventTypes";
import immutable from 'immutable';
import { v4 } from 'uuid'

export const defaultEventState: EventState = {
  sessionId: "",
  playSessionId: undefined,
  // queue: [] Not yet
}

export const eventReducer = (state = defaultEventState, action: EventActions | EventStateActions) => {
  if(process.env.REACT_APP_LOG_LEVEL == 'debug') {
    console.log("eventReducer: ", action)
  }
  switch(action.type) {
    case EventActionTypes.SET_SESSION_ID:
      console.log("setting session id")
      return immutable.merge(
        state,
        {
          sessionId: v4(),
        }
      )
    case EventActionTypes.SET_PLAY_SESSION_ID:
        return immutable.merge(
          state,
          {
            playSessionId: action.payload,
          }
        )
    default:
      return state
  }
} 

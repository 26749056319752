import { useState, FC, useEffect } from 'react'
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import { useNavigate } from "react-router-dom";
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import pianoBackground from '../../assets/images/pianoBackground.png';
import { useSelector, useDispatch } from 'react-redux'
import { MainAppReducer } from 'Types';
import { isPhoneNumber } from 'Components/Forms/SignUpForm';
import { confirmSignUp } from 'Utils/Amplify';
import {useMediaQuery} from '@mui/material';
import Logo from 'Components/Logo';
import SignIn from './SignIn'
import '../TrialWelcome/welcome.css'
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VerificationCode from './VerificationCode';
import { useLocation } from 'react-router-dom';
import { useAWSRumContext } from 'Contexts/AWSRum';
import { MUSEFLOW_OFF_WHITE_1 } from 'Components/Colors';
import { AuthReducer, SIGN_IN_COMPONENT } from 'Types/AuthTypes';
import * as authActions from 'Actions/auth';



const Signin: FC<{}> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); 
    const { awsRum } = useAWSRumContext();
    
    const { userLevelData, userData } = useSelector((state: MainAppReducer) => state.mainAppReducer)
    const auth = useSelector((state: AuthReducer) => state.authReducer)
    const { 
      processPending, 
      pendingEmailOrPhone,
      signInComponent,
      resetEmailOrPhone
     } = auth

     useEffect(()=>{
      // this really shouldn't be necessary given default state but it isn't working for some reason
      dispatch(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
     },[])


    const navPlayGuest = () => {
      // dispatch(appActions.fetchClientDataRequest())
      navigate(`/welcome`)
    }

    const setComponentForgotPassword = () => {
      dispatch(authActions.resetErrors())
      dispatch(authActions.setSignInComponent(SIGN_IN_COMPONENT.FORGOT_PASSWORD))
    }

    const setComponentResetPassword = () => {
      dispatch(authActions.resetErrors())
      dispatch(authActions.setSignInComponent(SIGN_IN_COMPONENT.RESET_PASSWORD))
    }

    const setComponentSignIn = () => {
      dispatch(authActions.resetErrors())
      dispatch(authActions.setPendingEmailOrPhone(undefined))
      dispatch(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
    }

    // useEffect(()=>{
    //   if(isPhoneNumber(resetEmailOrPhone)){
    //     dispatch(authActions.resetErrors())
    //     dispatch(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
    //   }
    // }, [pendingEmailOrPhone])
  

    useEffect(()=>{
      if(auth.jwtToken && auth.user) {
        dispatch(authActions.setPendingEmailOrPhone(undefined))
        // Ensure that the userData has BECOME the user data of the user that just signed in!!
        // Otherwise it will show the state according to user data values of the last user before the state switches over.
        if (userData && userData.user_id == auth.user?.attributes?.sub) { // ensure startup sagas has grabbed user data (or created inital state row)
          if (userData.subscription_status == "") { // Blank is before any trial has begun (new user state)
            navigate('/welcome')
          } else { 
            navigate('/roadmap')
          }
        }
      }
    },[navigate, auth.jwtToken, userLevelData, userData])
  

    return (
      <Box className="page-div-styles">
        <Box className="filter-div-styles">
          <Box className="sign-in-div-styles">
            <Logo/>
            <Box className="desc-container welcome-container">
            {
              processPending &&
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <CustomCircularProgress />
              </Box>
            }

              { (signInComponent === SIGN_IN_COMPONENT.SIGNIN) && 
                <SignIn 
                forgotPasswordNavFunction={setComponentForgotPassword}
                goBackFn={setComponentSignIn}
                /> 
              }

              { (signInComponent ===  SIGN_IN_COMPONENT.FORGOT_PASSWORD && 
                <ForgotPassword 
                  goBackFn={setComponentSignIn} 
                  goToReset={setComponentResetPassword}
                />)
              }
            
              { (signInComponent ===  SIGN_IN_COMPONENT.RESET_PASSWORD && 
                <ResetPassword 
                  goToSignIn={setComponentSignIn} 
                  goBackFn={setComponentForgotPassword}
                />)
              }


              {
                (signInComponent === SIGN_IN_COMPONENT.VERIFICATION_CODE &&
                <VerificationCode 
                  backFn={setComponentSignIn}
                />)
              }
            </Box>
          </Box>
        </Box>
      </Box>      
  )
}

const backgroundStyles1 = {
  backgroundImage: `url(${pianoBackground})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: '100vh',
}

const backgroundStyles2 = {
  width: '100%',
  height: '100%',
  backdropFilter: 'brightness(15%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

export default Signin;

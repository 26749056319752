import React from 'react';
import { Box } from '@mui/material';
import Immutable from 'immutable';
// import QuarterNoteUpStem from 'assets/anim-assets/quarter_note_up_stem.svg'
import QuarterNoteStemUp from 'Components/Exercises/Assets/QuarterNoteStemUp'
import  Cursor from 'Components/Exercises/Assets/Cursor';
import { NoteEvent, TimingOffsets, TimingOffsetsConfig } from 'Models/EventStream';
import { NoteType} from 'opensheetmusicdisplay';
import { calculateDurationByType, calculateCorrectNoteOffMin } from 'Utils';

enum NoteState {
  correct = 'correct',
  neutral = 'neutral',
  error = 'error'
}

type NoteStateType = 
  NoteState.correct | NoteState.error | NoteState.neutral

const noteCorrectGreen='#6D9B73';
const noteNeutral='#CABFCD'
const tailCorrectOutline='#B3BC8E';
const tailCorrectGreen='#CCD6A1';
const tailNeutralOutline='#B5ABB8'
const tailNeutral='#CABFCD';
const tailError='red'
const background='#FDE2BE';
const textBackground='#CABFCD';


type AnimatedQuarterNoteProps = {
  tailWidth: number,
  noteState: NoteStateType
}

const AnimatedQuarterNote: React.FC<AnimatedQuarterNoteProps> = ({
  tailWidth,
  noteState
}) => {
  const [noteColorState, setNoteColorState] = React.useState<{
    tailOutline: string,
    tailFill: string,
    noteFill: string
  }>({
    tailOutline: tailCorrectOutline,
    tailFill: tailCorrectGreen,
    noteFill: noteCorrectGreen,
  });
  React.useEffect(()=>{
    if(noteState === NoteState.correct) {
      setNoteColorState({
        tailFill: tailCorrectGreen,
        tailOutline: tailCorrectOutline,
        noteFill: noteCorrectGreen
      })
    } else if(noteState === NoteState.error) {
      setNoteColorState({
        tailFill: tailCorrectGreen,
        tailOutline: tailCorrectOutline,
        noteFill: noteCorrectGreen
      })
    } else if(noteState === NoteState.neutral) {
      setNoteColorState({
        tailFill: tailNeutral,
        tailOutline: tailNeutralOutline,
        noteFill: 'black'
      })
    }
  },[noteState])
  return (
    <Box
    sx={{
      height: '100px',
      position: 'relative',
      justifyContent: 'bottom'
    }}
  >
    <Box
    sx={{
      position: 'absolute',
      background: noteColorState.tailFill,
      height: '22px',
      width: `${tailWidth}px`,
      bottom: 1,
      left: 5,
      borderRadius: '15px 5px 5px 15px;',
      zIndex:9997,
      outlineColor: noteColorState.tailOutline,
      outlineWidth: '2px',
      outlineStyle: noteState === NoteState.neutral ? 'none' : 'solid'
    }}
    />
    <QuarterNoteStemUp stroke={noteColorState.noteFill} fill={noteColorState.noteFill} zIndex={9999}/>
  </Box>
  ) 
}

type NoteLengthsByTypeProps = {
  notes: NoteType[],
  bpm: number
}

const NoteLengthsByType: React.FC<NoteLengthsByTypeProps> = ({
  notes,
  bpm
}) => {

  const animationCallbackRef = React.useRef<((ts: DOMHighResTimeStamp) => void) | null>(null)
  const [currentNote, setCurrentNote] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [exerciseNoteOffsets, setExerciseNoteOffsets] = React.useState(Immutable.List([]))
  const [animationState, setAnimationState] = React.useState(Immutable.List<any>([]))

  const animate = React.useCallback(() => {

  }, []);

  const constructInitialAnimationState = React.useCallback(()=>{
    const initialAnimationState = [];
    const initialTailWidth = 10;
    const offsetsConfig = new TimingOffsetsConfig()
    const startAllowance = 0.25;
    const endAllowance = 0.25;
    let currentOnset = 0;
    for(let i = 0; i < notes.length; i ++) {
      const note = notes[i];
      let currentNoteDuration = calculateDurationByType(note)
      let lastNoteDuration = i == 0 ? 0 : calculateDurationByType(notes[i-1])
      let currentOffset = currentOnset + currentNoteDuration;
      const correctNoteOffsetStartTimestamp = currentOnset + calculateCorrectNoteOffMin(currentNoteDuration)
      const noteOnTimestamps = new TimingOffsets(
        currentOnset === 0 ? -.2 : currentOnset - startAllowance * offsetsConfig.correctNoteOnsetStartPct,
        currentOnset + endAllowance * offsetsConfig.correctNoteOnsetEndPct,
        currentOnset === 0 ? -.2 : currentOnset - startAllowance * offsetsConfig.mistimedNoteOnsetStartPct,
        currentOnset + endAllowance * offsetsConfig.mistimedNoteOnsetEndPct
      )
    

      const noteOffTimestamps = new TimingOffsets(
          correctNoteOffsetStartTimestamp,
          currentOffset + endAllowance * offsetsConfig.correctNoteOffsetEndPct,
          correctNoteOffsetStartTimestamp - currentNoteDuration*0.2,
          currentOffset + endAllowance * offsetsConfig.mistimedNoteOffsetEndPct
      )
      initialAnimationState.push({
        noteOffsets: noteOffTimestamps,
        noteOnsets: noteOnTimestamps
      })
      currentOnset = currentOffset;
    }
  },[]) 

  const init = React.useCallback(()=>{
    // animationCallbackRef.current = (timestamp: DOMHighResTimeStamp) => {
    //   animate()

    //   if (animationCallbackRef.current) {
    //       window.requestAnimationFrame(animationCallbackRef.current)
    //   }
    // }
    // window.requestAnimationFrame(animationCallbackRef.current)
    

  },[])

  React.useEffect(()=>{
   

  }, [])




  return (
    <Box
      sx={{
        width: '100%',
        height: '500px',
        display: 'flex',

        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        background: background,
        // spaceBetween: '20px'
      }}
    >
      <Box
        sx={{
          background: textBackground,
          padding: '10px 10px',
          borderRadius: '5px',
          marginBottom: '30px'
        }}
      >
        <b>Play four quarter notes in a row using any key.</b>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '300px',
          justifyContent: 'space-between',
        }}
      >
      <AnimatedQuarterNote tailWidth={40} noteState={NoteState.correct}/>
      <AnimatedQuarterNote tailWidth={10} noteState={NoteState.neutral}/>
      <AnimatedQuarterNote tailWidth={10} noteState={NoteState.neutral}/>
      <AnimatedQuarterNote tailWidth={10} noteState={NoteState.neutral}/>
      </Box>
    </Box>
  )
}

export default NoteLengthsByType
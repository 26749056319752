import React from 'react';
import { Box, Typography } from '@mui/material';
import { Formik, Form } from "formik";
import { ResetPasswordData } from 'Types';
import ResetPasswordForm, {resetPasswordFormSchema} from 'Components/Forms/ResetPasswordForm';
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import GoBackButton from 'Components/GoBackButton';
import VerificationCode from './VerificationCode';
import * as authActions from 'Actions/auth';
import { AuthReducer } from 'Types/AuthTypes';
import { useDispatch, useSelector } from 'react-redux';

type ResetCodeProps = {
  goBackFn: () => void,
  goToSignIn: () => void
}

const ResetCode: React.FC<ResetCodeProps> = (props) => {

  const { goBackFn, goToSignIn } = props
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const dispatch = useDispatch();

  const {
    processPending,
    resetEmailOrPhone, 
    confirmationCode,
    errors
  } = auth


  const resetSuccessful = !processPending && !resetEmailOrPhone && !confirmationCode;
  return (
    <Box 
      sx={{
      display:'flex',
      flexDirection:'column', 
      alignItems:'center', 
      marginTop: '20px',
      marginBottom: '20px'
      }}
    >
      {resetSuccessful &&
        <Box
          sx={{
            maxWidth: '600px'
          }}
        > 
          <Typography 
            sx={{
              margin: "10px"
            }}
            color="rgb(255,255,255,1.0)"
          >
            Your password has been reset. Go back to the sign in tab to log in. 
          </Typography> 
        </Box>
      }
      {resetEmailOrPhone && confirmationCode &&
        <>
        <Box 
         sx={{
           maxWidth: '400px',
           margin: '20px'
         }}>
           <Typography
             sx={{
               fontSize: '20px',
               color: 'rgb(255,255,255,1.0)'
             }}
           > 
             Reset Your Password
           </Typography>
         </Box>
         <Formik
         initialValues={{
           "password": "",
           "repeatPassword": "",
           "unknown": ""
         }}
         validate={(values: ResetPasswordData) => {
          if(values.password !== values.repeatPassword) {
            return {
              password: "Passwords must match",
              repeatPassword: "Passwords must match",
            }
          }
           return ValidateForm(resetPasswordFormSchema,values)}
         }
         onSubmit={async (values) => {
          dispatch(authActions.submitNewPassword({password:  values.password}))
         }}
         validateOnChange={false}
       >
         {formikProps => (
           <Form
             onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
               e.preventDefault();
               formikProps.handleSubmit(e);
             }}
           >
             <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
               <ResetPasswordForm 
                 {...formikProps}
                 errors={{...errors,...formikProps.errors}}
               />
             </Box>
           </Form>
         )}
         </Formik>
       </>
      }
      { resetEmailOrPhone && !confirmationCode &&  
        <VerificationCode />
      }
      <Box>
        <GoBackButton 
        variant="text"
        onClick={() => {
          // Pretty
          if(resetSuccessful) {
            goToSignIn()
          } else if(confirmationCode) {
            // this will set the page view to enter confirmation code again. 
            // But messy but w/e
            dispatch(authActions.SetConfirmationCode(undefined))
          } else {
            goBackFn()
          }
        }}
        sx={{
          cursor: 'pointer',
          textDecoration: 'underline',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
        >
          Back
        </GoBackButton>
      </Box>
    </Box>
  )
}

export default ResetCode;
import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { ForgotPasswordData } from "Types";
import { CustomTextField, CustomButton } from "Components/StyledComponents"
import { FormikErrors, FormikProps } from "formik";
import { isPhoneNumberWithCountryCode, validateEmailOrPhone } from "./SignUpForm";
import * as yup from "yup";
import CountryCodeSelect from "./CountryCodeSelect";

export type ForgotPasswordFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FormikProps<ForgotPasswordData>;

export const forgotPasswordFormSchema = yup.object().shape({
  emailOrPhone: yup
    .string()
    .test("validateEmailorPhone", "Must be an email or phone number", validateEmailOrPhone)
    .required("Email or Phone is a required field"),
  countryAcronym: yup.string()
});


const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (
  props: ForgotPasswordFormProps
) => {
  const { handleChange, values, errors } = props;
  const [isPhoneNumber, setIsPhoneNumber] = React.useState(false);

  useEffect(()=>{
    if(isPhoneNumberWithCountryCode(values.emailOrPhone)) {
      setIsPhoneNumber(true)
    } else {
      setIsPhoneNumber(false)
    }
  },[values.emailOrPhone])


  return (
    <Box
      sx ={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}
    >
      
      <Box
        sx={{
          minWidth: 310,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
      { isPhoneNumber &&
          <CountryCodeSelect 
            handleChange={handleChange}
            countryAcronym={values.countryAcronym}
          />
        }
      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="emailOrPhone"
        name="emailOrPhone"
        type="emailOrPhone"
        label="Email or Phone Number"
        value={values.emailOrPhone || ""}
        error={!!errors.emailOrPhone}
        helperText={errors.emailOrPhone}
        fullWidth
      />

      </Box>
      <Box>
        { 
        errors.unknown && 
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
        }
      </Box>
      <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <CustomButton
        variant="contained"
        type="submit"
        sx={{
          minWidth: "200px"
        }}
      >
        RESET PASSWORD
      </CustomButton>
      </Box> 

    </Box>
  );
};

export default ForgotPasswordForm;
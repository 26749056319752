import { AuthState, ChangePassword, ConfirmSignUp, ConfirmSignUpPayload, ForgotPassword, LogIn, LogInPlayload, LogOut, ResendSignUp, SIGN_IN_COMPONENT, SignUp, SubmitNewPassword, UpdateAuthData, VerifyAttributes, VerifyAttributesSubmit, VerifyPendingEmailOrPhone, authActionTypes } from 'Types/AuthTypes'
import * as amplify from 'Utils/Amplify';
import { Auth } from "aws-amplify";
import * as authActions from 'Actions/auth';
import { put, takeEvery, select, getContext } from 'redux-saga/effects'
import { AmplifyAuthError, AugmentedCognitoUser, UpdateAuthDataValues, actionTypes } from 'Types';
import { handleAuthErrors } from 'Utils/Forms';
import { awsRum } from 'Utils/AwsRum';
import { isEmail, isPhoneNumber } from 'Components/Forms/SignUpForm';
import { filterFalsy } from 'Utils';
import { conforms, has, pick, take } from 'lodash';
import { codeFromCountryAcronym } from 'Components/Forms/CountryCodeSelect';



function* tryGetCurrentAuthenticatedUser() {
  try {
    const currentAuthenticatedUser: AugmentedCognitoUser = yield Auth.currentAuthenticatedUser()
    yield put(authActions.setUser({user: currentAuthenticatedUser}))
    yield put(authActions.setToken({token: currentAuthenticatedUser.getSignInUserSession()?.getAccessToken().getJwtToken()}))
    yield put(authActions.setAuthPending(false))
    // user found, delete temp sign up pass
    yield put(authActions.setTempSignUpPass(undefined))
  } catch {
    yield put(authActions.setAuthPending(false))
    yield put(authActions.logOut())
  }
}

function* onLogIn(logInAction: LogIn): any {
    const { emailOrPhone, password, countryAcronym } = logInAction.payload;
    try {
      yield put(authActions.setProcessPending(true))
      const user: AugmentedCognitoUser = yield amplify.signIn({ emailOrPhone, password, countryAcronym });
      yield put(
        authActions.setUser({
          user
        })
      )
      const token = user.getSignInUserSession()?.getAccessToken().getJwtToken()
      if(token) {
        yield put(
          authActions.setToken({
            token
          })
        )
      } else {
        awsRum?.recordError("Unable to retreive access token from newly signed in user")
      }
      yield put(
        authActions.setPendingEmailOrPhone(undefined)
      )
      yield put(
        authActions.setTempSignUpPass(undefined)
      )
      yield put(authActions.setProcessPending(false))
      yield put(authActions.resetErrors())
    } catch(err) {
      yield put(authActions.setProcessPending(false))
      const authError = err as unknown as AmplifyAuthError
      const handledAuthErrors = handleAuthErrors(err as AmplifyAuthError);
      yield put(authActions.setErrors(handledAuthErrors))
      if(authError.code === "UserNotConfirmedException") {
        yield put(authActions.setPendingEmailOrPhone(emailOrPhone))
        yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.VERIFICATION_CODE))
      } else {
        if(Object.keys(handledAuthErrors).includes('unknown')) {
          awsRum?.recordError(err)
        }
      }
    }
}

function *onLogOut(logOutAction: LogOut) {
  try {
   yield amplify.signOut();
  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    authActions.setErrors(handledAuthErrors)
    if(Object.keys(handledAuthErrors).includes('unknown')) {
      awsRum?.recordError(err)
    }
  }
}


function* signUp(signUpEvent: SignUp) {
  try {
    yield put(authActions.setProcessPending(true))
    yield put(authActions.setTempSignUpPass(signUpEvent.payload.password))
    yield amplify.signUpUser(signUpEvent.payload)
    yield put(authActions.setPendingEmailOrPhone(signUpEvent.payload.emailOrPhone))
    yield put(authActions.setPendingEmailOrPhone(signUpEvent.payload.emailOrPhone))
    if(isPhoneNumber(signUpEvent.payload.emailOrPhone)) {
      yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.VERIFICATION_CODE))
    } else {
      yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
    }

  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
      if(authError.code === "UserNotConfirmedException") {
        authActions.setPendingEmailOrPhone(signUpEvent.payload.emailOrPhone);
      } else {
        const handledAuthErrors = handleAuthErrors(err as AmplifyAuthError);
        yield put(authActions.setErrors(handledAuthErrors))
        if(Object.keys(handledAuthErrors).includes('unknown')) {
          awsRum?.recordError(err)
        }
      }
  } finally {
    yield put(authActions.setProcessPending(false))
  }
}

function* confirmSignUp(confirmSignUp: ConfirmSignUp) {
  const {phoneNumber, confirmationCode, countryAcronym} = confirmSignUp.payload
  const { tempSignUpPass }: AuthState = yield select(({authReducer}) => authReducer)

  try {
    if(phoneNumber && confirmationCode && tempSignUpPass) {
      yield put(authActions.setProcessPending(true))
      yield amplify.confirmSignUp(phoneNumber, confirmationCode, countryAcronym)
      yield put(authActions.setPendingEmailOrPhone(undefined))
      yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
      yield put(authActions.logIn({password: tempSignUpPass, emailOrPhone: phoneNumber, countryAcronym: countryAcronym }))
    } else {
      console.warn(`No emailOrPhone or ConfirmationCode on confirm sign up: emailOrPhone: ${phoneNumber}, confirmatonCode: ${confirmationCode}`)
      awsRum?.recordError(new Error(`No emailOrPhone or ConfirmationCode on confirm sign up: emailOrPhone: ${phoneNumber}, confirmatonCode: ${confirmationCode}`))
    }
  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    yield put(authActions.setErrors(handledAuthErrors))
      if(authError.code === "UserNotConfirmedException") {
        yield put(authActions.setPendingEmailOrPhone(phoneNumber))
      } else {
        if(Object.keys(handledAuthErrors).includes('unknown')) {
          awsRum?.recordError(err)
        }
      }
  }
}

function* resendSignUp(resendSignUp: ResendSignUp) {
  //TODO: this should probably all come from redux state
  const emailOrPhone = resendSignUp.payload
  const { pendingCountryAcronym }: AuthState = yield select(({authReducer}) => authReducer)

  try {
    yield amplify.resendSignUp(emailOrPhone, pendingCountryAcronym);
  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    yield put(authActions.setErrors(handledAuthErrors))
    if(authError.code === "UserNotConfirmedException") {
      yield put(authActions.setPendingEmailOrPhone(emailOrPhone))
    } else {
      if(Object.keys(handledAuthErrors).includes('unknown')) {
        awsRum?.recordError(err)
      }
    }
  }
}

function *refreshUser(){
  const user: AugmentedCognitoUser = yield Auth.currentAuthenticatedUser({ bypassCache: true })
  yield put(authActions.setUser({user}))
}

function *verifyPendingEmailOrPhone(verifyPending: VerifyPendingEmailOrPhone) {
  const { pendingEmailOrPhone }: AuthState = yield select(({authReducer}) => authReducer)
  
  if(pendingEmailOrPhone) {
    yield amplify.confirmSignUp(pendingEmailOrPhone, verifyPending.payload)
  }
  yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
}

function *verifyAttributes(verifyAttributes: VerifyAttributes) {
  const { pendingEmailOrPhone }: AuthState = yield select(({authReducer}) => authReducer)
  if(isPhoneNumber(pendingEmailOrPhone)) {
    yield amplify.verifyUserAttribute("phone_number")
  }
  if(isEmail(pendingEmailOrPhone)) {
    yield amplify.verifyUserAttribute("email")
  }
  yield put(authActions.setPendingEmailOrPhone(undefined))
  yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.VERIFICATION_CODE))
}

function *verifyAttributesSubmit(verifyAttributesSubmit: VerifyAttributesSubmit) {
  const code = verifyAttributesSubmit.payload;
  const { pendingEmailOrPhone, user }: AuthState = yield select(({authReducer}) => authReducer)
  try {
    if(isPhoneNumber(pendingEmailOrPhone)) {
      yield amplify.verifyCurrentUserAttributeSubmit("phone_number", code);
    }
    if(isEmail(pendingEmailOrPhone)) {
      yield amplify.verifyCurrentUserAttributeSubmit("email", code);
    }
    yield put(authActions.refreshUser())
    yield put(authActions.setPendingEmailOrPhone(undefined))
  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(err as AmplifyAuthError);
    yield put(authActions.setErrors(handledAuthErrors))
    if(authError.code === "UserNotConfirmedException" && !pendingEmailOrPhone) {
      if(user?.attributes?.email) {
        yield put( authActions.setPendingEmailOrPhone(user?.attributes.email))
      } else if(user?.attributes?.phone_number) {
        yield put(authActions.setPendingEmailOrPhone(user?.attributes.phone_number))
      }
    } else {
      if(Object.keys(handledAuthErrors).includes('unknown')) {
        awsRum?.recordError(err)
      }
    }
  }
}


function *updateAuthData(updateAuthData: UpdateAuthData) {
  const { user }: AuthState = yield select(({authReducer}) => authReducer)
  const values = updateAuthData.payload;
  const filteredValues: Partial<UpdateAuthDataValues> = filterFalsy(pick(updateAuthData.payload, ['name', 'emailOrPhone']));
  let finalValues: {name?: string, email?: string, phone_number?: string} = {};
  yield put(authActions.setProcessPending(true))
  if(has(filteredValues, 'emailOrPhone') && isPhoneNumber(filteredValues['emailOrPhone'])) {
    const strippedNumber = "+" + codeFromCountryAcronym(updateAuthData.payload.countryAcronym) + filteredValues['emailOrPhone']?.replace(/[^0-9.]/g, '');
    finalValues['phone_number'] = strippedNumber;
  }
  if(has(filteredValues, 'emailOrPhone') && isEmail(filteredValues['emailOrPhone'])) {
    finalValues['email'] = filteredValues['emailOrPhone'];
    yield put 
  }
  if(has(filteredValues, 'name')) {
    finalValues.name = filteredValues.name;
  }
  try {
    yield amplify.updateUserAttributes(
      finalValues
    )
    if(values?.emailOrPhone &&
      (user?.attributes?.email !== values?.emailOrPhone &&
      user?.attributes?.phone_number !== values?.emailOrPhone)
    ) {
      console.log("setting pending")
      // they've changed their phone or email. set pending.
      yield put(authActions.setPendingEmailOrPhone(values.emailOrPhone))
      yield put(authActions.setPendingCountryAcronym(values.countryAcronym))
      yield put(authActions.refreshUser())
      // authActions.setResetEmailOrPhone(values.emailOrPhone)
    }
    // TODO: maybe we should have a global success/error message box that can be filled in here to replace this?
    else {
      yield put(authActions.setSuccessMessage("profile successfully updated"))
    }
    yield put(authActions.setProcessPending(false))
  } catch(err) {
    yield put(authActions.setProcessPending(false))
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    yield put(authActions.setErrors(handledAuthErrors))
    if(authError?.code === "UserNotConfirmedException") {
      yield put(authActions.setPendingEmailOrPhone(values.emailOrPhone))
    } else {
      if(Object.keys(handledAuthErrors).includes('unknown')) {
        awsRum?.recordError(err)
      }
    }
  }
}

function *changePassword(changePassword: ChangePassword) {
  const { user, pendingEmailOrPhone }: AuthState = yield select(({authReducer}) => authReducer)
  const {currentPassword, newPassword} = changePassword.payload;
  try {
    yield amplify.changePassword(
      currentPassword, newPassword
    )
    yield put(authActions.setSuccessMessage("Password successfully updated"))
  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    yield put(authActions.setErrors(handledAuthErrors))
    if(authError.code === "UserNotConfirmedException" && !pendingEmailOrPhone) {
      if(user?.attributes?.email) {
        yield put(authActions.setPendingEmailOrPhone(user?.attributes.email))
      } else if(user?.attributes?.phone_number) {
        yield put( authActions.setPendingEmailOrPhone(user?.attributes.phone_number))
      }
    } else {
      if(Object.keys(handledAuthErrors).includes('unknown')) {
        awsRum?.recordError(err)
      }
    }
  }

}

function *forgotPassword(forgotPassword: ForgotPassword) {

  const {emailOrPhone, countryAcronym} = forgotPassword.payload;
  try {
    yield amplify.forgotPassword(emailOrPhone, countryAcronym)
    yield put(authActions.setResetEmailOrPhone(emailOrPhone))
    yield put(authActions.setResetCountryAcronym(countryAcronym))
    yield put(authActions.setProcessPending(false))
    yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.VERIFICATION_CODE))

  } catch(err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    yield put(authActions.setErrors(handledAuthErrors))
    if(authError?.code === "UserNotConfirmedException") {
      yield put(authActions.setAuthPending(true))
      yield put(authActions.setPendingEmailOrPhone(emailOrPhone))
    } else {
      if(Object.keys(handledAuthErrors).includes('unknown')) {
        awsRum?.recordError(err)
      }
    }
  }
}

function *submitNewPassword(submitNewPassword: SubmitNewPassword) {
  const { user, confirmationCode, resetEmailOrPhone, resetCountryAcronym}: AuthState = yield select(({authReducer}) => authReducer)
  const { password } = submitNewPassword.payload
  const emailOrPhone = resetEmailOrPhone || user?.attributes.email || user?.attributes.phone_number
  try {
    if(confirmationCode && emailOrPhone) {

      yield amplify.submitForgotPassword(emailOrPhone, confirmationCode, password, resetCountryAcronym);
      yield put(authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN))
    } else {
      console.warn(`No emailOrPhone or ConfirmationCode on submit new password: emailOrPhone: ${emailOrPhone}, confirmatonCode: ${confirmationCode}`)
      awsRum?.recordError(new Error(`No emailOrPhone or ConfirmationCode on submit new password: emailOrPhone: ${emailOrPhone}, confirmatonCode: ${confirmationCode}`))
    }

  } catch (err) {
    const authError = err as unknown as AmplifyAuthError
    const handledAuthErrors = handleAuthErrors(authError);
    yield put(authActions.setErrors(handledAuthErrors))
    if(authError?.code === "UserNotConfirmedException") {
      yield put(authActions.setAuthPending(true))
      yield put(authActions.setPendingEmailOrPhone(emailOrPhone))
    } else {
      if(Object.keys(handledAuthErrors).includes('unknown')) {
        awsRum?.recordError(err)
      }
    }
  }
}

export default [
  takeEvery(authActionTypes.TRY_GET_CURRENT_AUTH, tryGetCurrentAuthenticatedUser),
  takeEvery(authActionTypes.LOG_IN, onLogIn),
  takeEvery(authActionTypes.LOG_OUT, onLogOut),
  takeEvery(authActionTypes.SIGN_UP, signUp),
  takeEvery(authActionTypes.CONFIRM_SIGN_UP, confirmSignUp),
  takeEvery(authActionTypes.RESEND_SIGN_UP, resendSignUp),
  takeEvery(authActionTypes.VERIFY_PENDING_EMAIL_OR_PHONE, verifyPendingEmailOrPhone),
  takeEvery(authActionTypes.VERIFY_ATTRIBUTES, verifyAttributes),
  takeEvery(authActionTypes.REFRESH_USER, refreshUser),
  takeEvery(authActionTypes.VERIFY_ATTRIBUTES_SUBMIT, verifyAttributesSubmit),
  takeEvery(authActionTypes.CHANGE_PASSWORD, changePassword),
  takeEvery(authActionTypes.UPDATE_AUTH_DATA, updateAuthData),
  takeEvery(authActionTypes.FORGOT_PASSWORD, forgotPassword),
  takeEvery(authActionTypes.SUBMIT_NEW_PASSWORD, submitNewPassword)
]

import { AugmentedCognitoUser, UpdateAuthDataValues } from "Types";



export enum SIGN_IN_COMPONENT {
  SIGNIN = 'SIGNIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  VERIFICATION_CODE = 'VERIFICATION_CODE'
} 


export interface AuthReducer {
  authReducer: AuthState,
}

export interface AuthState {
  jwtToken: string | undefined,
  idToken: string | undefined,
  user: AugmentedCognitoUser | undefined,
  confirmationCode: string | undefined
  authPending: boolean,
  processPending: boolean,
  errors: SetErrorsPayload,
  resetEmailOrPhone: string | undefined,
  resetCountryAcronym: string | undefined,
  pendingEmailOrPhone: string | undefined,
  pendingCountryAcronym: string | undefined,
  pendingEmailOrPhoneSuccessful: boolean,
  signInComponent: SIGN_IN_COMPONENT
  successMessage: string | undefined
  failureMessage: string | undefined
  tempSignUpPass: string | undefined // used only on sign up to sign in after verification
}

export enum authActionTypes {
  SET_TOKEN = "SET_TOKEN",
  SET_ID_TOKEN = "SET_ID_TOKEN",
  LOG_IN = "LOG_IN",
  LOG_OUT = "LOG_OUT",
  SIGN_UP = "SIGN_UP",
  CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SET_CONFIRMATION_CODE = "SET_CONFIRMATION_CODE",
  SET_RESET_EMAIL_OR_PHONE = "SET_RESET_EMAIL_OR_PHONE",
  SET_RESET_COUNTRY_ACRONYM = "SET_RESET_COUNTRY_ACRONYM",
  SUBMIT_NEW_PASSWORD = "SUBMIT_NEW_PASSWORD",
  UPDATE_USER_ATTRIBUTES = "UPDATE_USER_ATTRIBUTES",
  VERIFY_CURRENT_USER_ATTRIBUTES = "VERIFY_CURRENT_USER_ATTRIBUTES",
  RESEND_SIGN_UP = "RESEND_SIGN_UP",
  SET_AUTH_PENDING = "SET_AUTH_PENDING",
  // "process pending" is for events that aren't explicitly signing in and out, i.e. reset password
  SET_PROCESS_PENDING = "SET_PROCESS_PENDING",
  RESET_ERRORS = "RESET_ERRORS",
  SET_ERRORS = "SET_ERRORS",
  SET_USER = "SET_USER",
  SET_PENDING_EMAIL_OR_PHONE = "SET_PENDING_EMAIL_OR_PHONE",
  SET_PENDING_COUNTRY_ACRONYM = "SET_PENDING_COUNTRY_ACRONYM",
  SET_PENDING_EMAIL_OR_PHONE_SUCCESSFUL = "PENDING_EMAIL_OR_PHONE_SUCCESSFUL",
  SET_SIGN_IN_COMPONENT = "SET_SIGN_IN_COMPONENT",
  VERIFY_PENDING_EMAIL_OR_PHONE = "VERIFY_PENDING_EMAIL_OR_PHONE",
  VERIFY_ATTRIBUTES = "VERIFY_ATTRIBUTES",
  VERIFY_ATTRIBUTES_SUBMIT = "VERIFY_ATTRIBUTES_SUBMOIT",
  UPDATE_AUTH_DATA = "UPDATE_AUTH_DATA",
  REFRESH_USER = "REFRESH_USER",
  SET_SUCCESS_MESSAGE = "SET_SUCCESS_MESSAGE",
  SET_FAILURE_MESSAGE = "SET_FAILURE_MESSAGE",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  TRY_GET_CURRENT_AUTH = "TRY_GET_CURRENT_AUTH",
  SET_TEMP_SIGNUP_PASS = "SET_TEMP_SIGNUP_PASS"
}

export interface SetTokenPayload {
  token: string | undefined
}

export interface SetIdTokenPayload {
  idToken: string
}

export interface LogInPlayload {
  emailOrPhone: string,
  countryAcronym: string,
  password: string
}

export interface SignUpPayload {
  name: string,
  emailOrPhone: string,
  password: string,
  repeatPassword: string,
  unknown: string,
  countryAcronym: string
}

export interface ConfirmSignUpPayload {
  phoneNumber: string,
  countryAcronym: string,
  confirmationCode: string
}

export interface SetUserPayload {
  user: AugmentedCognitoUser | null,
}

export interface SetPendingEmailOrPhonePayload {
  pendingEmailOrPhone: string
}

export interface SetPendingEmailOrPhoneSuccessfulPayload {
  pendingEmailOrPhoneSuccessful: boolean
}


// SetErrorsPayload contains all types of form fields for any auth related form
export interface SetErrorsPayload {
  code: string;
  emailOrPhone: string;
  name: string;
  password: string;
  repeatPassword: string;
  unknown: string;
  currentPassword: string;
  newPassword: string;
  repeatNewPassword: string;
}

export interface ChangePasswordPayload {
  currentPassword: string
  newPassword: string
}

export interface SubmitNewPasswordPayload {
  // confirmation code and emailOrPhone should be set in state 
  password: string
}

export interface SetToken {
  type: typeof authActionTypes.SET_TOKEN;
  payload: SetTokenPayload
}

export interface SetIdToken {
  type: typeof authActionTypes.SET_ID_TOKEN;
  payload: SetIdTokenPayload
}

export interface LogIn {
  type: typeof authActionTypes.LOG_IN;
  payload: LogInPlayload
}

export interface LogOut {
  type: typeof authActionTypes.LOG_OUT;
}

export interface SignUp {
  type: typeof authActionTypes.SIGN_UP;
  payload: SignUpPayload
}

export interface ConfirmSignUp {
  type: typeof authActionTypes.SIGN_UP;
  payload: ConfirmSignUpPayload
}

export interface SetProcessPending {
  type: typeof authActionTypes.SET_PROCESS_PENDING;
  payload: boolean
}

export interface SetAuthPending {
  type: typeof authActionTypes.SET_AUTH_PENDING;
  payload: boolean
}

export interface SetConfirmationCode {
  type: typeof authActionTypes.SET_CONFIRMATION_CODE;
  payload: string
}

export interface SetUser {
  type: typeof authActionTypes.SET_USER;
  payload: SetUserPayload
}

export interface SetErrors {
  type: typeof authActionTypes.SET_ERRORS;
  payload: SetErrorsPayload
}

export interface ResetErrors {
  type: typeof authActionTypes.RESET_ERRORS;
}

export interface SetPendingEmailOrPhone {
  type: typeof authActionTypes.SET_PENDING_EMAIL_OR_PHONE,
  payload: string
}

export interface SetPendingCountryAcronym {
  type: typeof authActionTypes.SET_PENDING_COUNTRY_ACRONYM,
  payload: string
}

export interface SetPendingEmailOrPhoneSuccessful {
  type: typeof authActionTypes.SET_PENDING_EMAIL_OR_PHONE_SUCCESSFUL,
  payload: SetPendingEmailOrPhoneSuccessfulPayload
}

export interface ResendSignUp {
  type: typeof authActionTypes.LOG_IN,
  payload: string
}

export interface RefreshUser {
  type: typeof authActionTypes.REFRESH_USER
}


export interface ForgotPassword {
  type: typeof authActionTypes.FORGOT_PASSWORD,
  payload: {emailOrPhone: string, countryAcronym: string}
}

export interface VerifyPendingEmailOrPhone {
  type: typeof authActionTypes.VERIFY_PENDING_EMAIL_OR_PHONE,
  payload: string
}

export interface SetSignInComponent {
  type: typeof authActionTypes.SET_SIGN_IN_COMPONENT,
  payload: SIGN_IN_COMPONENT
}

export interface VerifyAttributes {
  type: typeof authActionTypes.VERIFY_ATTRIBUTES
}

export interface VerifyAttributesSubmit {
  type: typeof authActionTypes.VERIFY_ATTRIBUTES_SUBMIT,
  payload: string
}

export interface UpdateAuthData {
  type: typeof authActionTypes.UPDATE_AUTH_DATA,
  payload: UpdateAuthDataValues
}

export interface SetSuccessMessage {
  type: typeof authActionTypes.SET_SUCCESS_MESSAGE,
  payload: string
}

export interface SetFailureMessage {
  type: typeof authActionTypes.SET_FAILURE_MESSAGE,
  payload: string
}

export interface ChangePassword {
  type: typeof authActionTypes.CHANGE_PASSWORD,
  payload: ChangePasswordPayload
}

export interface SubmitNewPassword {
  type: typeof authActionTypes.SUBMIT_NEW_PASSWORD,
  payload: SubmitNewPasswordPayload
}

export interface SetResetEmailOrPhone {
  type: typeof authActionTypes.SET_RESET_EMAIL_OR_PHONE,
  payload: string
}

export interface SetResetCountryAcronym {
  type: typeof authActionTypes.SET_RESET_COUNTRY_ACRONYM,
  payload: string
}

export interface TryGetCurrentAuth {
  type: typeof authActionTypes.TRY_GET_CURRENT_AUTH,
}

export interface SetTempSignUpPass {
  type: typeof authActionTypes.SET_TEMP_SIGNUP_PASS,
  payload: string
}



export type AuthActions =
  | SetToken
  | SetIdToken 
  | LogOut
  | LogIn
  | SignUp
  | ConfirmSignUp
  | SetProcessPending
  | SetAuthPending
  | SetConfirmationCode
  | SetUser
  | SetErrors
  | ResetErrors
  | SetPendingEmailOrPhone
  | SetPendingEmailOrPhoneSuccessful
  | SetPendingCountryAcronym
  | ResendSignUp
  | RefreshUser
  | ForgotPassword
  | SetSignInComponent
  | VerifyPendingEmailOrPhone
  | VerifyAttributes
  | VerifyAttributesSubmit
  | UpdateAuthData
  | SetSuccessMessage
  | SetFailureMessage
  | SetErrors
  | ChangePassword
  | SubmitNewPassword
  | SetResetEmailOrPhone
  | SetResetCountryAcronym
  | TryGetCurrentAuth
  | SetTempSignUpPass
const StartingULP = () => {
  return ({
    "bpm": 60,
    "current_tier": 1,
    "highest_tier_complete": 0,
    "level": {
      "level_number": 0,
    },
    "phrases_til_next_tier": 4,
    "status": "active",
  })
}
export default StartingULP

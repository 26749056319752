// Ripped directly from Stack Overflow: https://stackoverflow.com/questions/21277900/how-can-i-pause-setinterval-functions
export class IntervalTimer {
  callbackStartTime = 0;
  remaining = 0;
  paused = false;
  timerId: NodeJS.Timer | undefined = undefined;
  _callback;
  _delay;

  constructor(callback: () => void, delay: number) {
      this._callback = callback;
      this._delay = delay;
  }

  reset(callback: () => void, delay: number) {
    clearInterval(this.timerId);
    this.callbackStartTime = 0
    this.remaining = 0
    this.paused = false
    this._callback = callback;
    this._delay = delay;
  }

  pause() {
      if (!this.paused) {
          this.clear();
          this.remaining = new Date().getTime() - this.callbackStartTime;
          this.paused = true;
      }
  }

  resume() {
      if (this.paused) {
          if (this.remaining) {
              setTimeout(() => {
                  this.run();
                  this.paused = false;
                  this.start();
              }, this.remaining);
          } else {
              this.paused = false;
              this.start();
          }
      }
  }

  clear() {
    clearInterval(this.timerId);
  }

  start() {
      this.clear();
      this.timerId = setInterval(() => {


          this.run();
      }, this._delay);
  }

  run() {
      this.callbackStartTime = new Date().getTime();
      this._callback();
  }
}
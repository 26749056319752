import { Box, TextField, Button } from '@mui/material';
import { Menu } from 'Components/RoadmapComponents/Menu';
import { useState } from 'react';
import userIcon from '../../assets/images/userIcon.png';
import pianoIcon from '../../assets/images/pianoIcon.png';
import notificationIcon from '../../assets/images/notificationIcon.png';
import cardIcon from '../../assets/images/cardIcon.png';
import selectedUserIcon from '../../assets/images/selectedUserIcon.png';
import selectedPianoIcon from '../../assets/images/selectedPianoIcon.png';
import selectedNotificationIcon from '../../assets/images/selectedNotificationIcon.png';
import selectedCardIcon from '../../assets/images/selectedCardIcon.png';
import Account from './Account';
import Keyboard from './Keyboard';
import Notifications from './Notifications';
import Billing from './Billing';
const SettingsPage = () => {
  const [subSelect, setSubSelect] = useState('account');

  return (
    <Box //className="foo" 
    sx={containerStyles}
    >
      <Menu selected={"Settings"}/>
      <Box sx={{
        width: 'calc(100% - 100px)',
        height: '100%', 
        // background: 'linear-gradient(0deg, black, #1D2F44)'
        }}>
        <Box sx={{display: 'flex', flexDirection: 'column', paddingBottom: '40px'}}>
          <Box sx={titleContainerStyles}>
            <Box sx={titleStyles}>
              Settings
            </Box>
          </Box>
          <Box sx={{display:'flex', marginLeft: '50px'}}>
            <Box sx={ (subSelect=="account") ? {...selectContainerStyles, ...selectSelectedContainerStyles} : {...selectContainerStyles}}>
              <Box sx={ (subSelect=="account") ? {...selectStyles, ...selectSelectedStyles} : {...selectStyles}} onClick={() => setSubSelect("account")}>
                <Box sx={imgContainerStyles}>
                  {(subSelect=="account") ?
                  <img style={{height:'100%'}} src={selectedUserIcon}/>
                  :
                  <img style={{height:'100%'}} src={userIcon}/>
                  }
                </Box>
                <Box sx={textStyles}>Account</Box>
              </Box>
            </Box><Box sx={ (subSelect=="keyboard") ? {...selectContainerStyles, ...selectSelectedContainerStyles} : {...selectContainerStyles}}>
              <Box sx={ (subSelect=="keyboard") ? {...selectStyles, ...selectSelectedStyles} : {...selectStyles}} onClick={() => setSubSelect("keyboard")}>
                <Box sx={imgContainerStyles}>
                  {(subSelect=="keyboard") ?
                  <img style={{height:'100%'}} src={selectedPianoIcon}/>
                  :
                  <img style={{height:'100%'}} src={pianoIcon}/>
                  }
                </Box>
                <Box sx={textStyles}>Keyboard</Box>
              </Box>

            </Box>
            {/* <Box sx={ (subSelect=="notifications") ? {...selectContainerStyles, ...selectSelectedContainerStyles} : {...selectContainerStyles}}>
              <Box sx={ (subSelect=="notifications") ? {...selectStyles, ...selectSelectedStyles} : {...selectStyles}} onClick={() => setSubSelect("notifications")}>
                <Box sx={imgContainerStyles}>
                  {(subSelect=="notifications") ?
                  <img style={{height:'100%'}} src={selectedNotificationIcon}/>
                  :
                  <img style={{height:'100%'}} src={notificationIcon}/>
                  }
                </Box>
                <Box sx={textStyles}>Notifications</Box>
              </Box>
            </Box> */}
            <Box sx={ (subSelect=="billing") ? {...selectContainerStyles, ...selectSelectedContainerStyles} : {...selectContainerStyles}}>
              <Box sx={ (subSelect=="billing") ? {...selectStyles, ...selectSelectedStyles} : {...selectStyles}} onClick={() => setSubSelect("billing")}>
                <Box sx={imgContainerStyles}>
                  {(subSelect=="billing") ?
                  <img style={{height:'100%'}} src={selectedCardIcon}/>
                  :
                  <img style={{height:'100%'}} src={cardIcon}/>
                  }
                </Box>
                <Box sx={textStyles}>Billing</Box>
              </Box>
            </Box>
          </Box>

          {(subSelect=="account") && 
            <Account/>
          }
          {(subSelect=="keyboard") && 
            <Keyboard/>
          }
          {(subSelect=="notifications") && 
            <Notifications/>
          }
          {(subSelect=="billing") && 
            <Billing/>
          }
        </Box>
      </Box>
    </Box>
  )
}
export default SettingsPage

const selectStyles = {
  color: 'white',
  margin: '5px 5px 5px 5px',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    background: "rgb(202,124,98,.25)",
    borderRadius: '5px',
    cursor: 'pointer',
    transition: '0.3s',
    boxShadow: '0px 0px 5px 5px rgb(202,124,98,.25)',
    },
}

const imgContainerStyles = {
  marginRight: '10px', 
  height: '18px'
}

const textStyles = {
  //fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '1px',
  textTransform: 'uppercase',
  color: '#FFFFFF',
}

const selectContainerStyles = {
  marginRight: '20px'
}
const selectSelectedContainerStyles = {
  borderBottom: '2px solid', 
  borderColor: '#469F6A'
}
const selectSelectedStyles = {
  borderBottomColor: '#469F6A',
  borderBottomWidth: 10,
  color: '#469F6A',
}

const containerStyles = {
  display: 'flex', 
  height: '100%', 
  width: '100%',
  background: 'linear-gradient(0deg, black, #1D2F44)'
}

const titleContainerStyles = {
  width: '100%', 
  height: '100px', display: 'flex', 
  flexDirection: 'row', 
  alignItems: 'center', 
  justifyContent: 'space-between',
  paddingBottom: '20px'
}
const titleStyles = {
  marginLeft: '50px',
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '48px',
  lineHeight: '58px',
  letterSpacing: '0.01em',
  color: '#FFFFFF',
}

import { Button } from '@mui/material';

const WelcomeButton = (props: any) => {
  return (
    // Note; if this is "sx" instead of style, it doesn't work
    <Button {...props} style={{...submitButtonStyles, ...props.style}} variant="contained">
      {props.children}
    </Button>
  )
}
export default WelcomeButton

const submitButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#050B13', 
  padding: '14px 28px',
  background: '#CA7C62', 
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px',

  fontFamily: 'Lato',
  fontStyle: 'normal',
  letterSpacing: '2.5px',
  fontWeight: '700',
  fontSize: '16px',
}

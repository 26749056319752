

const isSafariBrowser = () => {
  const userAgent = window.navigator.userAgent;
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
  return isSafari;
}

const isMobileBrowser = () => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Windows Phone|Opera Mini/i.test(userAgent);
};

const isCompatibleAndroid = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  return (/Android|android/i.test(userAgent) ||
    /Opera|opera/i.test(userAgent) || 
    /baidu|Baidu/i.test(userAgent) || 
    /qq|QQ|Qq/i.test(userAgent)) &&
    (! /Opera Mini/i.test(userAgent) ||
    ! /Firefox|firefox/i.test(userAgent))
};

export { isSafariBrowser, isMobileBrowser, isCompatibleAndroid }

import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MUSEFLOW_ORANGE } from 'Components/Colors';

const GoBackButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      disableRipple
      variant="text"
      startIcon={<ArrowBackIcon />}
      {...props}
      sx={Object.assign({
        color: MUSEFLOW_ORANGE,
        width: '138px', 
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer'
        }
      }, props.sx)}
    >
      {props.children}
    </Button>
  )
}

export default GoBackButton;
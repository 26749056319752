import { AwsRum, AwsRumConfig } from 'aws-rum-web';


export const getRum = () => {
  try {
    if(process.env.REACT_APP_NODE_ENV === 'local') {
      throw new Error('Do not load RUM in local env')
    }
    const cognitoGuestRoleArn = process.env.REACT_APP_COGNITO_GUEST_ROLE_ARN;
    const awsRumIdentityPoolId = process.env.REACT_APP_RUM_IDENTITY_POOL_ID;
    const awsRumSessionSampleRate = process.env.REACT_APP_RUM_SESSION_SAMPLE_RATE;
    let awsRumApplicationId = process.env.REACT_APP_RUM_APPLICATION_ID;
    let applicationVersion = process.env.REACT_APP_APPLICATION_VERSION;

    if(!awsRumSessionSampleRate) {
      console.warn("AWS RUM sample rate not set")
    }
    if(!awsRumApplicationId) {
      throw new Error("AWS RUM application ID not set")
    }
    if(!applicationVersion) {
      console.warn("AWS RUM application version not set")
      applicationVersion = '1.0.0'
    }
    const sessionSampleRate = awsRumSessionSampleRate ? parseInt(awsRumSessionSampleRate) : .1
    const config: AwsRumConfig = {
      allowCookies: true,
      guestRoleArn: cognitoGuestRoleArn,
      identityPoolId: awsRumIdentityPoolId,
      sessionSampleRate: sessionSampleRate,
      telemetries: [
        'errors', 
        [ "performance", {recordAllTypes: ['document', 'script', 'other']} ],
        [ 'http', {
          recordAllRequests: true,
          urlsToExclude: [
            new RegExp('/audio-samples/.+'),
            new RegExp('https://dataplane.rum.us-west-2.amazonaws.com/.+')
          ]
        } ]],
      eventPluginsToLoad: []
    };
    
    return new AwsRum(
      awsRumApplicationId as string, // we want this to fail if it isn't set
      applicationVersion,
      'us-west-2',
      config
    )
  } catch (error) {
    return undefined;
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}

const awsRum = getRum();

export { awsRum };
import { Box } from '@mui/material';    
import RoadmapMini1 from '../../assets/images/RoadmapMini1.png';
import RoadmapMini2 from '../../assets/images/RoadmapMini2.png';
import RoadmapMini3 from '../../assets/images/RoadmapMini3.png';
import '../../index.css'
import { 
  useState, 
  useRef,
  useEffect,
  useCallback,
 } from 'react'
type MinimapPropTypes = {
  unitPercentComplete: number;
  scrollAreaSize: number;
  scrollPosFromRoadmap: number;
  setScrollPosFromRoadmap: any;
  areaToPreventScroll: string;
  setAreaToPreventScroll: any;
  setPathScrollPos: any; //remove
}

export const Minimap = (props: MinimapPropTypes) => {
  const { 
    scrollPosFromRoadmap,
    setScrollPosFromRoadmap,
    scrollAreaSize,
    unitPercentComplete ,
    areaToPreventScroll, setAreaToPreventScroll,
    setPathScrollPos, //remove
  } = props 
  const [scrollPos, setScrollPos] = useState(0);
  const [startLeftPos, setStartLeftPos] = useState(0);
  const [startRightPos, setStartRightPos] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  // const movingBoxWidth = 100; // your movingBoxWidth
  // const containerWidth = window.innerWidth; // your containerWidth
  const scrollArea: any = useRef(null);
  const scrollBox = useRef<HTMLDivElement>(null);

  //This is a percentage of the screen that has been scrolled
  let containerWidth = 212
  let movingBoxWidth = scrollAreaSize*containerWidth

  useEffect(() => {
    const handleScroll = (e: any) => {
      setPathScrollPos(((e.target.scrollWidth-containerWidth)-e.target.scrollLeft)*21)
    };
    if (scrollBox.current) {
      scrollBox.current.addEventListener("scroll", handleScroll);
    }
    // Cleanup function
    return () => {
      if (scrollBox.current) {
        scrollBox.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [areaToPreventScroll]); 

  useEffect(() => {
    if (scrollBox.current) {
      scrollBox.current.scrollLeft = 1*(containerWidth-movingBoxWidth) - scrollPosFromRoadmap*(containerWidth-movingBoxWidth);
    }
  }, [scrollPosFromRoadmap])
  useEffect(() => {
    if (scrollBox.current) {
      const { left, right } = scrollBox.current.getBoundingClientRect();
      setStartLeftPos(left)
      setStartRightPos(right)
    }
  }, []);

  useEffect(() => {
    if (scrollBox.current) {
      const handleDragStart = (e: any) => e.preventDefault();
      scrollBox.current.addEventListener("dragstart", handleDragStart);
      return () => {
        if (scrollBox.current) {
          scrollBox.current.removeEventListener("dragstart", handleDragStart);
        }
      };
    }
  }, []);

  const handleMouseMove = (e: any) => {
    if (areaToPreventScroll!='path') {
      setAreaToPreventScroll('path')
    }
    if (isDragging && scrollBox.current){//} && scrollArea.current.contains(event.target)) {
      const dx = startX - e.clientX;
      scrollBox.current.scrollLeft += dx;
      setStartX(e.clientX); 
    }
  }
  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.clientX);
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };

  return (
    <Box sx={{
        width: `${containerWidth}px`,
        height: '70px',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        zIndex: 10,
    }}
    >

<Box
  sx={{position: 'relative', zIndex: 10,}}
>  
   <Box
      sx={{
        width: '100%',
        background: 'black',
        overflow: 'scroll', // Enable scrolling
        zIndex: 10,
      }}
      className="chatbox-scroll-container"
      ref={scrollBox}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <Box
        sx={{
          width: `${424-movingBoxWidth}px`,
          zIndex: 11,
        }}
      >
        <Box
          sx={{
            marginLeft: `${212-movingBoxWidth}px`,
            width: `${movingBoxWidth}px`, 
            height: '50px',
            boxSizing: 'border-box',
            border: '3px solid #469F6A',
            borderRadius: '2px',
            zIndex: 12,
          }}
        ></Box>
      </Box>
    </Box>

    <Box sx={{
      display: 'flex',
      margin: '5px 5px 5px 5px',
      cursor: 'pointer',
      position: 'absolute',
      left: '0px', top: '0px',
      zIndex: 0,
      pointerEvents: 'none',
    }}>
      <Box sx={{position: 'relative', cursor: 'pointer',}}>
      <img style={{width: '100%', cursor: 'pointer',}} src={RoadmapMini1}/>
      </Box><Box sx={{position: 'relative', cursor: 'pointer',}}>
      </Box><Box sx={{position: 'relative', cursor: 'pointer',}}>
      <img style={{width: '100%', cursor: 'pointer',}}src={RoadmapMini2}/>
      </Box><Box sx={{position: 'relative', cursor: 'pointer',}}>
      <img style={{width: '100%', cursor: 'pointer',}}src={RoadmapMini3}/>
      </Box>
    </Box>
      </Box>
      <Box sx={{
        marginTop: '10px',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        letterSpacing: '0.01em',
        color: '#469F6A',
        cursor: 'pointer',
      }}>
        Unit {Math.round(100*unitPercentComplete)}% Complete
      </Box>
    </Box>
  )
}


import { Fraction, NoteType } from 'opensheetmusicdisplay';
import { TickNoteEvent } from 'Utils/CustomEvents';
import { TICK_EVT_NAME } from 'Utils/Constants'; 
import { noteTypeToDivisionsPerMeasure } from 'Utils';
import  SHA256 from "crypto-js/sha256";
import ITimeKeeper from 'Models/ITimeKeeper';
import * as Tone from 'tone'
import TimeKeeper from 'Models/TimeKeeper';
import { find, repeat } from 'lodash';
import { v4 as uuid } from 'uuid';
import { scheduler } from 'timers/promises';

const LOWEST_START_MEASURE = -1

type Note = {
  time: number
}

export type IntervalFn = {
  name: string, // helpful for debugging
  fn: (evt: typeof TickNoteEvent)=>void,
  intervalNoteType: NoteType,
  numInterval: number,
  noteOffset: number,
  lastInvocationTick: number,
  id: string
}

export type TimeoutFunctionTick = {
  name: string, // helpful for debugging
  fn: () => void,
  count: number,
  countNoteType: NoteType,
  lastUpdateTick: number,
  ticksSinceScheduled?: number,
  id: string
}

export type PauseFn = (countInTimestamp: number) => void;
export type UnpauseFn = (countInTicks: number, playbackTicks: number, ticksPerMeasure: number) => void;


class Scheduler {

  intervalFns: IntervalFn[] = []
  timeoutFns: TimeoutFunctionTick[] = []
  pauseFns: PauseFn[] = []
  unpauseFns: UnpauseFn[] = []
  lastUnpauseMeasure: number = -1;
  lastPauseMeasure: number = -1;

  timeKeeper: ITimeKeeper;
  downBeatSample: Tone.Player = new Tone.Player(process.env.PUBLIC_URL + "/" + "Synth_Sine_C_hi.wav").toDestination()
  upBeatSample: Tone.Player = new Tone.Player(process.env.PUBLIC_URL + "/" + "Synth_Sine_C_lo.wav").toDestination()
  workerState: object = {}
  lastScheduledBeat = -10000
  handlingPauseOrUnpause = false
  audioInitialized = false
  id = uuid()

  repMetronomeSound = true
  repDownbeatsSound = true
  countInMeasures = LOWEST_START_MEASURE
  pickUpMeasureOffset = 0

  tickIntervalFn: NodeJS.Timer | undefined


  constructor(timeKeeper: ITimeKeeper, countInMeasures = LOWEST_START_MEASURE, pickUpMeasureOffset: number = 0) {
    this.timeKeeper = timeKeeper;
    // const pickUpMeasureLength = phrases[0].pickUpMeasureLength
    // const pickUpOffset = (phrases[0].musicXML.timeSignatures[0].numerator / phrases[0].musicXML.timeSignatures[0].denominator) - pickUpMeasureLength
    this.countInMeasures = countInMeasures
    this.lastPauseMeasure = this.countInMeasures
    this.pickUpMeasureOffset = pickUpMeasureOffset
  }

  printUuid = () => {
    console.log(this.id)
  }

  updateWorkerState = (newstate: object) => {
    Object.assign(this.workerState, newstate)
  }

  reset = () => {
    this.timeKeeper.resetTime()
    for (let pauseCb of this.pauseFns) {
      pauseCb(0)
    }
    this.intervalFns = []
    this.timeoutFns = []
    clearInterval(this.tickIntervalFn)
  }

  cleatPauseUnpauseCallbacks = () => {
    this.pauseFns = []
    this.unpauseFns = []
  }

  pauseTicks = (goBackOnPause: boolean = true) => {
    if (this.timeKeeper && !this?.timeKeeper.isPaused() && !this.handlingPauseOrUnpause) {
      this.handlingPauseOrUnpause = true
      this.timeKeeper.pause()
      const elapsedTicks = this.timeKeeper?.calculateElapsedTicks()
      const numberOfTicksPerMeasure = this.timeKeeper.getTicksPerMeasure()
      clearInterval(this.tickIntervalFn)
      let countInMeasures2;
      if(goBackOnPause) {
        // let countInMeasures2 = (Math.floor(elapsedTicks / numberOfTicksPerMeasure) - 1) 
        countInMeasures2 = (Math.floor(elapsedTicks / numberOfTicksPerMeasure) - 1)
        //  * (1 / this.timeKeeper.getTimeSigFract())

        if(countInMeasures2 < 0 ) {
          countInMeasures2 = this.countInMeasures
        } 
        if(countInMeasures2 === 0 && this.pickUpMeasureOffset > 0) {
          countInMeasures2 = (countInMeasures2 - this.pickUpMeasureOffset) * (1/this.timeKeeper.getTimeSigFract())
        }
        else {
          countInMeasures2 = (Math.floor(elapsedTicks / numberOfTicksPerMeasure)) - 1 - this.pickUpMeasureOffset
          * ( 1 / this.timeKeeper.getTimeSigFract())
        }
        if(countInMeasures2 < this.lastPauseMeasure){
          countInMeasures2 = this.lastPauseMeasure
        } 
        for (let pauseCb of this.pauseFns) {
          pauseCb( countInMeasures2 * this.timeKeeper.measureDurationAt(countInMeasures2))
        }
      } else {
        countInMeasures2 = Math.floor(elapsedTicks / numberOfTicksPerMeasure)
      }
      this.lastPauseMeasure = countInMeasures2;
      this.handlingPauseOrUnpause = false
    }
  }

  initialize_samples = async () => {
    if (!this.audioInitialized) {
      this.downBeatSample = new Tone.Player(process.env.PUBLIC_URL + "/" + "Synth_Sine_C_hi.wav").toDestination()
      this.upBeatSample = new Tone.Player(process.env.PUBLIC_URL + "/" + "Synth_Sine_C_lo.wav").toDestination()
      this.downBeatSample.volume.value = 8
      this.upBeatSample.volume.value = 8
      await Tone.loaded()
      await Tone.start()
      this.audioInitialized = true
    }
  }

  unpauseTicks = async (
    bpm: number, 
    countInMeasures: number, 
    startMeasure: number, 
    tickNoteType: NoteType) => 
  {
    if (!this.timeKeeper.isPaused() || this.handlingPauseOrUnpause) {
      return
    }
    this.handlingPauseOrUnpause = true
  
    // Load metronome audio samples
    await this.initialize_samples()
  
    const elapsedTicks = this.timeKeeper?.calculateElapsedTicks()
    const numberOfTicksPerMeasure = this.timeKeeper.getTicksPerMeasure()

    let lastCompleteMeasure = Math.floor(elapsedTicks / numberOfTicksPerMeasure)
    if (isNaN(lastCompleteMeasure) || lastCompleteMeasure < 0) {
      lastCompleteMeasure = this.countInMeasures
    } 
    this.timeKeeper.unpause(bpm, 0, this.lastPauseMeasure, tickNoteType)
  
    this.lastScheduledBeat = -10000
    this.scheduleMetronomeAudio()
  
    // Let any unpause callbacks run their logic before we kick the ticks loop and audio context back to life.
    for (let unpauseCb of this.unpauseFns) {
      unpauseCb(
        this.timeKeeper?.calculateElapsedTicks(), 
        this.timeKeeper?.getPlaybackStartTicks(), 
        this.timeKeeper?.getTicksPerMeasure()
      )
    }
  
    // Start (or restart) the tick interval loop.
    this.tickIntervalFn = this._startTickIntervalLoop();
    this.handlingPauseOrUnpause = false
  }

  clearIntervalFn = (name: string | null) => {
    this.intervalFns = this.intervalFns.filter(fn => fn.name !== name);
  }

  resetIntervalFns = () => {
    this.intervalFns = this.intervalFns.map(fn => {
      fn.lastInvocationTick = -Infinity
      return fn;
    })
  }

  resetTimeoutFns = () => {
    this.timeoutFns = this.timeoutFns.map(fn => {
      fn.ticksSinceScheduled = 0
      return fn;
    })
  }
  

  clearTimeoutFn = (id: string | null) => {
    this.timeoutFns = this.timeoutFns.filter(fn => fn.id !== id);
  }

  getWorkerState = () => this.workerState;

  clearWorkerState = () => {
    this.workerState = {};
  }

  getTimeKeeper = () => this.timeKeeper;

  _runIntervalFunctions = (event: typeof TickNoteEvent) => {
    const ticks = event.detail.ticks;
    const tickNoteType = event.detail.timeKeeper.getTickNoteType()
    for (let repeatingFn of this.intervalFns) {
      const numInterval = repeatingFn.numInterval;
      const intervalNotesPerMeasure = noteTypeToDivisionsPerMeasure(repeatingFn.intervalNoteType, this.timeKeeper.getTimeSignature())
      const numberOfTicksPerMeasure = noteTypeToDivisionsPerMeasure(tickNoteType, this.timeKeeper.getTimeSignature());
      const ticksPerIntervalNote =  numberOfTicksPerMeasure / intervalNotesPerMeasure
      const tickOffset =  (repeatingFn.noteOffset * ticksPerIntervalNote)
      const tickInterval = (this.timeKeeper.getTicksPerMeasure() / intervalNotesPerMeasure) * numInterval

      if ((ticks - tickOffset) % tickInterval === 0 && tickInterval >= (ticks - Math.abs(repeatingFn.lastInvocationTick))) {
        repeatingFn.lastInvocationTick = ticks;
        repeatingFn.fn(event);
      }
    }
  }

  _runTimeoutFunctions = (event: typeof TickNoteEvent) => {
    const ticks = event.detail.ticks;

    const tickNoteType = event.detail.timeKeeper.getTickNoteType()
    let newTimeoutFns: TimeoutFunctionTick[] = []
    this.timeoutFns.forEach((toRunFn) => {
      const ticksSinceStart = toRunFn.ticksSinceScheduled || 0;
      const countNoteType = toRunFn.countNoteType;
      const count = toRunFn.count;
      const numberOfTicksPerMeasure = noteTypeToDivisionsPerMeasure(tickNoteType, this.timeKeeper.getTimeSignature());
      const numberOfCountNotesPerMeasure =  noteTypeToDivisionsPerMeasure(countNoteType, this.timeKeeper.getTimeSignature())
      



      const ticksPerCountNote =  numberOfTicksPerMeasure * (1 / numberOfCountNotesPerMeasure )
      // don't run if ticks is less than last update ticks because it means we've gone back to previously played state
      if(ticks > toRunFn.lastUpdateTick && toRunFn.ticksSinceScheduled && toRunFn.ticksSinceScheduled >= count * ticksPerCountNote) {
        toRunFn.fn()
      }
      else {
        // don't update if ticks is less than last update ticks because it means we've gone back to previously played notes
        if(ticks > toRunFn.lastUpdateTick) {
          toRunFn.lastUpdateTick = ticks
          toRunFn.ticksSinceScheduled = ticksSinceStart + 1;
        }
        newTimeoutFns.push(toRunFn)
      }
    });
    this.timeoutFns = newTimeoutFns;
  }

  hasTimeout = (name: string): boolean  => {
    return !!find(this.timeoutFns, fn => fn.name === name);
  }

  hasInterval = (name: string): boolean  => {
    return !!find(this.intervalFns, fn => fn.name === name);
  }

  printTimeouts = () => {
    console.log(this.timeoutFns.map(fn => fn.name))
  }

  printIntervals = () => {
    console.log(this.intervalFns.map(fn => fn.name))
  }

  setIntervalByTick = (
    name: string,
    fn: (event: typeof TickNoteEvent)=>void,
    intervalNoteType: NoteType,
    numInterval: number,
    noteOffset: number = 0
  ): string => {
    let intervalFn = {
      name, 
      fn,
      intervalNoteType,
      numInterval,
      noteOffset,
      lastInvocationTick: -Infinity,
      id: SHA256(name + Date.now()).toString()
    };
    this.intervalFns.push(intervalFn);
    return intervalFn.id
  }  

  setTimeoutByTick = (name: string, fn:()=>void, countNoteType: NoteType, count: number): string => {
    // It's possible there could be a race condition where the loop in _runScheduledFunctions starts
    // and the this function is lost because it wasn't added to the array before the loop stated and 
    // therefore it wasn't added to "newToRunFns". But I'm not sure if that's possible without threading.
    let timeoutFn = {
      name,
      fn,
      count,
      countNoteType,
      lastUpdateTick: 0,
      ticksSinceScheduled: 0,
      id: SHA256(name + Date.now()).toString()
    };
    this.timeoutFns.push(timeoutFn);
    return timeoutFn.id;
  }

  addPauseCallback = (cb: PauseFn) => {
    this.pauseFns.push(cb)
  }

  addUnpauseCallback = (cb: UnpauseFn) => {
    this.unpauseFns.push(cb)
  }

  changeDownbeatsAudio = async (setDownbeats: boolean) => {
    this.repDownbeatsSound = setDownbeats
  }

  changeMetronomeAudio = async (setMetronome: boolean) => {
    this.repMetronomeSound = setMetronome
  }

  scheduleMetronomeAudio = () => {
    const startTime = this.timeKeeper.getPlaybackStartTime()
    const timeSignatureNum = this.timeKeeper.getTimeSignature()?.numerator
    const timeElapsed = this.timeKeeper.getAudioContextTime() - startTime
    const secondsPerBeat = this.timeKeeper.getMeasureDurationSecs() / timeSignatureNum
  
    // TODO this will need extra logic for mixed meter!
    // Calculate how many beats have elapsed now, and shortly into the future.
    const beatsElapsed = Math.floor(timeElapsed / secondsPerBeat)
    const beatsElapsedSoon = Math.floor((timeElapsed + TimeKeeper.METRONOME_SCHEDULE_AHEAD_TIME) / secondsPerBeat)
  
    // If the integer portion of beatsElapsedSoon is greater than the integer portion of beatsElapsed,
    // we should schedule new beat audio.
    if (beatsElapsedSoon != this.lastScheduledBeat && beatsElapsed < beatsElapsedSoon) {
      this.lastScheduledBeat = beatsElapsedSoon
  
      const scheduleTime = startTime + beatsElapsedSoon * secondsPerBeat
  
      // Is this a downbeat or upbeat?
      // (The down beat is the first beat of the measure)
      if (beatsElapsedSoon % timeSignatureNum == 0 && this.repDownbeatsSound){
        if (this.repMetronomeSound) {
          this.downBeatSample.start(scheduleTime)
        }
      } else {
        if (this.repMetronomeSound) {
          this.upBeatSample.start(scheduleTime)
        }
      }
    }
  }

  _runTickEventListener = () => {
    // eslint-disable-next-line no-restricted-globals
    self.addEventListener(TICK_EVT_NAME, ((event: typeof TickNoteEvent) => {
      this._runIntervalFunctions(event);
      this._runTimeoutFunctions(event);
    }) as EventListener);
  }

  _runMetronomeLoop = () => {
    this.scheduleMetronomeAudio()
  }

  _startTickIntervalLoop = (): NodeJS.Timer => {
    let elapsedTicks = this.timeKeeper?.calculateElapsedTicks()
    let tickIntervalFn = setInterval(() => {
      this._runMetronomeLoop()
  
      // Check if elapsed ticks have changed since the last time this loop ran.
      const lastElapsedTicks = elapsedTicks
      elapsedTicks = this.timeKeeper?.calculateElapsedTicks()
      // Run tick interval listeners if ticks have elapsed.
      if (elapsedTicks > lastElapsedTicks) {
        // Make sure we run tick events for each tick that's elapsed.
        for (let i = lastElapsedTicks + 1; i <= elapsedTicks; i++) {
          // eslint-disable-next-line no-restricted-globals
          self.dispatchEvent(new CustomEvent(TICK_EVT_NAME, {
            detail: {
              name: TICK_EVT_NAME,
              ticks: i,
              timeKeeper: this.timeKeeper,
              workerState: this.workerState,
            }
          }))
        }
      }
    }, 25)
    return tickIntervalFn;
  };

  init =  ( _workerState = {}) => {
    this.updateWorkerState(_workerState)
    Tone.setContext(this.timeKeeper.getAudioContext())
  
    this._runTickEventListener();
  }

  setTimeKeeper = (_timeKeeper: ITimeKeeper) => {
    this.timeKeeper = _timeKeeper
  }

}

export default Scheduler;
import React, { useEffect } from "react";
import { Typography, Box, Select, MenuItem } from "@mui/material";
import { SignUpData } from "Types";
import { CustomTextField, CustomButton, CustomCheckbox } from "Components/StyledComponents"
import { FormikProps } from "formik";
import { find } from 'lodash';
import * as yup from "yup";
import { MUSEFLOW_OFF_WHITE_1 } from "Components/Colors";
import CountryCodeSelect from './CountryCodeSelect';

export type SignUpFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FormikProps<SignUpData>;

export const isPhoneNumber = (value:string|undefined) => {
  const codeSchema = yup.string().matches(
    /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Invalid phone number'
  );
  return codeSchema.isValidSync(value || "");
}

export const isPhoneNumberWithCountryCode = (value:string|undefined) => {
  const codeSchema = yup.string().matches(
    /^(\+\d{1,3}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    'Invalid phone number'
  );
  return codeSchema.isValidSync(value || "");
}

export const isEmail = (value:String|undefined) => {
  const emailSchema = yup.string().email()
  return emailSchema.isValidSync(value || "");
}

export const validateEmailOrPhone = (emailOrPhone:string | undefined) => {
  return isEmail(emailOrPhone) || isPhoneNumber(emailOrPhone)
}

export const signUpFormSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is a required field"),
  emailOrPhone: yup
    .string()
    .test("emailOrPhoneNumber", "Must be an email or a phone number",validateEmailOrPhone)
    .required("Email or code is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be at least 8 characters"),
  countryacronym: yup.string()
});

const SignUpForm: React.FC<SignUpFormProps> = (
  props: SignUpFormProps
) => {
  const { handleChange, values, errors } = props;

  const [isPhoneNumber, setIsPhoneNumber] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState<"password"|"text">("password") 
  
  useEffect(()=>{
    if(isPhoneNumberWithCountryCode(values.emailOrPhone)) {
      setIsPhoneNumber(true)
    } else {
      setIsPhoneNumber(false)
    }
  },[values.emailOrPhone])

  return (
    <Box className="signup-form-container">

      <CustomTextField
        id="name"
        name="name"
        type="text"
        onChange={handleChange}
        label="Full Name"
        value={values.name || ""}
        error={!!errors.name}
        helperText={errors.name}
        fullWidth
      />

      <Box
        sx={{
          minWidth: 310,
          display: 'flex',
          flexDirection: 'row'
        }}
      >
      { isPhoneNumber &&
          <CountryCodeSelect 
            handleChange={handleChange}
            countryAcronym={values.countryAcronym}
          />
        }
        <CustomTextField
          onChange={handleChange}
          id="emailOrPhone"
          name="emailOrPhone"
          type="text"
          label="Email or Phone Number"
          value={values.emailOrPhone || ""}
          error={!!errors.emailOrPhone}
          helperText={errors.emailOrPhone}
          fullWidth
        />

      </Box>
      
      <CustomTextField
        hidden={false}
        onChange={handleChange}
        id="password"
        name="password"
        type={passwordType}
        label="Password"
        value={values.password || ""}
        error={!!errors.password}
        helperText={errors.password}
        fullWidth
      />

      <CustomCheckbox 
        id="showPassword"
        name="showPassword"
        label="Show password"
        value={false}
        checked={false}
        defaultChecked={false}
        sx={{
          margin: 0
        }}
        onChange={()=>{
          if(passwordType === 'password') {
            setPasswordType('text')
          } else if(passwordType === 'text') {
            setPasswordType('password')
          }
        }}
      />

      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <CustomButton
          variant="contained"
          type="submit"
        >
          CREATE ACCOUNT
        </CustomButton> 
      </Box>
      <Box>
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
      </Box>
    </Box>
  );
};


const textStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.16px',
}

export default SignUpForm;
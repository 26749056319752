import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { CustomCircularProgress } from 'Components/StyledComponents';
import PaymentFlow from './Payment/PaymentFlow'
import { useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';

type ProtectedLayoutProps = {
  children: React.ReactNode;
};
const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ children }) => {
  return (
    <>
      <PaymentFlow />
      {children}
    </>
  );
};

type ProtectedRouteProps = {
  redirectPath: string,
} & React.PropsWithChildren

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({children, redirectPath}) => {
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  if (!auth.authPending && !auth.jwtToken) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return ( 
    <>
      {auth.authPending ? (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <CustomCircularProgress/>
        </Box>
      ) : (
        <ProtectedLayout>{children}</ProtectedLayout>
      )}
    </>
  )
};

export default ProtectedRoute;

import { TempoProps } from '../Types';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
 
function Tempo(props: TempoProps): JSX.Element {
    const { tempo, tempoChange } = props
    return (
        <div style={{
            width: 140,
            height: 85,
            //display: 'flex',
            //flexDirection: 'column',
            //alignItems: 'flex-start',
            padding: '12px 18px',
            gap: 10,
            backgroundColor: 'black',
            borderRadius: '35px',
            color: 'white'
    
        }}>
            <div style={{textAlign: 'center', paddingTop: 5}}>
                Tempo
                <span style={{backgroundColor: tempoChange >= 0 ? 'green' : 'red', borderRadius: '20px', marginLeft: 8, padding: 5}}>
                    { tempoChange >= 0 ? '+' : '-'}
                    {Math.abs(tempoChange)} bpm
                </span>
            </div>
    
            <div style={{textAlign: 'center', display: 'inline-flex', position: 'relative'}}>
                
                <div style={{fontSize: 40, paddingLeft: 40, paddingTop: 10, position: 'relative'}}>{tempo}
                <div>
                    <ArrowDropUpIcon style={{position: 'absolute', width: 40, height: 40, bottom: 10, left: 7}}/>
                    <ArrowDropDownIcon style={{position: 'absolute', width: 40, height: 40, bottom: -5, left: 7 }}/>

                </div>
                <div style={{fontSize: 22, position: 'absolute', bottom: 5, right: -46}}>bpm</div>
                </div>
                
            </div>
    
        </div>
    )
}
export default Tempo;
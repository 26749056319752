import * as fullNameParser from 'parse-full-name';
import { put, takeEvery, select } from 'redux-saga/effects'
import * as appActions from 'Actions/app'
import * as authActions from 'Actions/auth';
import { actionTypes, AppState, TutorialData, UserLevelData, LevelData, UnitData, AppActions, TiersByLevels, SetUnitSelectPayload, SetAudioOn, AugmentedCognitoUser, SetRepMetronomeSound, SetRepDownbeatsSound } from 'Types';
import { EventState } from 'Types/EventTypes'
import { AxiosError, AxiosResponse } from 'axios';
import { getHighestCompletedLevel } from 'Utils/UserProgress'
import StartingULP from 'Fixtures/UserLevelProgress/StartingULP';
import { UpdateCurrentULP } from 'Types';
// import AxiosRetrier from 'Utils/AxiosRetrier';
import { UserTypes } from 'Utils/Constants';
import {getRum} from 'Utils/AwsRum';
import axios from 'axios';
import { AuthState, SIGN_IN_COMPONENT } from 'Types/AuthTypes';
import { Auth } from 'aws-amplify';
import { getCurrentAuthToken, getCurrentUser } from 'Utils/Amplify';
import * as repertoireActions from '../Actions/repertoire'
import * as repertoireTypes from '../Types/RepertoireTypes'
import { UserRepertoireData, RepertoireData, UserRepertoireProgress } from 'Types/RepertoireTypes';
import { isConstructorDeclaration } from 'typescript';

const awsRum = getRum()
// const axios = new AxiosRetrier()
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1/`
interface unitsRequest {
  data: {
    data: UnitData[]
  }
}
interface levelsRequest {
  data: {
    data: LevelData[]
  }
}
interface userLevelProgressResponse {
  data: UserLevelData[]
}

interface tutorialsRequest {
  data: {
    data: TutorialData[]
  }
}

interface tiersByLevelsRequest {
  // data: TiersByLevels[]    <- Should be this, but server passes level_number as string
  data: {
    level_number: string;
    tiers: number[];
  }[]
}

interface userDataRequest {
  data: any
}


interface repertoiresRequest {
  data: {
    data: RepertoireData[],
    hasNextPage: boolean
  }
}

interface userDataRequest {
  data: any
}


interface userRepertoireProgressResponse {
  data: UserRepertoireProgress[]
}

interface singleUserRepertoireProgressResponse {
  data: UserRepertoireProgress
}



function* onStartup(): any {
  // yield console.log('Startup!!!!');
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  try {
    const jwtToken = yield getCurrentAuthToken()
    if(jwtToken) {
      yield put(
        appActions.setUnitSelect({unitSelect:1}) // this shouldn't be necessary - why is default state not set on load?
      )
    }
    yield put(
      authActions.setSignInComponent(SIGN_IN_COMPONENT.SIGNIN)
    )
  } catch {
    yield put(authActions.setAuthPending(false))

  }

}

function* updateLevelsData(): any {
  const data:AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  const jwtToken = yield getCurrentAuthToken()
  // Now that we have the highest level the user has completed, we will query all levels for corresponding unit
  // And lower. (Onces we have multiple units, new users won't have their roadmap populated with these levels yet)
  // Now using the highest unit, grab all the levels up to and including that unit to populate the roadmap!
  let levels: LevelData[] = []
  let LevelsResponse:levelsRequest
  LevelsResponse = yield axios.get<any[]>(baseUrl+`levels?unitNumber=${data.unitSelect}&ascending=true`, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  levels = levels.concat(LevelsResponse.data.data)
  
  yield put(
    appActions.fetchLevelDataSuccess({
      levelData: levels
    })
  )

  let TierCountByLevelResponse:tiersByLevelsRequest
  TierCountByLevelResponse = yield axios.get<any[]>(baseUrl+`phrases/tiers`, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  // Passed from server with level_number as sring; so casting to into to match TiersByLevels type and easier use
  let TiersByLevels: TiersByLevels[] = TierCountByLevelResponse.data.map(v => ({...v, level_number: parseInt(v.level_number)}));
  yield put(
    appActions.updateTiersByLevels(
      TiersByLevels
    )
  )
}

function* onUnitSelect() {
    yield updateLevelsData()
}

function* onAuthStartup(action: any): any {
  const data:AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  const user = yield getCurrentUser();
  let jwtToken;
  try {
    jwtToken = yield getCurrentAuthToken()
  } catch{}
  if(jwtToken) {
      // First check UserLevelProgress, pull all rows for user
      let ulp_url = baseUrl+`user-level-progress`
      let userLevelProgressResponse:userLevelProgressResponse
      userLevelProgressResponse = yield axios.get<any[]>(ulp_url, {headers: {'Authorization': `Bearer ${jwtToken}`}})
      let userLevelProgress: UserLevelData[] = userLevelProgressResponse.data
      // If user does not have a userLeverProgress, create a row for user
      if (Object.keys(userLevelProgress).length == 0) {
        console.log("posting starting ulp")
        const startingULP = (StartingULP() as UserLevelData)
        try {
          userLevelProgressResponse = yield axios.post<UserLevelData>(
            ulp_url, 
            startingULP,
            {headers: {'Authorization': `Bearer ${jwtToken}`}},
          )
          userLevelProgress = [(userLevelProgressResponse.data as unknown as UserLevelData)]
        } catch(err ) {
          awsRum?.recordError(err)
          // sometimes this can happen due to a race condition, so this is just a bit of extra error handling
          if( err instanceof AxiosError && err?.response?.data?.message === "User level progress already exists for user/level") {
            userLevelProgressResponse = yield axios.get<UserLevelData>(
              ulp_url, 
              {headers: {'Authorization': `Bearer ${jwtToken}`}},
            )
            console.log(userLevelProgressResponse)
            userLevelProgress = userLevelProgressResponse.data
            yield put(
              appActions.fetchUserLevelsSuccess({
                userLevelData: userLevelProgress
              })
            )
          }
        }
      } else {
        userLevelProgress = userLevelProgressResponse.data
      }
      //Now set user Level data
      yield put(
        appActions.fetchUserLevelsSuccess({
          userLevelData: userLevelProgress
        })
      )
      
      // Then calculate the lastestLevelComplete for the user.
      const highestCompleteData = getHighestCompletedLevel(userLevelProgress)
      let lastLevelComplete = highestCompleteData.lastLevelComplete
      
      // Now set highestLevelCompleted
      yield put(
        appActions.setHighestLevelCompleted({
          highestLevelCompleted: lastLevelComplete
        })
      )

      // Now that we have the highest level the user has completed, we will query all levels for corresponding unit
      // And lower. (Onces we have multiple units, new users won't have their roadmap populated with these levels yet)
      let UnitsResponse:unitsRequest
      // UnitsResponse = yield axios.get<any[]>(baseUrl+`units?levelNumber=${lastLevelComplete || 1}`, {headers: {'Authorization': `Bearer ${authToken}`}})
      
      // Now using the highest unit, grab all the levels up to and including that unit to populate the roadmap!
      let levels: LevelData[] = []
      let LevelsResponse:levelsRequest
      LevelsResponse = yield axios.get<any[]>(baseUrl+`levels?unitNumber=${data.unitSelect}&ascending=true`, {headers: {'Authorization': `Bearer ${jwtToken}`}})
      levels = levels.concat(LevelsResponse.data.data)
      
      yield put(
        appActions.fetchLevelDataSuccess({
          levelData: levels
        })
      )

      let TierCountByLevelResponse:tiersByLevelsRequest
      TierCountByLevelResponse = yield axios.get<any[]>(baseUrl+`phrases/tiers`, {headers: {'Authorization': `Bearer ${jwtToken}`}})
      // Passed from server with level_number as sring; so casting to into to match TiersByLevels type and easier use
      let TiersByLevels: TiersByLevels[] = TierCountByLevelResponse.data.map(v => ({...v, level_number: parseInt(v.level_number)}));
      yield put(
        appActions.updateTiersByLevels(
          TiersByLevels
        )
      )
      const highestCompleteLevel = highestCompleteData.lastLevelComplete
      yield getRepertoireData(highestCompleteLevel)

      // Get user Data:
      
      let user_data_url = baseUrl+`user-data`
      let userDataResponse:userDataRequest
      try {
        userDataResponse = yield axios.get<any[]>(user_data_url, {headers: {'Authorization': `Bearer ${jwtToken}`}})
        yield put(
          appActions.setUserData(
            userDataResponse.data
          )
        )
        yield updateLevelsData()
      } catch(err: any) {
        console.error(err)
        awsRum?.recordError(err)
        if(user) {
          userDataResponse = yield axios.post<any[]>(
            user_data_url,
            {
              subscription_status: "",  // Could be a new state (not yet active) or null. But something to differentiate from the stripe "upaid" "canceled" states
              user_type: UserTypes.USER.toString(),
              name: user?.attributes?.name, 
              email: user?.attributes.email,
              phone_number: user?.attributes?.phone_number
            },
            // {headers: {'Authorization': `Bearer ${jwtToken}`, 'id_token': `${idToken}`}}
            {headers: {'Authorization': `Bearer ${jwtToken}`}}
  
          )
  
          yield put(
            appActions.setUserData(
              userDataResponse.data
            )
          )
          
          yield updateLevelsData()          
        }

      }
  }
  
}

function* onTutorialDataRequest(action: any): any {
  const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)

  const data:AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  yield put(
    appActions.fetchTutorialDataProcessing(true)
  )  
  const levelNumber = data.levelData[data.levelSelect].level_number
  let tutorialsResponse: tutorialsRequest
  tutorialsResponse = yield axios.get<any[]>(baseUrl+`tutorials?levelNumber=${levelNumber}`, {headers: {'Authorization': `Bearer ${jwtToken}`}})

  let tutorialData = tutorialsResponse.data.data
  yield put(
    appActions.fetchTutorialDataSuccess({
      tutorialData: tutorialData
    })
  )
  yield put(
    appActions.fetchTutorialDataProcessing(false)
  ) 
  // let path = "/tutorial"
  // window.location.href = path // <- This is not idea solution, the push(path) should work, but doesn't
  // yield put(push(path))
}


function* onCurrentULPUpdate(action: UpdateCurrentULP): any {
  const data: AppState = yield select(({ mainAppReducer }) => mainAppReducer)
  const events: EventState = yield select(({ eventReducer }) => eventReducer)
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  const jwtToken = yield getCurrentAuthToken()
  const level = data.levelData[data.levelSelect].level_number // This will determine which tutorial we grab!
  const toUpdateULP = action.payload.currentUserLevelProgress;
  let updatedULP: AxiosResponse<UserLevelData>;
  if(!toUpdateULP) {
    try {
      updatedULP = yield axios.post<any>(
        baseUrl+`user-level-progress?levelNumber=${level}`,
        {
          ...StartingULP(),
          level: {
            level_number: level
          },
          play_session_id: action.payload.currentUserLevelProgress?.play_session_id || events.playSessionId
        },
        {
          headers: {'Authorization': `Bearer ${jwtToken}`}
        }
      )
      yield put(
        appActions.updateCurrentULPSuccess({...updatedULP.data, play_session_id: action.payload.currentUserLevelProgress?.play_session_id || events.playSessionId})
      )
    } catch(err) {
      if( err instanceof AxiosError) {
        awsRum?.recordError(err);
        console.error(err)
      }
    }
    return
  } 

  if(toUpdateULP && Object.keys(toUpdateULP).contains('user_id')) {
    delete toUpdateULP.user_id
  }
  try {
    updatedULP = yield axios.patch<any>(
        baseUrl+`user-level-progress?levelNumber=${level}`,
        {
          level: {
            level_number: level
          },
          ...toUpdateULP,
          play_session_id: action.payload.currentUserLevelProgress?.play_session_id || events.playSessionId
        },
        {
          headers: {'Authorization': `Bearer ${jwtToken}`}
        }
      )
    yield put(
      appActions.updateCurrentULPSuccess({...updatedULP.data, play_session_id: action.payload.currentUserLevelProgress?.play_session_id || events.playSessionId})
    )    
  } catch(err) {
    if( err instanceof AxiosError) {
      if(err?.response?.data?.message === "User level progress not found.") {
        updatedULP = yield axios.post<any>(
          baseUrl+`user-level-progress?levelNumber=${level}`,
          {
            ...StartingULP(),
            level: {
              level_number: level
            },
            play_session_id: action.payload.currentUserLevelProgress?.play_session_id || events.playSessionId
          },
          {
            headers: {'Authorization': `Bearer ${jwtToken}`}
          }
        )
        // TODO: add play session id to current ulp in backend
        yield put(
          appActions.updateCurrentULPSuccess({...updatedULP.data, play_session_id: action.payload.currentUserLevelProgress?.play_session_id || events.playSessionId})
        )
      }
    } else {
      awsRum?.recordError(err);
      console.error(err)
    }
  }
}

function* onAudioUpdate(action: SetAudioOn): any {
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  try {
    const jwtToken = yield getCurrentAuthToken()
    const audioOn = action.payload.audioOn
    yield axios.put<any>(
      baseUrl+`user-data/set-user-audio?audio_on=${audioOn}`,
      {},
      {
        headers: {'Authorization': `Bearer ${jwtToken}`}
      }
    )
    yield put(
      appActions.updateUserData({audio_on: audioOn})
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
}

function* onsetLastShownSurvey(action: any): any {
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  try {
    const jwtToken = yield getCurrentAuthToken()
    const lastShownSurvey = action.payload.last_shown_survey.toISOString()
    yield axios.put<any>(
      baseUrl+`user-data/set-last-shown-survey?last_shown_survey=${lastShownSurvey}`,
      {},
      {
        headers: {'Authorization': `Bearer ${jwtToken}`}
      }
    )
    yield put(
      appActions.updateUserData({last_shown_survey: lastShownSurvey})
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
}

function* onUpdateMailchimpInfo(action: any): any {
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  try {
    const jwtToken = yield getCurrentAuthToken()

    const fullName = fullNameParser.parseFullName(action.payload.signUpData.name)
    const firstName = fullName.first ? fullName.first : fullName.last
    const lastName = fullName.first && fullName.last ? fullName.last : undefined
    yield axios.post<any>(
      baseUrl+`mailchimp/add-to-list`,
      {
        first_name: firstName,
        last_name: lastName,
        email: action.payload.signUpData.email
      },
      {
        headers: {'Authorization': `Bearer ${jwtToken}`}
      }
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
}

function* onGetSubscriptionStatus(action: any): any {
  // const { jwtToken }: AuthState = yield select(({authReducer}) => authReducer)
  const jwtToken = yield getCurrentAuthToken()
  try {
    const userDataResponse = yield axios.get<any[]>(
      baseUrl+`user-data`, 
      {
        headers: {'Authorization': `Bearer ${jwtToken}`}
      }
    )
    yield put(
      appActions.setSubscriptionStatus(userDataResponse.data.subscription_status)
    )
  } catch(err) {
    awsRum?.recordError(err);
    console.error(err)
  }
  
}

function* getRepertoireData(highestCompleteLevel: number): any {
  // Get User-Repertoire-Progress (URP):
  let urp_url = baseUrl+`user-repertoire-progress`
  let userRepertoireProgressResponse:userRepertoireProgressResponse
  const jwtToken = yield getCurrentAuthToken()
  userRepertoireProgressResponse = yield axios.get<any[]>(urp_url, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  if (Object.keys(userRepertoireProgressResponse.data).length) {
    yield put(
      repertoireActions.fetchURPSuccess(userRepertoireProgressResponse.data)
    )
  }

  // Get Repertoire by Highest Level Complete
  if (highestCompleteLevel > 0) {

  }

  // Get ALL repertoires:
  let repertoires: RepertoireData[] = []
  let RepertoiresResponse:repertoiresRequest

  let page = 1
  let hasNextPage = true
  while (hasNextPage) {
    RepertoiresResponse = yield axios.get<any[]>(baseUrl+`repertoires`, {headers: {'Authorization': `Bearer ${jwtToken}`}, params: { page }})
    repertoires = repertoires.concat(RepertoiresResponse.data.data)
    hasNextPage = RepertoiresResponse.data.hasNextPage;
    if (hasNextPage) {
      page += 1;
    }
  }

  // Instead of sorting here, could sort relations by asc backen..
  repertoires.forEach((rep) => {
    rep.composers.sort((a, b) => a.id - b.id)
    rep.genres.sort((a, b) => a.id - b.id)
  })
  yield put(
    repertoireActions.fetchRepertoireDataSuccess(repertoires)
  )

}

function* onUserRepertoireProgressUpdate(action: any): any {
  const jwtToken = yield getCurrentAuthToken()
  let urp_url = baseUrl+`user-repertoire-progress`
  let userRepertoireProgressResponse:singleUserRepertoireProgressResponse
  userRepertoireProgressResponse = yield axios.put<any[]>(urp_url, action.payload, {headers: {'Authorization': `Bearer ${jwtToken}`}})
  yield put(
    repertoireActions.fetchURPSuccess([userRepertoireProgressResponse.data])
  )
}



function* onRepMetronomeSoundUpdate(action: SetRepMetronomeSound): any {
  const jwtToken = yield getCurrentAuthToken()
  const repMetronomeSound = action.payload.repMetronomeSound
  yield axios.put<any>(
    baseUrl+`user-data/set-rep-metronome-sound?rep_metronome_sound=${repMetronomeSound}`,
    {},
    {
      headers: {'Authorization': `Bearer ${jwtToken}`}
    }
  )
  yield put(
    appActions.updateUserData({rep_metronome_sound: repMetronomeSound})
  )
}

function* onRepDownbeatsSoundUpdate(action: SetRepDownbeatsSound): any {
  const jwtToken = yield getCurrentAuthToken()

  const repDownbeatsSound = action.payload.repDownbeatsSound
  yield axios.put<any>(
    baseUrl+`user-data/set-rep-downbeats-sound?rep_downbeats_sound=${repDownbeatsSound}`,
    {},
    {
      headers: {'Authorization': `Bearer ${jwtToken}`}
    }
  )
  yield put(
    appActions.updateUserData({rep_downbeats_sound: repDownbeatsSound})
  )
}


export default [
  takeEvery(actionTypes.UPDATE_CURRENT_ULP, onCurrentULPUpdate),
  takeEvery(repertoireTypes.actionTypes.UPDATE_REPERTOIRE_DATA_REQUEST, onUserRepertoireProgressUpdate),
  takeEvery(actionTypes.FETCH_TUTORIAL_DATA, onTutorialDataRequest),
  takeEvery(actionTypes.AUTHENTICATED_STARTUP, onAuthStartup),
  takeEvery(actionTypes.SET_UNIT_SELECT, onUnitSelect),
  takeEvery(actionTypes.SET_AUDIO_ON, onAudioUpdate),
  takeEvery(actionTypes.UPDATE_MAILCHIMP_INFO, onUpdateMailchimpInfo),
  takeEvery(actionTypes.SET_LAST_SHOWN_SURVEY, onsetLastShownSurvey),
  takeEvery(actionTypes.GET_SUBSCRIPTION_STATUS, onGetSubscriptionStatus),
  takeEvery(actionTypes.SET_REP_METRONOME_SOUND, onRepMetronomeSoundUpdate),
  takeEvery(actionTypes.SET_REP_DOWNBEATS_SOUND, onRepDownbeatsSoundUpdate),
  onStartup()
]

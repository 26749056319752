import { Box } from '@mui/material';
import pianoIcon from '../../assets/images/pianoIcon.png';
import soundIcon from '../../assets/images/soundIcon.png';
import { useDispatch, useSelector } from 'react-redux'
import { MainAppReducer } from 'Types';
import * as appActions from '../../Actions/app'
import { AuthReducer } from 'Types/AuthTypes';

const Keyboard = () => {

  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const auth = useSelector((state: AuthReducer) => state.authReducer)


  const {
    userData,
  } = data

  const dispatch = useDispatch()
  
  return (
    <Box sx={{height: 'calc(100vh - 200px)', overflow: 'scroll'}}>
    <Box sx={{
      marginLeft: '50px',
      width: '600px',
      backgroundColor: 'black',
      borderRadius: '5px',
      }}>
      <Box sx={{
        color: 'white',
        textAlign: 'left',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          marginTop: '20px', 
          marginBottom: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '26px',
          letterSpacing: '0.01em',
          color: '#FFFFFF',
          fontFamily: 'Lato'
          }}>
        Keyboard Settings
        </Box>
        {/* <Box sx={{marginBottom: '20px', display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display:'flex', alignItems:'center', height: '25px'}}><img style={{height:"100%", marginRight: '10px'}} src={pianoIcon}/> Keyboard Range:</Box>
          <Box sx={{marginTop: '10px', marginLeft: '35px', display: 'flex'}}>
            <Box sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              color: '#ECECEC',
            }}>88 keys</Box>
            <Box sx={{
              marginLeft: '7px',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              color: '#767C8C',
            }}>A0-C8</Box>
            <Box sx={{
              marginLeft: '7px',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              textDecorationLine: 'underline',
              color: '#469F6A',
            }}>Edit</Box>
          </Box>
        </Box> */}
        <Box sx={{marginBottom: '20px', fontFamily: 'Lato'}}>
        <Box sx={{display:'flex', alignItems:'center', height: '25px'}}><img style={{height:"100%", marginRight: '10px'}} src={soundIcon}/>Device Speakers:</Box>
          <Box sx={{marginTop: '10px', marginLeft: '35px', display: 'flex'}}>
            
            <Box sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              color: '#ECECEC',
            }}>{ userData?.audio_on ?
              "Sound On"
              :
              "Sound Off"
            }</Box>
            <Box 
            onClick={() => {
              dispatch(appActions.SetAudioOn({audioOn: !userData?.audio_on}))
            }}
            sx={{
              marginLeft: '7px',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              textDecorationLine: 'underline',
              color: '#469F6A',
              '&:hover': {
                cursor: 'pointer',
                // transition: '0.3s',
                // boxShadow: '0px 0px 5px 5px rgb(70, 159, 106,.5)',
              },
            }}>
              { userData?.audio_on ?
              "Switch to Keyboard Speaker"
              :
              "Switch to Device Speaker"
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
    </Box>
  )
}

export default Keyboard

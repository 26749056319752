import {
  ListenForReactEvent,
  NotifyReact,
  RemoveListener,
} from "Phaser/EventBus";
import Phaser from "phaser";
import ExerciseBase from "./ExerciseBase";

export default class PauseMenu extends Phaser.Scene {
  other: string | null = null;
  constructor() {
    super("PauseMenu");
  }
  create(): void {
    ListenForReactEvent("toggle-play-pause", this.resume, this, true);
    ListenForReactEvent("resume-exercise", this.resume, this, true);
    this.events.once("shutdown", () => {
      RemoveListener("resume-exercise");
      RemoveListener("toggle-play-pause");
    });
  }
  resume() {
    console.log("resume");
    NotifyReact("exercise-resumed");
    this.scene.resume(this.other!);
    (this.scene.get(this.other!) as ExerciseBase).resume();
    this.scene.stop();
  }
  init({ other }: { other: string }): void {
    this.other = other;
    NotifyReact("exercise-paused");
  }
}

import { AugmentedCognitoUser, MainAppReducer } from 'Types';
import { BaseEvent, EventActionTypes, EventReducer, EventType, LevelSessionPayload, VisitEventAction } from 'Types/EventTypes';
import {
  SetSessionId,
  SignInEventAction,
  MidiConnectionSuccessEventAction,
  MidiConnectionFailureEventAction,
  LevelPlayingEventAction,
  TutorialStartedEventAction,
  TutorialPageViewEventAction,
  TutorialSkippedEventAction,
  MobileBlockedEventAction,
  PhraseCompletedEventAction,
  SafariBlockedEventAction,
  UserSystemDetailsEventAction,
} from 'Types/EventTypes';
import * as changeKeys from "change-case/keys";



export const setSessionId = (): SetSessionId => ({
  type: EventActionTypes.SET_SESSION_ID,
});

export const visitEventAction = (): VisitEventAction => ({
  type: EventActionTypes.VISIT_EVENT_ACTION,
  payload: {
    event_type: EventType.VisitEvent,
  }
});

export const signInEventAction = (): SignInEventAction => ({
  type: EventActionTypes.SIGN_IN_EVENT_ACTION,
  payload: {
    event_type: EventType.SignInEvent
  }
})

export const midiConnectionSuccessEventAction = (): MidiConnectionSuccessEventAction => ({
  type: EventActionTypes.MIDI_CONNECTION_SUCCESS_EVENT_ACTION,
  payload: {
    event_type: EventType.MidiConnectionSuccessEvent
  }
});

export const midiConnectionFailureEventAction = (): MidiConnectionFailureEventAction => ({
  type: EventActionTypes.MIDI_CONNECTION_FAILURE_EVENT_ACTION,
  payload: {
    event_type: EventType.MidiConnectionFailureEvent
  }
});

export const levelPlayingEventAction = (playTime: number, levelNumber: number, tierNumber: number, playSessionId: number): LevelPlayingEventAction => ({
  type: EventActionTypes.LEVEL_PLAYING_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.LevelPlayingEvent,
    playTime: playTime,
    levelNumber,
    tierNumber,
    playSessionId,
  })
});

export const tutorialStartedEventAction = (levelNumber: number): TutorialStartedEventAction => ({
  type: EventActionTypes.TUTORIAL_STARTED_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.TutorialStartedEvent,
    levelNumber
  })
});

export const tutorialPageViewEventAction = (levelNumber: number, pageNumber: number): TutorialPageViewEventAction => ({
  type: EventActionTypes.TUTORIAL_PAGE_VIEW_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.TutorialPageViewEvent,
    levelNumber,
    pageNumber
  })
});

export const tutorialSkippedEventAction = (levelNumber: number): TutorialSkippedEventAction => ({
  type: EventActionTypes.TUTORIAL_SKIPPED_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.TutorialSkippedEvent,
    levelNumber
  })
});

export const mobileBlockedEventAction = (): MobileBlockedEventAction => ({
  type: EventActionTypes.MOBILE_BLOCKED_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.MobileBlockedEvent
  })
});

export const phraseCompletedEventAction = ( midiData: string, userPlayData: string, phraseId: string): PhraseCompletedEventAction => ({
  type: EventActionTypes.PHRASE_COMPLETED_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.PhraseCompletedEvent,
    midiData,
    userPlayData,
    phraseId
  })
});

export const safariBlockedEventAction = (): SafariBlockedEventAction => ({
  type: EventActionTypes.SAFARI_BLOCKED_EVENT_ACTION,
  payload: {
    event_type: EventType.SafariBlockedEvent
  }
});

export const userSystemDetailsEventAction = (
  os: string,
  screenWidth: number,
  screenHeight: number,
  platform: string,
  userAgent?: string,
  browser?: string,
  browserType?: string,
  browserVersion?: string,
): UserSystemDetailsEventAction => {
  return ({
  type: EventActionTypes.USER_SYSTEM_DETAILS_EVENT_ACTION,
  payload: changeKeys.snakeCase({
    event_type: EventType.UserSystemDetailsEvent,
    browser,
    browserType,
    userAgent,
    screenWidth,
    screenHeight,
    os,
    platform,
    browserVersion,
  })
});
}

export const createLevelSession = (payload: LevelSessionPayload) => ({
  type: EventActionTypes.CREATE_LEVEL_SESSION,
  payload
})

export const setPlaySessionId = (payload: string | undefined) => ({
  type: EventActionTypes.SET_PLAY_SESSION_ID,
  payload
})
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { Formik, Form } from "formik";
import { ForgotPasswordData } from 'Types';
import ForgotPasswordForm, {forgotPasswordFormSchema} from 'Components/Forms/ForgotPasswordForm';
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import GoBackButton from 'Components/GoBackButton';
import IntroFlowBack from 'Components/IntroFlowBack'
import { setPendingEmailOrPhone, setAuthPending } from 'Actions/auth';
import { AuthReducer } from 'Types/AuthTypes';
import { resendSignUp, forgotPassword } from 'Utils/Amplify';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'Actions/auth';

type ForgotPasswordProps = {
  goBackFn: () => void,
  goToReset: () => void
}

const ForgotPassword: React.FC<ForgotPasswordProps> = (props) => {

  const { goBackFn, goToReset } = props
  const dispatch = useDispatch();
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const {
    pendingEmailOrPhone,
    pendingCountryAcronym,
    errors
  } = auth;

  React.useEffect(()=>{
    if(pendingEmailOrPhone) {
      goToReset()
    }
  },[
    pendingEmailOrPhone
  ])
   

  return (
    <Box 
      sx={{
      display:'flex',
      flexDirection:'column', 
      alignItems:'center', 
      marginTop: '20px',
      marginBottom: '20px',
      ...textStyles
      }}
    >
      <IntroFlowBack/>
      { 
        pendingEmailOrPhone && 
        <>
          <Typography 
            sx={{
              margin: "20px"
            }}
            color="rgb(255,255,255,1.0)"
          >
            Your registration is pending. Check your email to verify.
            <br></br>
            (If you don't see an email, check your spam folder)
          </Typography> 
          <Link 
            sx={{
              textAlign: 'center' as 'center',
              cursor: 'pointer'
            }}
            onClick={()=> {resendSignUp(pendingEmailOrPhone || "", pendingCountryAcronym || "")}}
          >
            Didn't get a link? Click here to resend.
          </Link>
        </>
      }
      {/* {!pendingEmail && resetEmail && 
        <Box
          sx={{
            maxWidth: '600px'
          }}
        > 
          <Typography 
            sx={{
              margin: "10px"
            }}
            color="rgb(255,255,255,1.0)"
          >
            If your email is in our system, a password reset link has been delivered to your inbox. Check your email (and spam). This can take up to 10 minutes. :)
          </Typography> 
        </Box>
      } */}
      {!pendingEmailOrPhone && 
      <>
       <Box 
        sx={{
          maxWidth: '400px',
          margin: '20px'
        }}>
          <Typography
            sx={{
              fontSize: '20px',
              color: 'rgb(255,255,255,1.0)'
            }}
          > 
            Forgot Password?
          </Typography>
          <Typography
            sx={{
              marginTop: '10px',
              fontSize:'16px',
              color: 'rgb(255,255,255,1.0)',
              fontFamily: 'Lato',
            }}
          > 
            Enter the email or phone number you used when you joined and we’ll send you a verification code.
          </Typography>
        </Box>
        <Formik
        initialValues={{
          "emailOrPhone": "",
          "countryAcronym": "US",
          "unknown": ""
        }}
        validate={(values: ForgotPasswordData) => {
          return ValidateForm(forgotPasswordFormSchema,values)}
        }
        onSubmit={(values:ForgotPasswordData) => {
          dispatch(authActions.forgotPassword({
            emailOrPhone: values.emailOrPhone, 
            countryAcronym: values.countryAcronym || ""
          }))
        }}
        validateOnChange={false}
      >
        {formikProps => (
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              dispatch(authActions.resetErrors());
              formikProps.handleSubmit(e);
            }}
          >
            <Box sx={{marginTop: '10px', marginBottom: '10px', width: '350px', display:'flex', flexDirection: 'column' }}>
              <ForgotPasswordForm 
                {...formikProps}
                errors={{...errors,...formikProps.errors}}
              />
            </Box>
          </Form>
        )}
        </Formik>
      </>
      }
      <Box>
        {/* <GoBackButton 
        onClick={goBackFn}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
        >
          Back
        </GoBackButton> */}
      </Box>
    </Box>
  )
}

const textStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.16px',
}

export default ForgotPassword;
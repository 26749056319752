import React from 'react';
import { Formik, Form } from "formik";
import { Box, Typography } from '@mui/material';
import { ResetCodeData } from 'Types';
import ResetCodeForm, {resetCodeFormSchema} from 'Components/Forms/ResetCodeForm';
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import { useDispatch, useSelector } from 'react-redux';
import { AuthReducer } from 'Types/AuthTypes';
import * as authActions from 'Actions/auth';
import { goBack } from 'connected-react-router';
import Link from '@mui/material/Link';
import { isPhoneNumber } from 'Components/Forms/SignUpForm';

type VerificationCodeProps = {
  onSubmit?: (code: string) => void;
}

const VerificationCode: React.FC<VerificationCodeProps> = (props) => {

  const { onSubmit } = props;

  const dispatch = useDispatch();
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const { 
    errors,
    pendingEmailOrPhone,
    pendingCountryAcronym
   } = auth;


  return(
    <Box
    sx ={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
    >
       <Box 
        sx={{
          maxWidth: '400px',
          margin: '20px'
        }}>
          <h3 style={{marginTop: '50px', marginBottom: 0}}>Verify your phone number</h3>
          <Box 
            sx={{
              margin: "20px",
              backgroundColor: 'rgba(57, 94, 227, 0.40)',
            }}
            color="#00C2FF"
          >
            Your registration is pending. Check your SMS messages for a verification code.
          </Box> 
          <Link 
            sx={{
              textAlign: 'center' as 'center',
              cursor: 'pointer'
            }}
            onClick={()=> {dispatch(authActions.resendSignUp(pendingEmailOrPhone || ""))}}
          >
            Didn't get a code? Click here to resend.
          </Link>
        </Box>
        <Formik
        initialValues={{
          "code": "",
          "unknown": ""
        }}
        validate={(values: ResetCodeData) => {
          return ValidateForm(resetCodeFormSchema,values)}
        }
        onSubmit={ (values) => {
          dispatch(authActions.SetConfirmationCode(values.code))
          if(isPhoneNumber(pendingEmailOrPhone)) {
            dispatch(authActions.confirmSignUp({
              phoneNumber: pendingEmailOrPhone || "", 
              confirmationCode: values.code,
              countryAcronym: pendingCountryAcronym || "US"
            }))
          }
          if(onSubmit){
            onSubmit(values.code)
          }
        }}
        validateOnChange={false}
      >
        {formikProps => (
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              dispatch(authActions.resetErrors());
              formikProps.handleSubmit(e);
            }}
          >
            <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
              <ResetCodeForm 
                {...formikProps}
                errors={{...errors,...formikProps.errors}}
              />
            </Box>
          </Form>
        )}
        </Formik>
      </Box>
  )
}

export default VerificationCode;
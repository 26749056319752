import { Box } from '@mui/material';
import { useRef } from 'react'
import Survey from 'Containers/Roadmap/Survey';
import Home from './Home';
import Browse from './Browse';
import { MainAppReducer } from 'Types';
import { useSelector, useDispatch } from "react-redux";
import * as appActions from '../../Actions/app'
import { useEffect } from 'react';

const Repertoire = () => {
  const data = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const dispatch = useDispatch()

  // When you return to repertoire component, set the error recognition back
  // We determined we don't (for now) want it persisting the settings to differnet songs; we may at some point decide to set this for every unique repertoire
  useEffect(() => {
    dispatch(appActions.setErrorRecognitionActive({ right: true, left: true }))
  }, [])

  const repRef = useRef<HTMLImageElement>(null);
  return (
    <Box 
      sx={{
        display: 'flex', 
        // flexDirection: 'column',
        height: '100vh', 
        width: '100%',
      }} ref={repRef}
    >
      <Survey />
      <Box sx={{display: 'flex', flexDirection: 'column', width: 'calc(100% - 100px)',}}>
        { data.repPageSelected == "Home" && <Home/> }
        { data.repPageSelected == "Browse" && <Browse/> }
      </Box>      
    </Box>
  )

}

export default Repertoire

import { FC } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const backcontainerStyles = {
  zIndex: 2,
  display: 'inline-flex',
  alignItems: 'center',
  gap: '6px',
  justifyContent: 'center',
  position: 'absolute',
  left: '16px',//'22px', 
  top: '16px',//'22px',
  '&:hover': {
      background: 'rgba(222,144,118,.5)',
      cursor: 'pointer',
      transition: '0.3s',
      borderRadius:'20px',//'50%',
      boxShadow: '0px 0px 5px 5px rgb(222,144,118,.5)',
    },
}

const textStyles = {
  color: 'var(--neutrals-200, #ADB1B7)',
  textAlign: 'center',
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.16px',
  marginBottom: '3px',
}

interface IntroFlowBackProps {
  handleBack?: () => void; // handleBack is optional and is a function that returns void
}

const IntroFlowBack: FC<IntroFlowBackProps> = ({ handleBack }) => {
  const navigate = useNavigate(); 

  const handleClick = () => {
    if (handleBack) {
      handleBack();
    } else {
      navigate(-1);
    }
  }

  return (
    <Box sx={backcontainerStyles} onClick={handleClick}>
      {/* <img style={{height: '26px'}}src={LeftArrowIcon}/> */}
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path d="M17.1666 9.16657V10.8332H7.16655L11.7499 15.4166L10.5666 16.5999L3.96655 9.9999L10.5666 3.3999L11.7499 4.58324L7.16655 9.16657H17.1666Z" fill="#ADB1B7"/>
      </svg>
      <Box sx={textStyles}>
        Back
      </Box>
    </Box>
  )
}
export default IntroFlowBack

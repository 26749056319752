import { Map, Set } from 'immutable';

export interface CompletionData {
  lesson: number;
  chapter: number;
};

// Maps a lesson number to a set of completed chapters within it
export type CompletionState = Map<number, Set<number>>;

export enum ChapterActionTypes {
  LOAD_COMPLETION_STATE = "LOAD_COMPLETION_STATE",
  MARK_CHAPTER_COMPLETE = "MARK_CHAPTER_COMPLETE"
}

export type LoadCompletionState = {
  type: typeof ChapterActionTypes.LOAD_COMPLETION_STATE,
  payload: CompletionState
}

export type MarkAsCompleted = {
  type: typeof ChapterActionTypes.MARK_CHAPTER_COMPLETE,
  payload: CompletionData
}

export type ChapterAction = LoadCompletionState | MarkAsCompleted;

export interface ChapterReducer {
  chapterReducer: CompletionState
}

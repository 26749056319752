
import { UpdateAuthDataValues } from 'Types'
import { 
  authActionTypes, 
  SetTokenPayload,
  SetIdTokenPayload,
  LogInPlayload,
  SetErrorsPayload,
  SetPendingEmailOrPhonePayload,
  SetPendingEmailOrPhoneSuccessfulPayload,
  SetUserPayload,
  SIGN_IN_COMPONENT,
  ChangePasswordPayload,
  SignUpPayload,
  SubmitNewPassword,
  SetResetEmailOrPhone,
  SubmitNewPasswordPayload,
  ConfirmSignUp,
  ConfirmSignUpPayload
 } from 'Types/AuthTypes'


 export const setToken = (payload: SetTokenPayload) => ({
  type: authActionTypes.SET_TOKEN,
  payload
})

export const setIdToken = (payload: SetIdTokenPayload) => ({
  type: authActionTypes.SET_ID_TOKEN,
  payload
})

export const setAuthPending = (payload: boolean) => ({
  type: authActionTypes.SET_AUTH_PENDING,
  payload
})

export const setProcessPending = (payload: boolean) => ({
  type: authActionTypes.SET_PROCESS_PENDING,
  payload
})

export const SetConfirmationCode = (payload: string | undefined       ) => ({
  type: authActionTypes.SET_CONFIRMATION_CODE,
  payload
})

export const setPendingEmailOrPhone = (payload: string | undefined) => ({
  type: authActionTypes.SET_PENDING_EMAIL_OR_PHONE,
  payload
})

export const setPendingCountryAcronym = (payload: string | undefined) => ({
  type: authActionTypes.SET_PENDING_COUNTRY_ACRONYM,
  payload
})

export const setPendingEmailOrPhoneSuccessful = (payload: SetPendingEmailOrPhoneSuccessfulPayload) => ({
  type: authActionTypes.SET_PENDING_EMAIL_OR_PHONE_SUCCESSFUL,
  payload
})

export const setErrors = (payload: SetErrorsPayload) => ({
  type: authActionTypes.SET_ERRORS,
  payload
})

export const logIn = (payload: LogInPlayload) => ({
  type: authActionTypes.LOG_IN,
  payload
})

export const logOut = () => ({
  type: authActionTypes.LOG_OUT,
})

export const signUp = (payload: SignUpPayload) => ({
  type: authActionTypes.SIGN_UP,
  payload
})

export const confirmSignUp = (payload: ConfirmSignUpPayload) => ({
  type: authActionTypes.CONFIRM_SIGN_UP,
  payload
})

export const resetErrors = () => ({
  type: authActionTypes.RESET_ERRORS,
})

export const setUser = (payload: SetUserPayload) => ({
  type: authActionTypes.SET_USER,
  payload
})

export const resendSignUp = (payload: string) => ({
  type: authActionTypes.RESEND_SIGN_UP,
  payload
})

export const refreshUser = () => ({
  type: authActionTypes.REFRESH_USER,
})

export const forgotPassword = (payload: {emailOrPhone: string, countryAcronym?: string}) => ({
  type: authActionTypes.FORGOT_PASSWORD,
  payload
})

export const verifyPendingEmailOrPhone = (payload: string) => ({
  type: authActionTypes.VERIFY_PENDING_EMAIL_OR_PHONE,
  payload
})

export const setSignInComponent = (payload: SIGN_IN_COMPONENT) => ({
  type: authActionTypes.SET_SIGN_IN_COMPONENT,
  payload
})

export const verifyAttributes = () => ({
  type: authActionTypes.VERIFY_ATTRIBUTES,
})

export const verifyAttributesSubmit = (code: string) => ({
  type: authActionTypes.VERIFY_ATTRIBUTES_SUBMIT,
  payload: code
})

export const updateAuthData = (payload: UpdateAuthDataValues) => ({
  type: authActionTypes.UPDATE_AUTH_DATA,
  payload
})

export const setSuccessMessage = (payload: string) => ({
  type: authActionTypes.SET_SUCCESS_MESSAGE,
  payload
})

export const setFailureMessage = (payload: string) => ({
  type: authActionTypes.SET_FAILURE_MESSAGE,
  payload
})

export const changePassword = (payload: ChangePasswordPayload) => ({
  type: authActionTypes.CHANGE_PASSWORD,
  payload
})

export const submitNewPassword = (payload: SubmitNewPasswordPayload) => ({
  type: authActionTypes.SUBMIT_NEW_PASSWORD,
  payload
})

export const setResetEmailOrPhone = (payload: string) => ({
  type: authActionTypes.SET_RESET_EMAIL_OR_PHONE,
  payload
})

export const setResetCountryAcronym = (payload: string) => ({
  type: authActionTypes.SET_RESET_COUNTRY_ACRONYM,
  payload
})

export const tryGetCurrentAuth = () =>({
  type: authActionTypes.TRY_GET_CURRENT_AUTH
})

export const setTempSignUpPass = (payload: string | undefined) => ({
  type: authActionTypes.SET_TEMP_SIGNUP_PASS,
  payload
})
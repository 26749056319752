import React, { useState, useRef, useEffect } from 'react';
import { TextField, InputAdornment, IconButton, SxProps, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Theme } from '@mui/system';

interface ExpandingSearchBarProps {
  sx?: SxProps<Theme>; // Optional sx prop for custom styles,
  onChange: (text:string)=>void

}

const ExpandingSearchBar: React.FC<ExpandingSearchBarProps> = ({ sx, onChange }) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const inputRef = useRef<HTMLInputElement | null>(null); // Create a ref for the input

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    if (!searchText) {
      setFocused(false);
    }
  };

  useEffect(() => {
    // Focus the input element when the component is focused
    if (focused && inputRef.current) {
      inputRef.current.focus(); // Automatically focus the input
    }
  }, [focused]); // Runs every time `focused` changes

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: focused ? 'flex-end' : 'center', // Center icon when collapsed
        alignItems: 'center',
        backgroundColor: '#0F1729', // Background color matching input field
        borderRadius: focused ? '25px' : '50%', // Circular when collapsed, rounded when expanded
        padding: '2px',
        transition: 'width 0.3s ease-in-out, border-radius 0.3s ease-in-out',
        width: focused ? '250px' : '40px', // Collapsed: icon only, Expanded: full width
        height: '40px', // Set a fixed height to maintain circular shape
        // transform: focused ? 'translateX(0)' : 'translateX(100px)', // Expand to the left
        overflow: 'hidden', // Ensure background clips to border radius
        cursor: 'pointer', // Pointer when hovering over the icon
        ...sx, // Apply custom styles passed via props
      }}
      onClick={handleFocus} // Allow the user to click the icon to expand
    >
      <TextField
        inputRef={inputRef} // Assign the ref to the input
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value)
          onChange(e.target.value)
        }}
        onBlur={handleBlur}
        placeholder="Search" // Add the "Search" placeholder text
        variant="outlined"
        size="small"
        fullWidth
        autoComplete="off" // Disable autocomplete suggestions
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleFocus}>
                <SearchIcon sx={{ color: 'white' }} /> {/* Ensure icon is white */}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            borderRadius: '25px', // Match container border radius when expanded
            backgroundColor: 'inherit', // Match background to parent container
            border: 'none', // Remove the border/outline
            '& fieldset': {
              border: 'none', // Remove default MUI outline
            },
            color: '#fff',

            paddingLeft: focused ? '8px' : '0', // No padding when collapsed
            opacity: focused ? 1 : 0, // Hide text input when collapsed
          },
        }}
        sx={{
          display: focused ? 'block' : 'none', // Hide input text when not focused
        }}
      />
      {!focused && ( // Show the search icon alone when not focused
        <IconButton>
          <SearchIcon sx={{ color: 'white' }} /> {/* Ensure icon is white when collapsed */}
        </IconButton>
      )}
    </Box>
  );
};

export default ExpandingSearchBar;


import { useState } from 'react'
import { Button, Box, TextField, Link, FormGroup, FormControlLabel, Checkbox, Input } from '@mui/material';
import { useNavigate } from "react-router-dom";
import ColoredLogo from '../../assets/images/ColoredLogo.png';
import pianoBackground from '../../assets/images/pianoBackground.png';
import { CustomTextField } from 'Components/StyledComponents';


import { useSelector, useDispatch } from 'react-redux'
import * as appActions from '../../Actions/app'

import { RootState } from '../../Reducers'

const Plan = () => {
    //const [componentShow, setComponentShow] = useState("Signin")
    
    // Plan options are "Free", "Month", and "Year"
    let selectedPlan = "Month"

    //const data = useSelector((state: RootState) => state.mainAppReducer)
    //console.log("data from selector?", data)

    // const dispatch = useDispatch()

    const navigate = useNavigate(); 
    // const navPlayGuest = () => {
    //   console.log("dispatch!")
    //   dispatch(appActions.fetchClientDataRequest())
    //   //let path = `/welcome`; 
    //   //navigate(path);
    // }
    const navPayment = (planSelect: string) => {
      let path = `/payment`; 
      navigate(path);
    }

    const navPlayGuest = () => { // Set client as guest (same as if chosen at /register page)
      //dispatch(appActions.fetchClientDataRequest())
      let path = `/welcome`
      navigate(path)
    }

    return (
        <Box sx={pageDivStyles}>
          <Box sx={filterDivStyles}>
            <Box sx={containerStyles}>
              <Box sx={{position:'absolute', top:'-35px', height: '70px'}}>
                <img style={{maxHeight: '100%'}} src={ColoredLogo}/>
              </Box>
              <Box>
                
                <Box sx={{
                  //fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  fontSize: '34px',
                  letterSpacing: '0.01em',
                  color: '#FFFFFF',
                  padding: '80px 120px 40px 120px',
                }}>
                  Your free trial has ended.<br/><br/>
                  Please pick a payment plan<br/>to continue flowing:
                </Box>


                <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', marginTop: '20px'}}>

                  <Box sx={{display: 'flex', margin: '0px 40px 0px 40px'}}>
                    {/* <Box sx={paymentContainerStyles1}>
                      <Box sx={paymentContainerStyles2}>
                      <Box sx={textStyles1}>
                        Free Trial
                      </Box>
                      <Box sx={textStyles2}>
                        Free
                      </Box>
                      <Box sx={textStyles3}>
                        Up to 5 lessons
                      </Box>
                      <Box sx={buttonContainerStyles}>
                      <Button onClick={() => navPayment("Free")} style={selectedPlan == "Free" ? selectedStyles : unselectedStyles} variant="contained">
                      SELECT
                      </Button>
                      </Box>
                      </Box>
                    </Box> */}
                    <Box sx={paymentContainerStyles1}>
                      <Box sx={paymentContainerStyles2}>
                      <Box sx={textStyles1}>
                        Monthly Plan
                      </Box>
                      <Box sx={textStyles2}>
                        $15.99
                      </Box>
                      <Box sx={textStyles3}>
                        Per Month
                      </Box>
                      <Box sx={buttonContainerStyles}>
                      <Button onClick={() => navPayment("Month")} style={selectedPlan == "Month" ? selectedStyles : unselectedStyles} variant="contained">
                        SELECT
                      </Button>
                      </Box>
                      </Box>
                    </Box>
                    <Box sx={paymentContainerStyles1}>
                      <Box sx={paymentContainerStyles2}>
                      <Box sx={textStyles1}>
                        Annual Plan
                      </Box>
                      <Box sx={textStyles2}>
                        $169.99
                      </Box>
                      <Box sx={textStyles3}>
                        Per Year (14% off!)
                      </Box>
                      <Box sx={buttonContainerStyles}>
                      <Button onClick={() => navPayment("Year")} style={selectedPlan == "Year" ? selectedStyles : unselectedStyles} variant="contained">
                        SELECT
                      </Button>
                      </Box>
                      </Box>
                    </Box>
                    
                  </Box>
                </Box>
                

        </Box>
      </Box>
      {/* <Box sx={{paddingTop: '30px'}}>
        <Button variant="text" onClick={navPlayGuest} style={{fontSize: '14px', color: '#CED0D4', textDecorationLine: 'underline'}}>Skip For Now</Button>
      </Box> */}
    </Box>
  </Box>      
  )
}

export default Plan;

const pageDivStyles = {
    backgroundImage: `url(${pianoBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
}

const filterDivStyles = {
  width: '100%',
  height: '100%',
  backdropFilter: 'brightness(15%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}

const containerStyles = {
    backgroundColor: "black",
    width: '760px',
    height: '760px',
    maxWidth: '80%',
    maxHeight: '80%',
    borderRadius: '12px',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 10,
}

const paymentContainerStyles1 = {
  display: 'flex', 
  flexDirection: 'column', 
  border: '1px solid #5B646E', 
  borderRadius: '8px',
  margin: '0px 8px 100px 8px'
}
const paymentContainerStyles2 = {
  margin: '25px 40px 0px 40px'
}
const textStyles1 = {
  color:'#65AF83',
  //fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '16px',
  textAlign: 'center',
  textTransform: 'uppercase',
}
const textStyles2 = {
  color: '#FFFFFF',
  //fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '300',
  fontSize: '48px',
  textAlign: 'center',
  marginTop: '5px',
}
const textStyles3 = {
  color: '#FFFFFF',
  //fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '16px',
  textAlign: 'center',
  marginTop: '5px',
}
const buttonContainerStyles = {
  marginTop: '20px'
}

const selectedStyles = {
    marginTop: 15, 
    marginBottom: 25, 
    width: '138px', 
    color: 'black', 
    fontSize: '16px',
    background: '#CA7C62', 
    boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
    borderRadius: '40px'
}

const unselectedStyles = {
  marginTop: 15, 
  marginBottom: 25, 
  width: '138px', 
  color: '#CA7C62', 
  fontSize: '16px',
  // background: '#CA7C62', 
  background: 'black',
  border: '2px solid #CA7C62', 
  boxShadow: '0px 3.61149px 1.20383px -2.40766px rgba(0, 0, 0, 0.2), 0px 2.40766px 2.40766px rgba(0, 0, 0, 0.14), 0px 1.20383px 6.01915px rgba(0, 0, 0, 0.12)', 
  borderRadius: '40px'
}
import {
  EventBus,
  ListenForReactEvent,
  NotifyReact,
  RemoveListener,
} from "../EventBus";
import Piano, {
  BaseNote,
  Note,
  getMidiNumberFromBaseNote,
  getMidiNumberFromNote,
} from "../GameObjects/Piano";
import Phaser from "phaser";
import Instruction from "Phaser/GameObjects/Instruction";
import ExerciseBase from "./ExerciseBase";

export interface Config {
  start: Note;
  end: Note;
}

// Scene containing the interactive MIDI piano graphic and its related controllers.
export class PianoScene extends ExerciseBase {
  // The piano gameobject
  piano?: Piano;
  // Current octave of the piano (for computer keyboard to MIDI mapping, like in GarageBand)
  octave?: number;

  instruction: Instruction | null = null;

  listening: boolean = false;

  constructor() {
    super({ key: "PianoScene" });
  }

  create() {
    EventBus.emit("current-scene-ready", this);
  }

  init({ start, end }: Config) {
    this.piano = new Piano(this, 0, 0, start, end);
    this.piano.setScale(0.12);
    this.piano.setPosition(
      -this.cameras.main.width / 2,
      this.cameras.main.height / 2 - 25 * window.devicePixelRatio
    );
    setTimeout(
      () =>
        this.tweens.add({
          targets: this.piano,
          x: this.cameras.main.width / 2,
          duration: 1000,
          ease: "Power1",
        }),
      500
    );
    setTimeout(() => NotifyReact("exercise-loaded"), 50);
    this.setupKeyboardMapping();
    this.octave = 1;
    EventBus.on("update-objective", this.setObjective, this);

    super.init({ start, end });
    //this.cameras.main.setBackgroundColor(colors.bg);
  }

  octaveUp() {
    if (this.octave! < this.piano!.highestOctave()) this.octave!++;
  }

  octaveDown() {
    if (this.octave! > this.piano!.lowestOctave()) this.octave!--;
  }

  addKeyToInputs(key: number) {
    return this.input.keyboard?.addKey(key);
  }

  setupKeyboardMapping() {
    const codes = Phaser.Input.Keyboard.KeyCodes;
    this.addKeyToInputs(codes.Z)?.addListener(
      "down",
      () => this.octaveDown(),
      this
    );
    this.addKeyToInputs(codes.X)?.addListener(
      "down",
      () => this.octaveUp(),
      this
    );
    const map = new Map<number, [BaseNote, number]>();
    map.set(codes.A, ["C", 0]);
    map.set(codes.W, ["C#", 0]);
    map.set(codes.S, ["D", 0]);
    map.set(codes.E, ["D#", 0]);
    map.set(codes.D, ["E", 0]);
    map.set(codes.F, ["F", 0]);
    map.set(codes.T, ["F#", 0]);
    map.set(codes.G, ["G", 0]);
    map.set(codes.Y, ["G#", 0]);
    map.set(codes.H, ["A", 0]);
    map.set(codes.U, ["A#", 0]);
    map.set(codes.J, ["B", 0]);
    map.set(codes.K, ["C", 1]);
    map.set(codes.O, ["C#", 1]);
    map.set(codes.L, ["D", 1]);
    map.set(codes.P, ["D#", 1]);
    map.set(codes.SEMICOLON, ["E", 1]);
    map.set(codes.COMMA, ["F", 1]);
    map.forEach(([baseNote, mod], keyCode) => {
      this.addKeyToInputs(keyCode)?.addListener(
        "down",
        () => {
          this.playNote(baseNote, mod);
        },
        this
      );
      this.addKeyToInputs(keyCode)?.addListener(
        "up",
        () => {
          this.releaseNote(baseNote, mod);
        },
        this
      );
    });
    ListenForReactEvent(
      "midi-on",
      (ev: number) => this.piano?.onPressKey(ev),
      this
    );
    ListenForReactEvent(
      "midi-off",
      (ev: number) => this.piano?.onReleaseKey(ev),
      this
    );
  }

  playNote(base: BaseNote, octaveMod: number = 0) {
    const number = getMidiNumberFromBaseNote(base);
    this.piano!.onPressKey(number + (octaveMod + this.octave!) * 12);
  }

  releaseNote(base: BaseNote, octaveMod: number = 0) {
    const number = getMidiNumberFromBaseNote(base);
    this.piano!.onReleaseKey(number + (octaveMod + this.octave!) * 12);
  }

  passObjective(last?: boolean) {
    this.listening = false;
    if (last) this.passExercise();
    else {
      this.instruction?.showResult(
        true,
        () => NotifyReact("exercise-complete"),
        this
      );
      //NotifyReact("exercise-complete");
    }
  }

  startExercise(): void {
    this.listening = true;
  }

  onExerciseOver() {
    super.onExerciseOver(1500);
  }

  setObjective(
    instruction: string,
    last: boolean = true,
    startAfter: number = 0,
    target: Note | "any" = "any"
  ): void {
    this.time.delayedCall(
      startAfter * 1000,
      () => (this.listening = true),
      [],
      this
    );
    this.instruction?.destroy();
    this.instruction = new Instruction(this, instruction, "Bottom");
    if (target !== "any") {
      this.piano?.setValidator(
        (note) => note === getMidiNumberFromNote(target)
      );
      this.piano?.highlightKey(target, true);
    }
    this.piano?.addKeyListener(
      "down",
      target || "any",
      () => {
        if (!this.listening) return;
        this.passObjective(last);
        this.piano?.removeKeyListener("down", target || "any");
      },
      this,
      false
    );
  }

  transition(out?: boolean, onDone?: () => void, scope?: object): void {
    this.instruction?.hide();
    this.tweens.add({
      targets: this.piano,
      x: this.cameras.main.width * 2,
      duration: 1000,
      onComplete: onDone,
      callbackScope: scope,
    });
  }

  unload() {
    this.piano?.destroy();
    RemoveListener("midi-off");
    RemoveListener("midi-on");
    super.unload();
  }

  stop() {
    this.scene.stop();
  }

  update() {}

  changeScene() {
    // the piano appears in the first scene so we're just going to start by loading the first exercise
  }
}

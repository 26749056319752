import { XMLParser, XMLBuilder } from 'fast-xml-parser';
import Phrase from './Phrase';
import MusicXML from './MusicXML';
import TimeSignature from './TimeSignature';

export class MusicXMLStream {
    builder: XMLBuilder = new XMLBuilder({ ignoreAttributes: false, preserveOrder: true })
    parser: XMLParser = new XMLParser({ ignoreAttributes: false, preserveOrder: true })

    // List of parsed input phrases with a bit of additional metadata
    inputPhrases: Phrase[] = []

    // Time state tracking
    // Current measure accumulated over all phrases
    // this does not decrease when phrases are removed. It is cummmulative over time.
    currentMeasure: number = 0
    // Current measure within the current phrase.
    // The current phrase is always the first one in the list.
    currentPhraseMeasure: number = 0
    // Total measures accumulated over all phrases
    totalMeasures: number = 0

    // current index in he inputPhrases array. This allows us to go either 
    // measure by measure or phrase by phrase.
    currentPhrase: number = 0

    // XML state tracking: measure attributes carry across measures.
    // When we see new attributes, merge with previous attributes, 
    // and the current measure will get the merged attributes when queried.
    measureAttributes: any = {'attributes': []}

    private getXMLChild(children: any[], name: string) {
        for (let i = 0; i < children.length; i++) {
            const child: any = children[i]
            for (let j = 0; j < child.length; j++) {
                if (child[j].hasOwnProperty(name)) {
                    return child[j][name]
                }
            }
        }
        return null
    }

    private updateAttribs(attribs: any) {
      if (!attribs["attributes"]?.length) {
          return
      }
      
      // Look at each attribute in attribs, and try to match it against an existing measure attribute.
      // Keep track of which indices have already been merged, since the same name key may repeat multiple times,
      // and we should only merge once per key.
      const affectedIndices = []
      for (let attribObject of attribs['attributes']) {
        const attribName = Object.keys(attribObject)[0]
        let found = false

        for (let i = 0; i < this.measureAttributes['attributes']?.length && !found; i++) {
            const storedAttrib = this.measureAttributes['attributes'][i]
            const storedName = Object.keys(storedAttrib)[0]
            if (attribName == storedName && affectedIndices.indexOf(i) < 0) {
                this.measureAttributes['attributes'][i] = attribObject
                affectedIndices.push(i)
                found = true
            }
        }

        if (!found) {
            this.measureAttributes['attributes'].push(attribObject)
            affectedIndices.push(this.measureAttributes['attributes'].length - 1)
        }
      }
    }

    public measuresRemaining() : number {
      return this.totalMeasures - this.currentMeasure
    }

    public length(): number {
      return this.inputPhrases.length;
    }
 
    public addPhrase(phrase: Phrase) : boolean {
        try {
            this.inputPhrases.push(phrase)
            this.totalMeasures += phrase.musicXML.measures.length
            return true
        } catch(e) {
            console.error("Failed to parse MusicXML input string: ", e)
            return false
        }
    }

    public nextPhrasePart(numMeasures: number) : MusicXML {
        if (numMeasures > this.measuresRemaining()) {
            console.error("Cannot make next phrase, not enough measures remaining")
            new MusicXML("", [])
        }        

        const measures = []
        const timeSignatures: TimeSignature[] = []
        const currInputPhrase = this.inputPhrases[0]

        // First collect the list of measures to display.
        for (let i = 0; i < numMeasures; i++) {
            const inputPhrase = this.inputPhrases[0]
            // Ordered XML is a pain to access
            const measureObj = inputPhrase.musicXML.measures[this.currentPhraseMeasure]
            const measure = measureObj["measure"]
            measures.push(measureObj)

            // Increment the counters.
            this.currentPhraseMeasure += 1
            this.currentMeasure += 1

            // If we're done with the input phrase, we can throw it away now.
            if (this.currentPhraseMeasure >= inputPhrase.musicXML.measures.length) {
                // Remove from the front
                this.inputPhrases.shift()
                this.currentPhraseMeasure = 0
            }

            // Make sure we keep track of updating attributes
            const attribsIndex = MusicXML.getChildIndex(measure, "attributes")
            if (attribsIndex !== -1) {
                this.updateAttribs(measure[attribsIndex])
            }

            // The first measure always needs attributes
            // TODO: later measures may need attributes updates to avoid re-rendering a clef, for instance;
            //      specifically, the first measure of each input phrase will likely be problematic,
            //      if it's not the first measure in the displayed phrase we're creating.
            if (i === 0) {
                if (attribsIndex === -1) {
                    // Attributes must be added to the front of the measure to render properly
                    measure.unshift(this.measureAttributes)
                } else {
                    measure[attribsIndex] = this.measureAttributes
                }
            }

            const timeSignature = MusicXML.getElements(this.measureAttributes.attributes, "time")
            const beats = this.getXMLChild(timeSignature, "beats")
            const beatType = this.getXMLChild(timeSignature, "beat-type")
            const numerator = parseInt(beats[0]["#text"])
            const denominator = parseInt(beatType[0]["#text"])
            timeSignatures.push(new TimeSignature(numerator, denominator))
        }

        // Clone the current input phrase so we don't destroy the original, 
        // and replace the measures in it with the measures we want to display.
        // TODO is there a more efficient way to safely deep clone?
        const nextPhraseXMLContent = this.parser.parse(currInputPhrase.musicXML.xml);
        {
            // This is what we are trying to say, if accessing things was reasonable:
            // nextPhrase["score-partwise"]["part"]["measure"] = measures
         
            // This is how you do that:
            const scorePartwise = MusicXML.getElements(nextPhraseXMLContent, "score-partwise")[0]
            scorePartwise[MusicXML.getChildIndex(scorePartwise, "part")]["part"] = measures
        }

        // Serialize the XML we want OSMD to display
        let newMusicXMLContent = this.builder.build(nextPhraseXMLContent)

        let newMusicXML = new MusicXML(newMusicXMLContent, timeSignatures)
        return newMusicXML;
    }

    public nextPhrase(): Phrase | undefined {
        if (!this.inputPhrases.length) {
            console.error("Cannot make next phrase, no phrases left in queue")
            return undefined
        }
        this.currentPhrase +=1;
        const inputPhrase = this.inputPhrases[0]
        this.currentMeasure += inputPhrase.musicXML.measures.length
        this.inputPhrases.shift()
        return inputPhrase
    }

    public reset() {
        this.currentMeasure = 0;
        this.currentPhraseMeasure = 0;
        this.totalMeasures = 0;
        this.inputPhrases = [];
        this.measureAttributes = {'attributes': []}
    }
}

import { Box, IconButton } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import RepertoireItem from './RepertoireItem';
import { useSelector } from 'react-redux'
import { RepertoireReducer } from 'Types/RepertoireTypes';
import { MainAppReducer } from 'Types';
// import EmptyRepertoire from 'assets/images/repertoire/EmptyRepertoire.png';
import EmptyRepertoire from '../../../src/assets/images/Repertoire/EmptyRepertoire.png';
import { REPERTOIRE_PROGRESS } from 'Types/RepertoireTypes';
import { DragScroll } from './DragScroll'
import { Menu } from 'Components/RoadmapComponents/Menu';
import PageNav from './PageNav';

const Home = () => {
  const appReducerData = useSelector((state: MainAppReducer) => state.mainAppReducer)
  const [ recommendedRep, setRecommendedRep ] = useState(true)
  const highestLevelCompleted = appReducerData.highestLevelCompleted

  const repertoireReducerData = useSelector((state: RepertoireReducer) => state.repertoireReducer)
  const repertoireData = repertoireReducerData?.repertoireData
  const userRepertoireProgress = repertoireReducerData?.userRepertoireProgresses

  const containerRef: any = useRef(null)

  const currentDate = new Date(); // daysAgo will be 7 days ago to be in recently played.
  const daysAgo = new Date(currentDate.setDate(currentDate.getDate() - 7));

  let recentlyPlayedRep: any = [];
  let atYourLevelRep: any= [];
  let morePracticeRep: any = [];
  let inProgressRep: any = [];

  repertoireData?.forEach((rep) => {
    // First add recently played, then the in progress, then at your level, then more practice
    // In this way, there will not be duplicates.
    const urp = userRepertoireProgress?.find(item => item.repertoire.id === rep.id)
    if (urp && (new Date(urp.last_played_at) > daysAgo)) {
      recentlyPlayedRep.push(rep)
    }
    if (urp && urp.status == REPERTOIRE_PROGRESS.IN_PROGRESS) {
      inProgressRep.push(rep)
    }
    // "At your level" does not include the level that is in progress.
    if (rep.level.level_number == highestLevelCompleted) {
      atYourLevelRep.push(rep)
    }
    if (rep.level.level_number < highestLevelCompleted && rep.level.level_number > highestLevelCompleted - 5) {
      morePracticeRep.push(rep)
    }
  })

  const sortDescendingByLevel = (a: any, b: any) => {
    return b.level.level_number - a.level.level_number;
  }
  recentlyPlayedRep.sort(sortDescendingByLevel);
  atYourLevelRep.sort(sortDescendingByLevel);
  morePracticeRep.sort(sortDescendingByLevel);
  inProgressRep.sort(sortDescendingByLevel);

  useEffect(() => {
    if (recentlyPlayedRep.length == 0 && atYourLevelRep.length == 0 && inProgressRep.length == 0 && morePracticeRep.length == 0) {
      setRecommendedRep(false)
    } else {
      setRecommendedRep(true)
    }
  }, [recentlyPlayedRep, atYourLevelRep, inProgressRep, morePracticeRep])
  
  return (
    <Box 
      sx={{
        display: 'flex', 
        height: '100vh', 
        width: '100vw',
      }}
    >
      <Menu selected={"Repertoire"}/>
      <Box sx={{display: 'flex', flexDirection: 'column', width: 'calc(100% - 100px)',}}>
        <PageNav selected={"Home"}/>
        <Box sx={{margin: '0px 0px', justifyContent: 'flex-start', height: 'calc(100vh - 60px)', overflowY: 'auto'}} ref={containerRef}>
          <Box sx={{...recForYouStyles, ...recTextStyles}}>
            Recommended For You
          </Box>

          { recommendedRep ?
            <Box sx={{margin: '0px 28px', paddingTop: '20px'}}>
              { recentlyPlayedRep.length > 0 && 
                <RecentlyPlayed recentlyPlayedRep={recentlyPlayedRep} containerRef={containerRef}/>
              }
              { inProgressRep.length > 0 && 
                <InProgress inProgressRep={inProgressRep} containerRef={containerRef}/>
              }
              { atYourLevelRep.length > 0 && 
                <AtYourLevel atYourLevelRep={atYourLevelRep} containerRef={containerRef}/>
              }
              { morePracticeRep.length > 0 && 
                <MorePractice morePracticeRep={morePracticeRep} containerRef={containerRef}/>
              }
            </Box>
          :
            <Box sx={{position: 'relative'}}>
              <Box sx={{position: 'absolute', left: '220px', top: '100px', textAlign: 'center'}}>
                <img style={{width: '180px', marginBottom: '10px'}} src={EmptyRepertoire} />
                <Box sx={recTextStyles}>Beat level 3 or higher<br></br>to unlock repertoire</Box> 
              </Box>
            </Box>
          }
          <Box sx={{height: '400px'}}/>
        </Box>
      </Box>  
    </Box>
  )

}
export default Home

const RecentlyPlayed = (props: any) => {
  const {recentlyPlayedRep, containerRef} = props
  return (
    <Box sx={containerStyles}>
      <Box sx={titleStyles}>Recently Played</Box>
      <DragScroll>
        {recentlyPlayedRep.map((rep:any) =>
        <RepertoireItem repertoireGroup={'recent-played'} repertoireData={rep} containerRef={containerRef}/>
        )}
      </DragScroll>
    </Box>
  )
}

const AtYourLevel = (props: any) => {
  const {atYourLevelRep, containerRef} = props
  return (
    <Box sx={containerStyles}>
      <Box sx={titleStyles}>At Your Level</Box>
      <DragScroll>
        {atYourLevelRep.map((rep:any) =>
        <RepertoireItem repertoireGroup={''} repertoireData={rep} containerRef={containerRef}/>
        )}
      </DragScroll>
    </Box>
  )
}

const InProgress = (props: any) => {
  const {inProgressRep, containerRef} = props
  return (
    <Box sx={containerStyles}>
      <Box sx={titleStyles}>In Progress</Box>
      <DragScroll>
        {inProgressRep.map((rep:any) =>
        <RepertoireItem repertoireGroup={'in-progress'} repertoireData={rep} containerRef={containerRef}/>
        )}
      </DragScroll>
    </Box>
  )
}

const MorePractice = (props: any) => {
  const {morePracticeRep, containerRef} = props
  return (
    <Box sx={containerStyles}>
      <Box sx={titleStyles}>Need More Practice?</Box>
      <DragScroll>
        {morePracticeRep.map((rep:any) =>
        <RepertoireItem repertoireGroup={''} repertoireData={rep} containerRef={containerRef}/>
        )}
      </DragScroll>
    </Box>
  )
}

const containerStyles = {
  margin: '4px 0px'
}

const titleStyles = {
  textAlign: 'left',
  fontFamily: 'Lato',
  color: '#ECECEC',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: '24px',
}

const recForYouStyles = {
  padding: '24px 28px',
  backgroundColor: '#171F31',
}
const recTextStyles = {
  color: '#ADB1B7',
  textAlign: 'left',
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '18px',
}

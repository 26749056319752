import { Box } from '@mui/material';    
import PlayIcon from '../../assets/images/PlayIcon.png';
import Checkmark from '../../assets/images/Checkmark.png';
import { useDispatch, useSelector } from 'react-redux'
import * as appActions from '../../Actions/app'
import './PlayContainer.css'
import { useLocation, useNavigate } from 'react-router-dom';
import {v4 as uuid} from 'uuid'
import { AuthReducer } from 'Types/AuthTypes';
// import './index.css';
// import 'src/index.css'

interface PlayContainerProps {
  selectedLevel: any; // level data type
  levelSelect: number;
  level_number: number;
  name: string;
  description: string;
  selectedLevelProgress: any;
  tierCountForLevel: number;
}

export const PlayContainer = (props: PlayContainerProps) => {
  const {
    selectedLevel, //object
    levelSelect,
    level_number,
    name,
    description,
    selectedLevelProgress,
    tierCountForLevel,
  } = props
  let highestTierComplete = 0
  if (selectedLevelProgress) {
    highestTierComplete = selectedLevelProgress.highest_tier_complete
  }

  let levelZeroStyles = level_number == 0 ? 'level-0':''

  return (
    <Box sx={{position: 'fixed', width: '100%', left: '50px', bottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <Box className={`container-styles-1 ${levelZeroStyles}`}>
      <Box className="container-styles-2" sx={{width: '100%'}}>
        <Box sx={{height: '100%', width: level_number == 0 ? '80%':'50%'}}>
          <Box className="container-styles-3">
            <Box className="play-container-text-container">
              <p className="play-container-text-description" style={{color: '#CED0D4'}}>START PLAYING:&nbsp; </p>
              <p className="play-container-text-description" style={{color: '#469F6A'}}> {name}</p>
            </Box>
            <p className="play-container-text">{description}</p>
          </Box>
        </Box>
        { (level_number != 0) &&
          <Box sx={{width: '50%', marginRight: '20px', display: 'flex'}}>
            <Box sx={{
              margin: 'auto',
              padding: '3px 5px 1px 5px',
              display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
              borderRadius: '18px', backgroundColor: '#e2e2e2',
              }}>
              <img style={{maxWidth: '100%', maxHeight: '110px'}} src={`https://${selectedLevel.srcs}`}/>
            </Box>
          </Box>
        }
        <Play levelSelect={levelSelect}/>
      </Box> 
      <Completion highestTierComplete={highestTierComplete} tierCountForLevel={tierCountForLevel}/>
    </Box>
    </Box>
  )
}

const Play = (levelSelect: any) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation()
  const auth = useSelector((state: AuthReducer) => state.authReducer)

  const onPlay = () => {
    // Will grab tutorials and redirect in the sagas
    dispatch(appActions.fetchTutorialData())
    navigate(`/tutorial`)
  } 
    return (
    <Box 
      onClick={() => {
        sessionStorage.setItem('previousLocation', location.pathname)
        onPlay()
      }}
      className="play-container-styles">
        <Box className="play-circle-styles">
        </Box>
        <Box className="poly-styles">
            <img src={PlayIcon}/>
        </Box>
    </Box>
    )
}

const Completion = (props: {highestTierComplete: number, tierCountForLevel: number;}) => {
  const { highestTierComplete, tierCountForLevel } = props
  let completeColor = '#6F87F1'
  let incompleteColor = '#767C8C'
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', height: '40px', marginTop: '6px',
    }}>
      { highestTierComplete == 0 && // Only place checkmark at start if no progress
        <Box sx={{marginRight: '10px'}}>
          <Box sx={{
                ...circleStyles,
                backgroundColor: incompleteColor,
                marginLeft: '12px', marginRight: '12px',
              }}/>
        </Box>
      }
      {[...Array(tierCountForLevel)].map((k,v) => {
        // console.log("T and v?", highestTierComplete, v)
        return (
          <Box 
            key={uuid()} 
            sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%'
            }}
          >
            {/* Place circle at start instead of checkmkark if more than one is complete */}
            { highestTierComplete >= 1 && v == 0 &&
              <Box sx={{
                ...circleStyles,
                backgroundColor: highestTierComplete >= v+1 ? completeColor : incompleteColor,
                marginLeft: '12px', marginRight: '12px',
              }}/>
            }
            <Box sx={{
              ...lineStyles,
              backgroundColor: highestTierComplete >= v+1 ? completeColor : incompleteColor,
            }}/>
            {/* Place circle or check depending if current tier is the highest complete */}
            {highestTierComplete == v+1 ?
              <Box sx={{marginLeft: '10px', marginRight: '10px'}}><img  src={Checkmark}/></Box>
            :
              <Box sx={{
                ...circleStyles,
                backgroundColor: highestTierComplete >= v+1 ? completeColor : incompleteColor,
                marginLeft: '12px', marginRight: '12px',
              }}/>
            }
          </Box>
        )
      })}
    </Box> 
  )
}

const lineStyles = {
  height: '5px',
  borderRadius: '99px',
  flexGrow: 1, // Line will take up full width between cirlces (100% width was squishing)
}

const circleStyles = {
  width: '14px',
  height: '14px',
  borderRadius: '50%',
}

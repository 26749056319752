import { Box, IconButton } from "@mui/material"
import { useRef, useState, useEffect, ReactNode } from "react";
import { ArrowForward } from '@mui/icons-material';

import { createContext, useContext } from 'react';

const PreventRepClickContext = createContext(false);
export const usePreventRepClick = () => useContext(PreventRepClickContext);

interface DragScrollProps {
  children: ReactNode;
}

export const DragScroll: React.FC<DragScrollProps> = ({ children }) => {
  const containerRef: any = useRef(null)
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [preventRepClick, setPreventRepClick] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [atEnd, setAtEnd] = useState(false);
  const [scrollInterval, setScrollInterval] = useState<any>(null);
  const [scrollTimeout, setScrollTimeout] = useState<any>(null);

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
  };
  const handleMouseMove = (e: any) => {
    if (isDragging) {
      const dx = startX - e.clientX;
      if (Math.abs(dx) > 5) { // Drag threshold of 5 pixels
        setPreventRepClick(true);
        containerRef.current.scrollLeft = scrollLeft + dx;
      }
    }
  };
  const handleMouseUp = () => {
    setIsDragging(false);
    setTimeout(() => {
      setPreventRepClick(false)
    }, 250)
  };

  useEffect(() => {
    if (containerRef.current) {
      const handleDragStart = (e: any) => e.preventDefault();
      containerRef.current.addEventListener("dragstart", handleDragStart);
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener("dragstart", handleDragStart);
        }
      };
    }
  }, []);

  const checkOverflow = () => {
    if (containerRef.current) {
      const isOverflowing = containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setHasOverflow(isOverflowing);
      const isAtEnd = containerRef.current.scrollLeft + containerRef.current.clientWidth >= containerRef.current.scrollWidth;
      setAtEnd(isAtEnd);
    }
  }

  const handleScroll = () => {
    if (containerRef.current) {
      const isAtEnd = containerRef.current.scrollLeft + containerRef.current.clientWidth >= containerRef.current.scrollWidth;
      setAtEnd(isAtEnd);
    }
  };

  useEffect(() => {
    checkOverflow()
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    window.addEventListener('resize', checkOverflow);
    return () => {
      window.removeEventListener('resize', checkOverflow);
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef])

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 200, behavior: 'smooth' })
    }
  }

  useEffect(() => { // needed so when holding scroll and it reaches the end, it will stop
    if (atEnd) {
      stopScrollRight();
    }
  }, [atEnd]);

  const scrollRightHold = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 10, behavior: 'auto' });
    }
  }

  const startScrollRight = () => {
    const timeout = setTimeout(() => {
      const interval = setInterval(scrollRightHold, 10)
      setScrollInterval(interval);
    }, 200)
    setScrollTimeout(timeout);
  };

  const stopScrollRight = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout)
      setScrollTimeout(null)
    }
    if (scrollInterval) {
      clearInterval(scrollInterval)
      setScrollInterval(null)
    }
  }

  return (
    <Box sx={{display: 'flex', position: 'relative'}}>
    <PreventRepClickContext.Provider value={preventRepClick}>
      <Box sx={{display: 'flex', gap: '14px', overflowX: 'auto'}}
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        >
        {children}
      </Box>
    </PreventRepClickContext.Provider>

    {(hasOverflow && !atEnd) &&
      <IconButton
            sx={arrowButtonStyles}
            onClick={scrollRight}
            onMouseDown={startScrollRight}
            onMouseUp={stopScrollRight}   
            onMouseLeave={stopScrollRight}
          >
        <ArrowForward sx={{ color: '#ECECEC' }} />
      </IconButton>
    }
    </Box>
  )
}

const arrowButtonStyles = {
  position: 'absolute',
  right: 0,
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: 'rgba(23, 31, 49, 0.8)',
  '&:hover': {
    backgroundColor: 'rgba(23, 31, 49, 1)',
  },
  zIndex: 1,
};

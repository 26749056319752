import Phaser from "phaser";
import { colors } from "Phaser/config";
import {
  NotifyReact,
  ListenForReactEvent,
  RemoveListener,
} from "Phaser/EventBus";
import Instruction, {
  InstructionPosition,
} from "Phaser/GameObjects/Instruction";

export default abstract class ExerciseBase extends Phaser.Scene {
  active: boolean = false;
  // The initial config, for restoring the initial state when the exercise is restarted
  initialConfig?: Object;
  // The instruction to be shown to the user
  instruction: Instruction | null = null;
  numFailedAttempts: number = 0;
  failMessage: Phaser.GameObjects.Text | null = null;
  toastMessage: Phaser.GameObjects.Text | null = null;
  debugText: Phaser.GameObjects.Text | null = null;
  paused: boolean = false;

  registeredKeys: Map<string, Phaser.Input.Keyboard.Key> = new Map();

  showInstruction(
    instruction: string,
    position: InstructionPosition = "Top"
  ): void {
    this.instruction = new Instruction(this, instruction, position);
  }

  debugLog(text: string): void {
    if (!this.debugText)
      this.debugText = this.add.text(200, 100, text, {
        color: "#000",
        fontSize: 22,
      });
    else this.debugText.setText(text);
  }

  // Initialise objects and perform any scene switch transitions here.
  init(config: Object): void {
    this.events.once("shutdown", this.unload, this);
    // We keep the initial config for restoring the initial state
    // when the exercise is played again
    this.initialConfig = config;
    //EventBus.on("change-scene", this.changeScene, this);
    //EventBus.on("start", this.startExercise, this);
    //this.input.addListener("pointerdown", () => EventBus.emit("play-video"));
    //EventBus.on("cleanup", this.unload, this);
    ListenForReactEvent("start-exercise", this.startExercise, this, true);
    ListenForReactEvent(
      "unload",
      () => {
        console.log("trying to shutdown");
        this.scene.stop();
      },
      this,
      true
    );
    ListenForReactEvent("toggle-play-pause", this.pauseExercise, this, true);
    ListenForReactEvent("pause-exercise", this.pauseExercise, this);
    ListenForReactEvent("midi-on", this.midiOn, this);
    ListenForReactEvent("midi-off", this.midiOff, this);
    NotifyReact("exercise-resumed");
    this.active = true;
  }

  midiOn(_data?: any): void {}

  midiOff(_data?: any): void {}

  /// Remove all events / timers / event listeners here,
  /// all sub classes should call super.clearEvents() if overridden
  clearEvents(): void {
    RemoveListener("midi-on");
    RemoveListener("midi-off");
    RemoveListener("pause-exercise");
  }

  /// Should be called before loading the next scene.
  unload(): void {
    //NotifyReact("exercise-paused");
    console.log("unloading");
    NotifyReact("exercise-unloaded");
    this.debugText?.destroy();
    this.debugText = null;
    this.active = false;
    this.failMessage?.destroy();
    this.failMessage = null;
    this.toastMessage?.destroy();
    this.toastMessage = null;
    this.instruction?.destroy();
    this.instruction = null;
    this.registeredKeys.clear();
    this.numFailedAttempts = 0;
    this.clearEvents();
    if (this.paused) this.scene.stop("PauseMenu");
    this.scene.start("Empty");
  }

  changeScene(name: string, args: any): void {
    this.scene.start(name, args);
  }

  restart(): void {
    // reinitialise scene with initial config, restore initial state
    this.init(this.initialConfig!);
    this.startExercise();
  }

  startExercise(): void {}

  pauseExercise(): void {
    //EventBus.emit("exercise-paused");
    this.paused = true;
    this.scene.pause();
    this.scene.launch("PauseMenu", { other: this.scene.key });
  }

  hideInstruction(): void {
    this.instruction?.destroy();
  }

  passExercise(): void {
    //console.log("passExercise");
    //EventBus.emit("exercise-success");
    this.instruction?.showResult(true, () => {
      console.log("passed");
      this.onExercisePass();
      //EventBus.emit("exercise-success");
      NotifyReact("exercise-complete");
    });
  }

  skipExercise(): void {
    NotifyReact("exercise-skipped");
  }

  onExerciseSkip(): void {
    this.onExerciseOver();
  }

  /// Override this for custom behaviour when a user passes the exercise
  onExercisePass(): void {
    console.log("passed 2");
    this.onExerciseOver();
  }

  /// Override this for custom behaviour when a user fails the exercise
  onExerciseFail(): void {
    this.restart();
  }

  onExerciseOver(delay: number = 500): void {
    this.time.delayedCall(
      delay,
      this.transition,
      [true, this.stop, this],
      this
    );
  }

  abstract stop(): void;

  showToast(text: string): void {
    const dpr = window.devicePixelRatio;
    this.toastMessage?.destroy();
    this.toastMessage = this.add.text(100, 100, text, {
      fontFamily: "Lato",
      fontSize: `${32 * dpr}px`,
      color: "#000000",
      align: "center",
    });
    this.toastMessage.setAlpha(0);
    this.toastMessage.setPosition(
      this.cameras.main.width / 2 - this.toastMessage.width / 2,
      this.cameras.main.height - 75 * dpr
    );
    this.tweens.add({
      hold: 1000,
      targets: this.toastMessage,
      alpha: 1,
      duration: 500,
      yoyo: true,
      ease: "Power1",
      onComplete: () => {
        //bg.destroy();
        this.toastMessage?.destroy();
      },
    });
  }

  resume(): void {
    this.paused = false;
    ListenForReactEvent("toggle-play-pause", this.pauseExercise, this, true);
  }

  failExercise(): void {
    this.numFailedAttempts++;
    if (this.numFailedAttempts >= 1) {
      const cam = this.cameras.main;
      const dpr = window.devicePixelRatio;
      this.failMessage = this.add.text(
        cam.width / 2,
        cam.height + 100 * dpr,
        "Good effort! Try again.",
        {
          fontFamily: "Lato",
          fontSize: 32 * dpr,
          fontStyle: "bold",
          color: colors.textFg,
        }
      );
      this.tweens.add({
        targets: this.failMessage,
        y: cam.height - 120 * dpr,
        duration: 500,
        ease: "Power2",
        yoyo: true,
        hold: 2000,
      });
      this.failMessage.setX(cam.width / 2 - this.failMessage.displayWidth / 2);
      NotifyReact("skip-button-request");
    }
    this.onExerciseFail();
    /*this.failMenu?.destroy();
    this.failMenu = new FailMenu(
      this,
      () => {
        this.failMenu?.hide();
        this.passExercise();
      },
      () => {
        this.failMenu?.hide();
        this.restart();
      }
    );*/
  }

  /// Add key press / release listeners for the duration of this exercise
  addKey(keyname: string, onDown: () => void, onUp?: () => void, ctx?: object) {
    const key =
      this.registeredKeys.get(keyname) ?? this.input.keyboard!.addKey(keyname);
    key.on("down", onDown, ctx);
    //this.input.keyboard!.on(`keydown-${key.toUpperCase()}`, onDown, ctx);
    if (onUp) {
      //this.input.keyboard!.on(`keyup-${key.toUpperCase()}`, onUp, ctx);
      key.on("up", onUp, ctx);
    }
    this.registeredKeys.set(keyname, key);
  }

  /// Remove any registered key listeners with the passed callbacks
  removeKey(keyname: string, onDown: () => void, onUp?: () => void) {
    this.registeredKeys.get(keyname)?.off("down", onDown).off("up", onUp);
  }

  transition(out?: boolean, onDone?: () => void, ctx?: object): void {
    if (!out) NotifyReact("exercise-loaded");
    else this.scene.stop();
    //EventBus.off("cleanup");
    //EventBus.emit("exercise-end");
  }

  sectionComplete(): void {}
}

import { Box, TextField, Switch, FormGroup, FormControlLabel } from '@mui/material';
import { CustomSwitch } from 'Components/StyledComponents';

const Notifications = () => {
  return (
    <Box sx={{height: '100vh'}}>
    <Box sx={{
      marginLeft: '50px',
      width: '600px',
      marginTop: '20px', 
      backgroundColor: 'black',
      borderRadius: '5px',
    }}>
      <Box sx={{
        color: 'white',
        textAlign: 'left',
        marginLeft: '24px',
        marginRight: '24px',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{
          marginTop: '20px', 
          marginBottom: '20px',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '26px',
          letterSpacing: '0.01em',
          color: '#FFFFFF',
        }}>
        Adjust Notifications
        </Box>
        <Box sx={{marginBottom: '20px', display: 'flex', flexDirection: 'column'}}>
          <Box sx={{display:'flex', alignItems:'center', height: '25px'}}>
          <CustomSwitch defaultChecked />
          Email Notifications</Box>
          <Box sx={{marginTop: '10px', marginLeft: '15px', display: 'flex'}}>
            <Box sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              color: '#CED0D4',
            }}>Receive emails for product updates and special offers.</Box>
          </Box>
        </Box>
        {/* <Box sx={{marginBottom: '20px'}}>
          <Box sx={{display:'flex', alignItems:'center', height: '25px'}}>
            <CustomSwitch defaultChecked />Push Notifications
          </Box>
          <Box sx={{marginTop: '10px', marginLeft: '15px', display: 'flex'}}>
            <Box sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '16px',
              letterSpacing: '0.01em',
              color: '#CED0D4',
            }}>
              Receive push notifications for product updates and special offers.
            </Box>
          </Box>
        </Box> */}
      </Box>
    </Box>
    </Box>
  )
}

export default Notifications

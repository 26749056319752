import React, { useEffect } from "react";
import { Typography, Box } from "@mui/material";
import { ResetCodeData } from "Types";
import { CustomTextField, CustomButton } from "Components/StyledComponents"
import { FormikErrors, FormikProps } from "formik";
import * as yup from "yup";

export type ResetCodeFormProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & FormikProps<ResetCodeData>;

export const resetCodeFormSchema = yup.object().shape({
  code: yup
    .number()
    .required("You must provide your verification code")
    .min(6)
});

const ResetCodeForm: React.FC<ResetCodeFormProps> = (
  props: ResetCodeFormProps
) => {
  const { handleChange, values, errors } = props;

  return (
    <Box
    sx ={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}
    >
      <CustomTextField
        sx={{marginBottom: '20px'}}
        onChange={handleChange}
        id="code"
        name="code"
        // type="number"
        label="Verification Code"
        value={values.code || ""}
        error={!!errors.code}
        helperText={errors.code}
        fullWidth
        inputProps={{
          maxLength:6
        }}
      />

      <Box>
        { 
        errors.unknown && 
        <Typography color="error" variant="h6">
          {errors.unknown}
        </Typography> 
        }
      </Box>

      <CustomButton
        variant="contained"
        type="submit"
      >
        Submit
      </CustomButton> 

    </Box>
  );
};

export default ResetCodeForm;
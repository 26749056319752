import { IconButton, Box } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import * as authAction from 'Actions/auth'
import { useDispatch } from 'react-redux'

const LogOutButton = () => {
  const dispatch = useDispatch();

  return (
    <Box>
      <IconButton
        onClick={ () => {
          dispatch(authAction.logOut())
        }}
      >
        <LogoutIcon
          sx={{
            color: 'rgb(255,255,255,0.8)',
            "&:hover": {
              color: "rgb(255,255,255,1.0)"
            },
            fontSize: '26px',
            transform: 'scaleX(-1)',
          }}
        />
      </IconButton>
    </Box>
  )
}

export default LogOutButton

import React from 'react';
import * as eventActions from 'Actions/events';
import { Box } from '@mui/material';
import ColoredLogo from '../assets/images/ColoredLogo.png';
import pianoBackground from '../assets/images/pianoBackground.png';
import HorizontalMobileBlocker from '../assets/images/HorizontalMobileBlocker.png';
import VerticalMobileBlocker from '../assets/images/VerticalMobileBlocker.png';
import Logo from 'Components/Logo';
import { useDispatch } from 'react-redux';

// Should generalize these styles to match the other ones... 

// import './index.css'
// Show note that they need to open their browser that is not safari!
const SafariBlockingScreen = () => {
  const dispatch = useDispatch();

  React.useEffect(()=>{
    dispatch(eventActions.safariBlockedEventAction());
  });
  return (
    <Box sx={{display: 'flex', backgroundColor: 'black', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <img src={HorizontalMobileBlocker} style={{maxHeight: '100%', maxWidth: '100%'}}/>
    </Box>
  )
  // Old blocker:
  // return (
  //   <Box className="page-div-styles">
  //   <Box className="filter-div-styles">
  //   <Box className="sign-in-div-styles" sx={{maxHeight: '80%'}}>
  //     <Logo/>
  //     <Box className="desc-container welcome-container" sx={{height: '100%'}}>
  //       <h3 className="title-welcome" style={{marginTop: '80px'}}>Welcome to MuseFlow!</h3>
  //       <Box className="blocking-container-flex" sx={{marginBottom: '80px'}}>
  //         <p className="p-blocking-container">
  //           Oops! It looks like MuseFlow is feeling a bit shy around the Safari Browser. 😅
  //           <br/><br/>
  //           To start playing, please open MuseFlow in a <a style={{color:"#ffffff"}} href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">Chrome</a> or <a style={{color:"#ffffff"}} href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noopener noreferrer">Edge</a>.
  //           <br/><br/>
  //           There is a compatibility issue that prevents MIDI keyboards from working properly on  the Safari browser.
  //           <br/><br/>
  //           Apologies for any inconvenience!
  //         </p>
  //       </Box>
  //     </Box>
  //     </Box>
  //   </Box>
  //   </Box>
  // )
}

const MobileBlockingScreen = () => {
  const dispatch = useDispatch();

  React.useEffect(()=>{
    dispatch(eventActions.mobileBlockedEventAction());
  },[]);


  return (
    <Box sx={{display: 'flex', backgroundColor: 'black', height: '100vh', justifyContent: 'center', alignItems: 'center'}}>
      <img src={VerticalMobileBlocker} style={{maxHeight: '100%', maxWidth: '100%'}}/>
    </Box>
  )
  // Old blocker:
  // return (
  //   <Box sx={backgroundStyles1}>
  //   <Box sx={backgroundStyles2}>
  //     <Box className="museflow-container-styles" sx={{marginTop: '80px'}}>
  //     <Logo/>
  //     <Box className="blocking-container-styles">
  //       <Box className="blocking-container-flex">
  //         Welcome to MuseFlow!
  //         <Box className="blocking-container-flex">
  //           <p className="p-blocking-container">
  //             Oops! It looks like MuseFlow is feeling a bit shy around mobile browsers. 😅
  //             <br/><br/>
  //             To start playing, please switch to a computer and open MuseFlow in Chrome or Edge.
  //             <br/><br/>
  //             There is a compatibility issue that prevents MIDI keyboards from working properly on mobile or Safari browsers.
  //             <br/><br/>
  //             Apologies for any inconvenience!
  //           </p>
  //         </Box>
  //       </Box>
  //     </Box>
  //     </Box>
  //   </Box>
  //   </Box>
  // )
}


export { SafariBlockingScreen, MobileBlockingScreen }

// const backgroundStyles1 = {
//   backgroundImage: `url(${pianoBackground})`,
//   backgroundPosition: 'center',
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
//   height: '100vh',
// }

// const backgroundStyles2 = {
//   width: '100%',
//   height: '100%',
//   backdropFilter: 'brightness(15%)',
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
// }

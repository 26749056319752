import { Auth, Analytics } from "aws-amplify";
import { AugmentedCognitoUser, SignInData, SignUpData } from "Types";
import { isNil } from "lodash";
import { MissingSignUpFormDataError } from "Utils/Errors";
import { isEmail, isPhoneNumber } from "Components/Forms/SignUpForm";
import { codeFromCountryAcronym } from 'Components/Forms/CountryCodeSelect';
import { LogInPlayload } from "Types/AuthTypes";
const userSignUpParams: string[] = ["emailOrPhone", "name", "password"];

export const signUpUser = (signUpData: SignUpData): Promise<any> => {
  userSignUpParams.forEach((field: string) => {
    if (isNil(signUpData[field as keyof SignUpData])) {
      throw new MissingSignUpFormDataError(field);
    }
  });
  let attributes = {
    name: signUpData.name,
  }
  let fields = {
    password: signUpData.password,
    username: '' // appease the typescript compiler
  }
  if(isEmail(signUpData.emailOrPhone)) {
    attributes = Object.assign(attributes, { 
      email: signUpData.emailOrPhone,
    })
    fields = Object.assign(fields, { 
      username: signUpData.emailOrPhone
    })
  }
  if(isPhoneNumber(signUpData.emailOrPhone)) {
    const strippedNumber = '+' + codeFromCountryAcronym(signUpData.countryAcronym) + signUpData.emailOrPhone.replace(/[^0-9.]/g, '');
    attributes = Object.assign(attributes, { 
      phone_number: strippedNumber,
    })
    fields = Object.assign(fields, { 
      username: strippedNumber,
    })
  }
  fields = Object.assign(fields,{attributes})
  return Auth.signUp(fields);
};

export const signIn = async (signInData: LogInPlayload): Promise<AugmentedCognitoUser> => {
  // Unfortunately we have a problem here grabbing the "CognitoUser" type from amplify
  const { emailOrPhone, password, countryAcronym} = signInData
  let user: AugmentedCognitoUser
  if(isPhoneNumber(emailOrPhone)) {
    const strippedNumber = emailOrPhone.replace(/[^0-9.]/g, '');
    // on normal sign in, country code is provided. However, when signing in after verification, it's pulled
    // from state w
    const phoneNumberWithCountryCode = countryAcronym ? '+' + codeFromCountryAcronym(countryAcronym) + strippedNumber : strippedNumber;
    user = await Auth.signIn(phoneNumberWithCountryCode, password);
  } else {
    user = await Auth.signIn(emailOrPhone, password);
  }
  if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    // const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
    // // You need to get the new password and required attributes from the UI inputs
    // // and then trigger the following function with a button click
    // // For example, the email and phone_number are required attributes
    // const {username, email, phone_number} = getInfoFromUserInput();
    // const loggedUser = await Auth.completeNewPassword(
    //     user,              // the Cognito User Object
    //     newPassword,       // the new password
    //     // OPTIONAL, the required attributes
    //     {
    //         email,
    //         phone_number,
    //     }
    // );
    throw new Error('NEW_PASSWORD_REQUIRED');
  } else {
    return user;
  }
};

export const forgotPassword = (username: string, countryAcronym?: string) => {
  if(isPhoneNumber(username) && countryAcronym) {
    const strippedNumber = "+" + codeFromCountryAcronym(countryAcronym) + username.replace(/[^0-9.]/g, '');
    return Auth.forgotPassword(strippedNumber);
  } else if(isPhoneNumber(username) && !countryAcronym){
    throw new Error("Country code not specified for phone number")
  } else {
    return Auth.forgotPassword(username);
  }
}

export const submitForgotPassword = (username: string, code: string, newPassword: string, countryAcronym?: string) => {
  if(isPhoneNumber(username) && countryAcronym ) {
    const strippedNumber = "+" + codeFromCountryAcronym(countryAcronym) + username.replace(/[^0-9.]/g, '');
    return Auth.forgotPasswordSubmit(strippedNumber, code, newPassword);
  } else if(isPhoneNumber(username) && !countryAcronym){
    throw new Error("Country code not specified for phone number")
  } else {
    return Auth.forgotPasswordSubmit(username, code, newPassword);
  }
}

export const signOut = () => Auth.signOut();

export const getCurrentUser = () => Auth.currentAuthenticatedUser(); 

export const resendSignUp = (username: string, countryAcronym?: string) => {
  if(isPhoneNumber(username) && countryAcronym) {
    const strippedNumber = "+" + codeFromCountryAcronym(countryAcronym) + username.replace(/[^0-9.]/g, '');
    Auth.resendSignUp(strippedNumber);
  } else if(isPhoneNumber(username) && !countryAcronym){
    throw new Error("Country code not specified for phone number")
  } else {
    Auth.resendSignUp(username);
  }
}

export const confirmSignUp = (username: string, code: string, countryAcronym?: string) => {
  if(isPhoneNumber(username) && countryAcronym) {
    const strippedNumber = "+" + codeFromCountryAcronym(countryAcronym) + username.replace(/[^0-9.]/g, '');
    return Auth.confirmSignUp(strippedNumber, code);
  } else if(isPhoneNumber(username) && !countryAcronym){
    throw new Error("Country code not specified for phone number")
  } else {
    return Auth.confirmSignUp(username, code);
  }
}

export const updateUserAttributes = async (attributes: any) => {
  const user = await getCurrentUser();
  // const updatedEmail = attributes.email !== user.email
  return Auth.updateUserAttributes(user, attributes);
}

export const verifyCurrentUserAttributeSubmit = async (attr: string, code: string) => {
  return Auth.verifyCurrentUserAttributeSubmit(attr, code)
}

export const verifiedContact = async (): Promise<{verified: {}, unverified:{}}> => {
  const user = await getCurrentUser();
  return Auth.verifiedContact(user)
} 

export const verifyUserAttribute = async (attribute: string): Promise<void> => {
  return Auth.verifyCurrentUserAttribute(attribute); 
} 

export const userAttributes = async (): Promise<any> => {
  const user = await getCurrentUser();
  return Auth.userAttributes(user);
}

export const changePassword = async (oldPassword: string, newPassword: string): Promise<"SUCCESS"> => {
  const user = await getCurrentUser();
  return Auth.changePassword(user, oldPassword, newPassword)
}


export const getCurrentAuthToken = async (): Promise<string> => {
  const session = await Auth.currentSession();
  return session.getAccessToken().getJwtToken();
}
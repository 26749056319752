
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistStore, persistReducer } from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
import { mainAppReducer } from './mainAppReducer'
import { authReducer } from './authReducer'
import { History } from 'history'
import { eventReducer } from './eventReducer'
import localforage from 'localforage';
import { chapterReducer } from './chapterReducer';
import {repertoireReducer} from './repertoireReducer';

const authPersistConfig = {
  key: 'authPersistance',
  storage: localforage,
  whitelist: ['tempSignUpPass', 'authPending', 'jwtToken', 'idToken','user'],
  stateReconciler: hardSet,
};

export const authPersistedReducer = persistReducer(authPersistConfig, authReducer)

const createRootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  mainAppReducer: mainAppReducer,
  authReducer: authPersistedReducer,
  eventReducer: eventReducer,
  chapterReducer: chapterReducer,
  repertoireReducer: repertoireReducer
})

export default createRootReducer;

export type RootState = ReturnType<typeof createRootReducer>
export type AppDispatch = typeof createRootReducer

export const ActiveSubscriptionValues = [
  "trialing",
  "active",
  "past_due"
]

export const InctiveSubscriptionValues = [
  "canceled",
  "unpaid",
]

export const isActiveSubscription = (subscription_status: string, hasPaymentMethod: boolean) => {
  if (subscription_status == "past_due" && !hasPaymentMethod) {
    return false
  }
  return ActiveSubscriptionValues.includes(subscription_status); 
}
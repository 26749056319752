import Phaser from "phaser";
import { NotifyReact, RemoveListener, EventBus } from "Phaser/EventBus";
import ExerciseBase from "./ExerciseBase";

export default class Empty extends ExerciseBase {
  constructor() {
    super("Empty");
  }
  create(): void {
    EventBus.emit("current-scene-ready", this);
  }
  init() {
    RemoveListener("update-objective");
    RemoveListener("toggle-play-pause");
    RemoveListener("unload");
  }
  stop() {
    this.scene.stop();
  }
}

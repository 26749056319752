import {
  CheckCircle,
  RadioButtonUnchecked,
  OndemandVideoRounded,
} from "@mui/icons-material";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  List,
} from "@mui/material";
import Immutable from "immutable";
import { useSelector } from "react-redux";
import { ChapterReducer } from "Types/ChapterTypes";

export type Timestamp = `${number}:${number}`;

export function getTime(stamp: Timestamp): number {
  const [min, sec] = stamp.split(":").map(parseFloat);
  return min * 60 + sec;
}

type ChapterData = {
  title: string;
  start: Timestamp;
  id?: number;
  type: "lecture" | "exercise";
  numObjectives?: number;
};

export type ChapterSection = {
  chapters: ChapterData[];
};

const formatTime = (seconds: number) => {
  const secs = Math.floor(seconds % 60);
  const mins = Math.floor(seconds / 60);
  return `${mins}:${secs > 10 ? secs : "0" + secs.toString()}`;
};

type ChapterListProps = {
  sections: ChapterSection[];
  lesson: number;
  active: number;
  jumpTo: (x: number, type: "lecture" | "exercise") => void;
};

const Chapter = ({
  jumpTo,
  completionStatus,
  active,
  chapter,
}: {
  jumpTo: (x: number, type: "lecture" | "exercise") => void;
  completionStatus: Immutable.Set<number>;
  active: number;
  chapter: ChapterData;
}) => {
  const completed =
    completionStatus.find((i) => i === chapter.id) !== undefined;
  const icon_color = completed
    ? "#28856f"
    : active === chapter.id
    ? "#ffffff"
    : "#b2b2b2";
  const timeInSecs = getTime(chapter.start);
  return (
    <ListItemButton
      onClick={() => jumpTo(timeInSecs, chapter.type)}
      id={`chapter-${chapter.id}`}
      sx={{
        bgcolor: active === chapter.id ? "#4A5AA1" : "transparent",
        ":hover": {
          bgcolor: "#4a5aa1",
        },
      }}
      onKeyUp={(e) => {
        if (e.key === " ") e.preventDefault();
      }}
    >
      {chapter.type === "exercise" ? (
        <ListItemIcon>
          {completionStatus.find((i) => i === chapter.id) ? (
            <CheckCircle sx={{ color: icon_color }} />
          ) : (
            <RadioButtonUnchecked sx={{ color: icon_color }} />
          )}
        </ListItemIcon>
      ) : (
        <ListItemIcon>
          <OndemandVideoRounded sx={{ color: icon_color }} />
        </ListItemIcon>
      )}
      <ListItemText
        sx={{ color: active === chapter.id ? "#ffffff" : "#b2b2b2" }}
      >
        {chapter.type === "exercise" && <b>Exercise: </b>}
        {`${chapter.title} (${chapter.start})`}
      </ListItemText>
    </ListItemButton>
  );
};

const ChapterList = ({
  sections,
  lesson,
  active,
  jumpTo,
}: ChapterListProps) => {
  const completionData = useSelector(
    (state: ChapterReducer) => state.chapterReducer
  );
  const completionStatus =
    Immutable.Set(Immutable.get(completionData, lesson)) || Immutable.Set([]);
  return (
    <Box
      sx={{
        bgcolor: "#1d2f44",
        overflowY: "scroll",
      }}
    >
      <h3 style={{ color: "#b2b2b2", margin: 20 }}>Chapters</h3>
      <List sx={{ overflowY: "scroll" }}>
        {sections.map((section) => (
          <Box sx={{ margin: 2, borderBottom: "1px solid #b2b2b255" }}>
            {section.chapters.map((chapter) => (
              <Chapter
                jumpTo={jumpTo}
                completionStatus={completionStatus}
                active={active}
                chapter={chapter}
              />
            ))}
          </Box>
        ))}
      </List>
    </Box>
  );
};

export default ChapterList;

import { XMLParser, XMLBuilder } from "fast-xml-parser";
import  SHA256 from "crypto-js/sha256";
import TimeSignature from "./TimeSignature";

export default class MusicXML {
  // stream: MusicXMLNote[];
  xml: string;
  xmlContent: any; // TODO: add types for XMLBuilder.build output
  parser = new XMLParser({ ignoreAttributes: false, preserveOrder: true })
  builder =  new XMLBuilder({ ignoreAttributes: false, preserveOrder: true })
  measures: any[]
  timeSignatures: TimeSignature[]
  hash: string
  hashWithTimestamp: string

  constructor(xml: string, timeSignatures: TimeSignature[]) {
    this.xml = xml
    this.xmlContent = this.parser.parse(xml);
    const scorePartwise = MusicXML.getElements(this.xmlContent, "score-partwise")[0];
    const measures = MusicXML.getElements(scorePartwise, "part")[0];
    this.measures = measures;
    this.hash = SHA256(xml).toString()
    this.hashWithTimestamp = SHA256(xml + Date.now()).toString()
    this.timeSignatures = timeSignatures
  }

  // Duration of the measure relative to 4/4, where 4/4 == duration of 1
  measureDuration(): number {
    const timeSignature = this.timeSignatures[0]
    return timeSignature?.numerator / timeSignature?.denominator
  }

  // Helper method for the whacky structure the XML parses to
  // TODO: get rid of this or change the interface, it's confusing
  static getElements(el: object[], childName: string) : any[] {
    const elements = []
    for (let i = 0; i < el.length; i++) {
      const child: any = el[i]
      if (child.hasOwnProperty(childName)) {
        elements.push(child[childName])
      }
    }
    return elements
  }

  // Another helper method
  static getChildIndex(el: object[], childName: string) : number {
    for (let i = 0; i < el.length; i++) {
        const child: any = el[i]
        if (child.hasOwnProperty(childName)) {
            return i
        }
    }
    return -1
  }

}
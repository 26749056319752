import Phaser from "phaser";

export default abstract class GraphicObject extends Phaser.GameObjects
  .GameObject {
  x: number;
  y: number;
  scene: Phaser.Scene;

  constructor(scene: Phaser.Scene, x: number, y: number, graphicType: string) {
    super(scene, graphicType);
    this.scene = scene;
    this.x = x;
    this.y = y;
  }

  draw() {}

  setX(x: number) {
    this.x = x;
    this.draw();
  }

  setY(y: number) {
    this.y = y;
    this.draw();
  }

  setPosition(x: number, y: number) {
    this.x = x;
    this.y = y;
    this.draw();
  }

  getWidth(): number {
    return 0;
  }

  destroy() {}
}

import React from 'react';
import { Box, Link, Typography, Button, SvgIcon } from '@mui/material';
import { Formik, Form } from "formik";
import { SignInData } from 'Types';
import SignInForm, {signInFormSchema} from 'Components/Forms/SignInForm';
import { ValidateForm, handleAuthErrors } from 'Utils/Forms';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import {ReactComponent as GoogleIcon} from 'assets/images/Google__G__Logo.svg';
import {ReactComponent as FacebookIcon} from 'assets/images/Facebook_f_logo_.svg';
import { MUSEFLOW_OFF_WHITE_1, MUSEFLOW_OFF_WHITE_3 } from 'Components/Colors';
import { isPhoneNumber, isEmail } from 'Components/Forms/SignUpForm';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'Actions/auth';
import { AuthReducer, SetErrorsPayload } from 'Types/AuthTypes';
import { useNavigate } from 'react-router-dom';

type SignInProps = {
  forgotPasswordNavFunction: () => void
  goBackFn: () => void
}

const SignIn: React.FC<SignInProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const auth = useSelector((state: AuthReducer) => state.authReducer)
  const { forgotPasswordNavFunction } = props;

  const { 
    pendingEmailOrPhone,
    user,
    jwtToken,
    authPending,
    errors
   } = auth;

   React.useEffect(()=> {
    if(jwtToken && user && !authPending) {
      navigate('/roadmap')
    } 
   }, [user, jwtToken, authPending])

   React.useEffect(()=> {
    setFormErrors(auth.errors)
   }, [auth.errors])

   const [ formErrors, setFormErrors ] = React.useState<SetErrorsPayload | object>({})

  return (
    <>
    <h3 className="title-welcome" style={{marginTop: '50px', }}>Welcome to MuseFlow!</h3>
    <Box 
      sx={{
      display:'flex',
      flexDirection:'column', 
      alignItems:'center', 
      marginBottom: '20px'
      }}
    >
      <Box sx={{
        textAlign: 'center',
        color: MUSEFLOW_OFF_WHITE_1,
        paddingBottom: '14px',
        ...textStyles,
        fontSize: '15px',
      }}>
        Sign in with:
      </Box>
      <Box
        sx={{
          // width:'80%',
          height:'100%',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            width:'100%',
          }}
        >
        
        { 
          <Button 
          sx={{
            fontSize: '16px',
            color: MUSEFLOW_OFF_WHITE_1,
            borderStyle: 'solid',
            borderColor: MUSEFLOW_OFF_WHITE_1,
            borderRadius: '40px',
            borderWidth: '2px',
            padding: '8px 40px',
            marginRight: '4px'
          }}
          onClick={() => {
            dispatch(authActions.setProcessPending(true))
            Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })} 
          }
          startIcon={<SvgIcon><GoogleIcon/></SvgIcon>}>Google</Button>
        }
        { false &&
          <Button 
          sx={{
            fontSize: '16px',
            color: MUSEFLOW_OFF_WHITE_1,
            borderStyle: 'solid',
            borderColor: MUSEFLOW_OFF_WHITE_1,
            borderRadius: '20px',
            borderWidth: '2px',
            padding: '8px 40px',
            marginLeft: '8px'
          }}
          onClick={() => {
            dispatch(authActions.setProcessPending(true))
            Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Facebook })} 
          }
          startIcon={<SvgIcon><FacebookIcon/></SvgIcon>}>Facebook</Button>
        }
        </Box>
        <Box
          sx={{
            display:"flex",
            width:'350px',
            alignItems: 'center',
            paddingTop: '20px',
            paddingBottom: '20px'
          }}
        >
          <Box
            sx={{
              "height": "0px",
              "borderWidth": "1px",
              "borderStyle": "solid",
              "borderColor": MUSEFLOW_OFF_WHITE_3,
              "width": "100%"
            }}
          />
          <Typography sx={{
            paddingLeft:"10px",
            paddingRight:"10px",
            color:MUSEFLOW_OFF_WHITE_3
          }}>
            or
          </Typography>
          <Box
          sx={{
            "height": "0px",
            "borderWidth": "1px",
            "borderStyle": "solid",
            "borderColor": MUSEFLOW_OFF_WHITE_3,
            "width": "100%"
          }}
          />
        </Box>


        {
        pendingEmailOrPhone && isEmail(pendingEmailOrPhone) &&
        <Box sx={{paddingBottom: "20px"}}>
        <Typography 
          sx={{
            marginBottom: "20px",
            padding: '10px',
            borderRadius: '4px',
            backgroundColor: 'rgba(57, 94, 227, 0.40)',
          }}
          color="#00C2FF"
        >
          Your registration is pending. Check your email to verify.
          <br></br>
          (If you don't see an email, check your spam folder)
          </Typography> 
            <Link 
            sx={{
              textAlign: 'center' as 'center',
              cursor: 'pointer',
              marginBottom: "10px",
            }}
            onClick={()=> {dispatch(authActions.resendSignUp(pendingEmailOrPhone || ""))}}
          >
          Didn't get a link? Click here to resend.
          </Link>
        </Box>
        }

        {
        pendingEmailOrPhone && isPhoneNumber(pendingEmailOrPhone) &&
        <Typography 
            sx={{
              marginBottom: "20px",
              padding: '10px',
              borderRadius: '4px',
              backgroundColor: 'rgba(57, 94, 227, 0.40)',
            }}
            color="#00C2FF"
          >
            Your verification is pending.<br></br>Check your text messages for a verification code.
          </Typography> 
        }
      
        <Formik
        initialValues={{
          "emailOrPhone": "",
          "password": "",
          "unknown": "",
          "countryAcronym": "US"
        }}
        validate={(values: SignInData) => {
          return ValidateForm(signInFormSchema,values)}
        }
        onSubmit={async (values, formikHelpers) => {
          dispatch(authActions.logIn({emailOrPhone: values.emailOrPhone, password: values.password, countryAcronym: values.countryAcronym}))
        }}
        validateOnChange={false}
      >
        {formikProps => (
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              authActions.resetErrors();
              formikProps.handleSubmit(e);
            }}
          >
            <Box sx={{width: '350px', display: 'flex', flexDirection: 'column'}}>
              <SignInForm 
                forgotPasswordNavFunction={forgotPasswordNavFunction}
                {...formikProps}
                errors={{...formErrors,...formikProps.errors}}
              />
            </Box>
          </Form>
        )}
        </Formik>
        <Box
          sx={{
            color:MUSEFLOW_OFF_WHITE_1,
            marginTop: '20px',
            marginBottom: '24px',
            ...textStyles,
          }}
        >
          Don't have an account? <Link href={"/register"} sx={{color: '#6F87F1'}}> Create an account</Link>
        </Box>
      
      </Box>
    </Box>
    </>
  )
}

const textStyles = {
  fontFamily: 'Lato',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'normal',
  letterSpacing: '0.16px',
}

export default SignIn;